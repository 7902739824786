import React from "react";
import classes from "./MileageSnapshot.module.scss";

const MileageSnapshot = ({odometerReading}) => {
    const {mileage, overageMiles, baseMileageLimit} = odometerReading;

    const mileageToDisplay = mileage === -1 ? "---" : mileage;

    const mileageUnvailableMessage = mileage === -1 ?
        <span className={`${classes.mileage_Not_available}`}>Reading unavailable</span> : <></>;

    return (
        <div data-testid="mileageSnapshotInfoContainer" className={classes.mileageSnapshotcontainer}>
            <div data-testid="mileageId" className={classes.mileage}>
                <div>
                    <span
                        className={`${mileage === -1 ? classes.mileage_Not_available : classes.strong}`}>{mileageToDisplay}</span>
                    {baseMileageLimit < 99999 ? <span> / {baseMileageLimit} miles </span> : <span> miles </span>}
                </div>
                <span>{mileageUnvailableMessage}</span>
            </div>
            <div data-testid="overageMilesId" className={classes.overageMiles}>
                {baseMileageLimit < 99999 ? <span> {overageMiles} overage miles </span> : <span> Unlimited miles </span>}
            </div>
        </div>
    );
};

export default MileageSnapshot;