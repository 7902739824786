import React from 'react';
import classes from './PickupDropoffScheduleV2.module.scss';
import { convertStringToTimeValue, formatPickupOrReturnDate } from '../../utils/dateUtil';

const PickupDropoffScheduleV2 = ({ reservation, isPickup }) => {
  return (
    <div className={classes['time-wrapper']}>
      <img className={classes.image} src={'/assets/images/calendar.svg'} alt="calendar icon" />
      {isPickup ?
        <div className={classes.time}>
          <time dateTime={reservation?.selectedPickUpDate}>
            {formatPickupOrReturnDate(reservation?.selectedPickUpDate)}
          </time>
          {reservation?.selectedPickupDateTime && reservation?.selectedPickupDateTime.length !== 0 ?
            convertStringToTimeValue(reservation.selectedPickupDateTime)
          : 'TBD'}
        </div>
      : <div>
          <time dateTime={reservation?.selectedReturnDate}>
            {formatPickupOrReturnDate(reservation?.selectedReturnDate)}
          </time>
          <br />
          {reservation?.selectedDropoffDateTime && reservation?.selectedDropoffDateTime.length !== 0 ?
            convertStringToTimeValue(reservation.selectedDropoffDateTime)
          : 'TBD'}
        </div>
      }
    </div>
  );
};

export default PickupDropoffScheduleV2;
