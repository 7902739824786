import React from "react";
import classes from "./PickupPendingDetails.module.scss";

const PickupPendingDetails = () => {
    return (
        <div className={classes.container}>
            <div className={classes.body}>
                <div className={classes.description}>
                    <div className={classes.title}>
                        Bring These Along:
                    </div>
                    <div className={classes.sub}>
                        <p>&nbsp;1. Proof of Insurance*</p>
                        <p>2. Valid Driver’s License*</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PickupPendingDetails;
