import React from 'react';
import classes from './Generic.module.scss';
import {useNavigate} from "react-router-dom";

interface Props {
  route: string;
}

const Generic = ({route}: Props) => {
  const navigate = useNavigate();
  return (
    <section className={classes.wrapper} role="main">
      <img className={classes.image} src={"/assets/images/genericerror.svg"} alt="oops"/>
      <h4 className={classes.header}>Whoops.<br/>Something went wrong.</h4>
      <p className={classes.sub}>You head back and we’ll get to the<br/>bottom of this.</p>
      <button className={`button--primary ${classes.button}`} onClick={() => navigate(route)} data-testid="head_back">Head Back</button>
    </section>
  );
};

export default Generic;