import React, { useEffect } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { atomWithStorage } from 'jotai/utils';

export const marketCode = atomWithStorage('marketCode', '');

const AzureUserFlow = () => {
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (`${window.__RUNTIME_CONFIG__.REACT_APP_FLOW_TYPE}` === 'NOLOGIN') {
        await noLoginFlow();
        return;
      }
      navigate('/error');
    })();
  }, []);

  const noLoginFlow = async () => {
    const referrerValue = window.document.referrer;
    if (referrerValue?.includes('uber.com')) {
      navigate({
        pathname: '/sign-up',
        search: `?${createSearchParams({
          referrer: 'https://bonjour.uber.com/',
          // will append &flow=UBER to the url to differentiate between UBER and RETAIL flow when going to the signup page
          flow: 'UBER',
        })}`,
      });
    } else {
      navigate('/error');
    }
  };

  return <></>;
};

export default AzureUserFlow;
