import React from "react";
import classes from "./ClosedDetails.module.scss";

const ClosedDetails = () => {
    return (
        <div className={classes.sub}>
            Your lease has been successfully canceled. We hope you’ll drive with us again soon.
        </div>
    );
};

export default ClosedDetails;
