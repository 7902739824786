
import React from "react";
import classes from "../TermsAndConditions.module.scss";

const IntroductionNote = () => {
  return (
    <>
      <div className={classes.tc_heading}>
        <p>Terms and Conditions</p>
      </div>
      <div className={classes.tc_area}>
        <div className={classes.tc_conditions}>
          <div className={classes.tc_condition}>
            Welcome to www.forddrive.com (the "Site"). This Site is operated by
            a brand within Ford Motor Company (hereinafter "Ford") and the
            content on the Site is owned by Ford or its affiliated companies and
            by affiliates and third parties that post on the Site through a
            license from Ford, but for whom Ford assumes no direct
            responsibility. Please read these terms and conditions and the
            Privacy Policy contained on the Site, as they, together with any
            additional terms to which you agree when using particular elements
            of the Site, constitute the entire agreement regarding the Site and
            set forth the legally binding terms governing your visit to this
            Site. These Terms and Conditions apply to all persons who visit this
            Site ("Visitors"), regardless of your level of participation.
          </div>
          <div className={classes.tc_condition}>
            By using this Site you agree that you have read, understand and
            accept the following terms and conditions ("Terms and Conditions")
            and the
            <a
              href="https://www.ford.com/help/privacy"
              target="_blank"
              className={classes.tc_link}
            >
              <b className={classes.tc_bold}> Privacy Policy</b>
            </a>
            , and you affirm that you are either more than 18 years of age, or
            an emancipated minor, or possess legal parental or guardian consent,
            and that you are fully able and competent to enter into the terms,
            conditions, obligations, affirmations, representations, and
            warranties set forth in these Terms and Conditions, and to abide by
            and comply with these Terms and Conditions. By using this Site, you
            also agree that you will not use this Site for any purpose that is
            unlawful or in contravention of these Terms and Conditions. Ford
            Motor Company and its affiliates reserve the right to change these
            Terms and Conditions from time to time without notifying you. If you
            object to any such changes, your sole recourse shall be to cease
            using this Site. Continued use of the Site following the effective
            date any such changes shall indicate your acknowledgement of such
            changes and agreement to be bound by the Terms and Conditions of
            such changes. Ford may cease all or part of the Site at any time
            without notice and is not obligated to retain or return any post or
            comment. If you do not agree to any of these Terms and Conditions,
            or the Privacy Policy, please do not use the Site.
          </div>
        </div>
      </div>
    </>
  );
};

export default IntroductionNote;