import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import classes from '../Modal.module.scss';
import { postPricingTierHistoryById } from '../../services/pricingTierHistoryService';
import { ReservationStatus } from '../../models/ReservationStatus';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { getNextMonday } from '../../utils/dateUtil';
import { trackPricingTier } from '../../services/analyticsService';
import { ModalProps } from '../Modal/ModalUtils';
import { showToast } from '../CustomToast/CustomToast';
import { reservationStorageAtom, selectedPricingDisplayInfoAtom } from '../../pages/Dashboard/Dashboard';
import { PricingDisplayInfo } from '../../models/PricingDisplayInfo';

export const newPricingSelectedAtom = atomWithStorage<{
  isNew: boolean;
  nextMonday: string;
  mileage: number;
  bundle: string;
  weeklyRate: number;
  wasClosed: boolean;
  currentReservationStatus: ReservationStatus;
} | null>('newPricingSelected', null);
const activeStates = [ReservationStatus.ACTIVE, ReservationStatus.PENDING_RETURN];
type PricingTierSwitchConfirmationModalProps = ModalProps & { selectedPackage?: PricingDisplayInfo };

export default function PricingTierSwitchConfirmationModal(props: PricingTierSwitchConfirmationModalProps) {
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(false);
  const [reservation] = useAtom(reservationStorageAtom);
  const [selectedPricingDisplayInfo, setSelectedPricingDisplayInfo] = useAtom(selectedPricingDisplayInfoAtom);
  const [, setNewPricingSelected] = useAtom(newPricingSelectedAtom);

  const infoText =
    activeStates.includes(reservation?.status) ?
      'This package will go into effect at the start of the next billing cycle. Please note that overage miles are billed the week after they’re incurred.'
    : 'You will not be charged until vehicle pickup.';
  const wasSuccessHtml = (
    <>
      <img
        src={`${process.env.PUBLIC_URL}/assets/images/save-success.gif?a=${Math.random()}`}
        data-testid="add-card-success-image"
        alt="add card success image"
      />
      <p className="font-blue font-bold">Your lease package has been changed</p>
    </>
  );
  const confirmationHtml = (
    <>
      <p className={classes.header}>Please confirm new</p>
      <p className={classes.header}>lease package</p>
      <br />
      <p className="font-blue" style={{ fontSize: '14px' }}>
        <b>New package:</b>
        <br />
        <span className="color-primary-accent">
          {props.selectedPackage?.bundle} - ${props.selectedPackage?.weeklyRate?.toFixed(2)}/week
        </span>
      </p>
      <br />
      <p className="font-sm">
        Current package:
        <br />
        {selectedPricingDisplayInfo?.bundle} - ${selectedPricingDisplayInfo?.weeklyRate}.00/week
      </p>
      {(selectedPricingDisplayInfo?.billingPlanId?.includes('tier0') ||
        selectedPricingDisplayInfo?.billingPlanId?.includes('tier00')) && (
        <>
          <br />
          <p className="color-primary font-sm">
            The Starter package is no longer an option among the available lease packages. By confirming a new package
            you will not be able to revert back to the Starter package.
          </p>
        </>
      )}
      <br />
      <p style={{ fontSize: '12px', lineHeight: '18px' }}>{infoText}</p>
      <button
        className="button--primary"
        style={{ width: '100%', display: 'grid', placeContent: 'center', marginTop: '32px' }}
        onClick={handleConfirmClick}
      >
        {processing ?
          <img
            className="spinner button-spinner"
            src={process.env.PUBLIC_URL + '/assets/images/spinner-white.svg'}
            alt="processing"
          />
        : 'Confirm'}
      </button>
    </>
  );
  const content = success ? wasSuccessHtml : confirmationHtml;

  async function handleConfirmClick() {
    try {
      setProcessing(true);
      const response = await postPricingTierHistoryById(props.selectedPackage?.billingPlanId);
      if (response.status === 200) {
        trackPricingTier({ market_code: '', weekly_pricing_rate: props.selectedPackage?.weeklyRate.toString() });
        setSelectedPricingDisplayInfo(props.selectedPackage);
        setNewPricingSelected({
          isNew: true,
          nextMonday: getNextMonday(new Date()),
          mileage: props.selectedPackage?.mileageLimit,
          bundle: props.selectedPackage?.bundle,
          weeklyRate: props.selectedPackage?.weeklyRate,
          wasClosed: false,
          currentReservationStatus: reservation?.status,
        });
        setSuccess(true);
      } else {
        throw response;
      }
    } catch (e) {
      setProcessing(false);
      props.onClose();
      showToast(
        'Package update failed',
        'An error occurred while updating the lease package. Please try again.',
        `${process.env.PUBLIC_URL}/assets/images/Caution.svg`,
        '#D62D14'
      );
    }
  }

  return (
    <Dialog
      className={classes.dialog}
      visible={props.isOpen}
      onHide={() => {
        props.onClose();
        setSuccess(false);
        setProcessing(false);
      }}
      closeOnEscape
      blockScroll
      draggable={false}
    >
      <div data-testid="modal-container" className={`${classes.container} ${classes.container2}`}>
        {content}
      </div>
    </Dialog>
  );
}
