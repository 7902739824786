interface HttpStatus {
    readonly [key: string]: number
}

export const HTTP_STATUS: HttpStatus = {
    CONFLICT: 409,
    NOT_FOUND: 404,
    OK: 200,
    NO_CONTENT: 204,
    INTERNAL_SERVER_ERROR: 500
};
