import { useEffect, useState } from 'react';
import { getPricingList } from '../../services/pricingService';
import { useNavigate } from 'react-router-dom';
import { PageKey, PageURL } from '../../models/PageKey';
import { updateJourney } from '../../services/journeyService';
import { useGetJourney } from '../Orchestrator/useJourney/useGetJourney';
import { trackPackageSelectionPageDuration, trackPackageSelectionPageLoad } from '../../services/analyticsService';
import { usePageDuration } from '../../hooks/usePageDuration';
import { PricingDisplayInfoWithSelection } from '../../models/PricingDisplayInfo';

export default function useChoosePackage() {
  const navigate = useNavigate();
  const [showContent, setShowContent] = useState(false);
  const [pricingDisplayInfoList, setPricingDisplayInfo] = useState<PricingDisplayInfoWithSelection[]>([]);
  const { shouldShowContent, journey } = useGetJourney(PageURL.SIGN_UP, ['market']);

  async function updateSelected(billingPlanId: string) {
    setShowContent(false);
    try {
      const journeyResponse = await updateJourney({
        pageKey: PageKey.CREDIT_INFO,
        metadata: { packageId: billingPlanId },
      });
      if (journeyResponse?.status !== 200) {
        console.error('Error updating journey: ', journeyResponse?.status);
        return;
      }

      navigate('/additional-profile');
    } catch (e) {
      navigate('/error');
    }
  }

  usePageDuration(trackPackageSelectionPageDuration, shouldShowContent);

  useEffect(() => {
    (async () => {
      if (!shouldShowContent) return;
      setShowContent(true);
      trackPackageSelectionPageLoad();
      try {
        const { data, status } = await getPricingList(journey?.metadata?.market);
        if (status !== 200) {
          console.error('Error retrieving package selection from widget: ', status);
          return;
        }
        setPricingDisplayInfo(data);
      } catch (error) {
        console.error('Error retrieving package selection from widget: ', error);
      }
    })();
  }, [shouldShowContent]);

  return { pricingDisplayInfoList, updateSelected, showContent, journey };
}
