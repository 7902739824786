import { deleteRequestForBff, getRequestForBff, postRequestForBff } from '../axios/axiosVerbs';
import { Reservation } from '../models/Reservation';
import { ReservationExist } from '../models/ReservationExist';

export const getLatestReservation = () => getRequestForBff<Reservation>('v1/reservations/latest', true);

export const addWaitListReservation = (market: string, billingPlanId: string) =>
  postRequestForBff<Reservation>(
    'v1/reservations',
    {
      market,
      billingPlanId,
    },
    true
  );

export const reservationExists = () => getRequestForBff<ReservationExist>(`v1/reservations`, true);

export const removeWaitlistReservation = () => deleteRequestForBff<Reservation>('v1/reservations/waitlist', true);
