import React, {useEffect} from "react";
import classes from "./Footer.module.scss";
import {Link} from "react-router-dom";
import {trackFooterLinkClick} from "../../services/analyticsService";

declare global {
  interface Window {
    Optanon: {
      ToggleInfoDisplay: () => void;
    };
  }
}

function Footer() {
  const handleCookieSettingsClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    setFooterLink("Cookie Settings");
    e.preventDefault();
    window.Optanon.ToggleInfoDisplay();
  };
  const [footerLink, setFooterLink] = React.useState("");
  useEffect(() => {
    if (footerLink.length !== 0) {
      trackFooterLinkClick(footerLink);
    }
  }, [footerLink]);

  return (
    <footer className={classes.container} data-testid="footerGradientId">
      <div className={classes.breaker_line}></div>
      <img
        alt="Breaker"
        className={classes.breaker_image}
        src={process.env.PUBLIC_URL + "/assets/images/BreakerSymbol.svg"}
      />
      <div className={classes.wrapper}>
        <div className={classes.footer_logo}>
          <a href="https://www.forddrive.com" target="_blank">
            <img className={classes.image_height} src={"/assets/images/logo.webp"} alt="Ford Drive Logo"/>
          </a>
        </div>
        <div className={classes.footer_links}>
          <a target='_blank'
             rel='noopener noreferrer' href="https://forddrive.com/faq"
            data-testid="footer-faq"
            onClick={() => setFooterLink("FAQ")}
            >
            FAQ
          </a>
        </div>
        <div className={classes.footer_links}>
          <Link
            onClick={(e) => handleCookieSettingsClick(e)}
            to="/"
            data-testid="footer-cs"
          >
            Cookie Settings
          </Link>
        </div>
        <div className={classes.footer_links}>
          <a
            href={"/terms-and-conditions"}
            target="_blank"
            data-testid="footer-tac"
            onClick={() => setFooterLink("T&C")}
          >
            Terms & Conditions
          </a>
        </div>
        <div className={classes.footer_links}>
          <a
            href="https://www.ford.com"
            target="_blank"
            data-testid="footer-vf"
            onClick={() => setFooterLink("Visit Ford.com")}
          >
            Visit Ford.com
          </a>
        </div>
        <div className={classes.footer_links}>
          <a
            href="https://www.ford.com/help/privacy"
            target="_blank"
            data-testid="footer-pp"
            onClick={() => setFooterLink("Privacy")}
          >
            Privacy Notice
          </a>
        </div>
        <div
          className={`${classes.footer_links} ${classes.footer_privacy_choice}`}
        >
          <a
            href="https://www.ford.com/help/privacy/ccpa/"
            target="_blank"
            data-testid="footer-pp"
            onClick={() => setFooterLink("Privacy Choices")}
          >
            Your Privacy Choices
          </a>
          <img
            className={classes.privacy_choice_image}
            alt="Privacy Choice"
            src={process.env.PUBLIC_URL + "/assets/images/PrivacyChoice.svg"}
          ></img>
        </div>
        <p className={classes.ford_footer_copyright}>
          © Ford Drive. All rights reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
