import { Appearance } from '@stripe/stripe-js/types/stripe-js/elements-group';

export const APPEARANCE: Appearance = {
  theme: 'stripe',
  rules: {
    '.Input': {
      backgroundColor: '#F6F8FA',
      borderRadius: '10px',
      fontFamily: 'Gill Sans, sans-serif',
      fontSize: '14px',
      height: '38px',
      padding: '14px',
    },
    '.Input:disabled, .Input--invalid:disabled': {
      color: 'lightgray',
    },
    '.Label': {
      color: '#4D4D4D',
      fontFamily: 'Ford Antenna',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '18px',
      marginBottom: '2px',
    },
  },
};
