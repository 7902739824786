import React from 'react';
import ChargingInfo from '../ChargingInfo/ChargingInfo';
import ChargingLevel from '../ChargingLevel/ChargingLevel';
import ChargingMache from '../ChargingMache/ChargingMache';
import classes from './ChargingGuide.module.scss';
import NavWebHeader from '../../../../components/NavWebHeader/NavWebHeader';

const ChargingGuide = () => {
  return (
    <>
      <NavWebHeader title="Charging Guide" />
      <div className={classes.wrapper}>
        <div className={classes.body_container}>
          <div className={classes.support_heading}>
            <p>Everything you need to know about charging your Mustang Mach&nbsp;-&nbsp;E</p>
          </div>
          <ChargingLevel />
          <div className={classes.support_heading}>
            <p>Two Ways to Charge Your Vehicle</p>
          </div>
          <div className={`${classes.space_below} ${classes.charging_info}`}>
            <ChargingInfo
              title="Home Charging"
              imageToDisplay="HomeCharging.png"
              navigateTo="home-charging"
              description="Provides convenient charging right from your driveway, garage or
          parking spot."
            />
          </div>
          <div className={classes.charging_info}>
            <ChargingInfo
              title="Public Charging"
              imageToDisplay="PublicCharging.png"
              navigateTo="public-charging"
              description="Offers many convenient locations for on-demand charging within
              your community."
            />
          </div>
          <div className={classes.support_heading}>
            <p>Charging Your Mustang Mach&nbsp;-&nbsp;E</p>
          </div>
          <ChargingMache />
        </div>
      </div>
    </>
  );
};

export default ChargingGuide;
