import { CardResponse } from '../models/CardResponse';
import { deleteRequestForBff, downloadRequestForBff, getRequestForBff, putRequestForBff } from '../axios/axiosVerbs';
import { UpdateCardRequest } from '../models/UpdateCardRequest';
import { InvoicesResponse } from '../models/InvoicesResponse';

export const getCardDetails = () => getRequestForBff<CardResponse>('v1/customer/payment/card', true);

export const getAllCards = () => getRequestForBff<CardResponse[]>('v1/customer/payment/cards', true);

export const setPrimaryCard = (paymentMethodId: string) =>
  putRequestForBff('v1/customer/payment/default', { paymentMethodId }, true);

export const detachCard = (id: string) => deleteRequestForBff<CardResponse>(`v2/customer/payment/card/${id}`, true);

export const updateCard = (updateCardRequest: UpdateCardRequest) =>
  putRequestForBff<CardResponse>('v1/customer/payment/card', updateCardRequest, true);

export const getInvoices = () => getRequestForBff<InvoicesResponse>('v1/customer/payment/invoices', true);

export const downloadAllInvoices = (filterBy: string[]) =>
  downloadRequestForBff<string>(`v1/customer/payment/download-all-invoices?filterBy=${filterBy}`, true);
