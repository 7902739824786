import React, { useEffect } from 'react';
import classes from './ExperianError.module.scss';
import ContactUsModal from '../Modal/ContactUsModal/ContactUsModal';
import { ErrorType } from '../../pages/ErrorViewer/ErrorViewer';
import SsnError from '../SsnError/SsnError';
import { trackExperianError, trackExperianGenericError, trackExperianSSNError } from '../../services/analyticsService';
import DefaultError from '../DefaultError/DefaultError';

const ExperianError = ({ type }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  useEffect(() => {
    trackExperianError({ error_type: type });
    if (type === ErrorType.EXPERIAN_SSN_ERROR) {
      trackExperianSSNError({ market_code: '' });
    } else {
      trackExperianGenericError({ market_code: '' });
    }
  }, [type]);

  const renderBody = () => {
    switch (type) {
      case ErrorType.EXPERIAN_SSN_ERROR:
        return <SsnError />;
      case ErrorType.EXPERIAN_ADDRESS_ERROR:
        break;
      case ErrorType.EXPERIAN_ERROR:
      default:
        return <DefaultError setIsModalOpen={setIsModalOpen} />;
    }
  };

  return (
    <div className={classes.container} role="main">
      <div className={classes.wrapper}>{renderBody()}</div>
      <ContactUsModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
  );
};

export default ExperianError;
