import React from 'react';
import { Form } from '../../components/form/FormComponents';
import DriversLicenseFormField from '../../components/form/components/DriversLicenseFormField/DriversLicenseFormField';
import BirthDateFormFields from '../../components/form/components/BirthDateFormFields/BirthDateFormFields';
import AddressFormFields from '../../components/form/components/AddressFormFields/AddressFormFields';
import Footer from '../../components/Footer/Footer';
import classes from './CreditCheckForm.module.scss';
import ConsentCheckbox from '../../components/form/components/ConsentCheckbox/ConsentCheckbox';
import { useGetJourney } from '../Orchestrator/useJourney/useGetJourney';
import { PageURL } from '../../models/PageKey';
import VerifyingSpinner from '../../components/VerifyingSpinner/VerifyingSpinner';
import useCreditCheckForm from './useCreditCheckForm';
import AnimatedButton from '../../components/AnimatedButton/AnimatedButton';
import ProgressBar from '../../components/ProgressBar';
import { useHandleBackButton } from '../../hooks/useHandleBackButton';

const CreditCheckForm: React.FC = () => {
  const payment = window.__RUNTIME_CONFIG__.REACT_APP_CC_REMOVED === 'true' ? PageURL.APPROVED : PageURL.PAYMENT;
  const { shouldShowContent, journey } = useGetJourney(payment, ['packageId']);
  const {
    allFieldsDirty,
    defaultValues,
    disableEditing,
    enableSubmit,
    processing,
    success,
    onAnimationEnd,
    onConsentEventHandler,
    onSubmit,
    setAllFieldsDirty,
  } = useCreditCheckForm(shouldShowContent, journey);

  useHandleBackButton(PageURL.PACKAGE_SELECTION);

  return (
    <>
      <div className="l-progress-bar-wrapper">
        <ProgressBar currentStep={3} />
      </div>
      {shouldShowContent ?
        <div className="l-border-radius-bg">
          <div className="l-center">
            <div className={classes.container}>
              <p className={classes.header} data-testid="applicationApproval">
                Application Approval
              </p>
              <p className={classes.notes} data-testid="updateAddressNotes">
                Fill the information below to qualify for your new Mach-E. This soft credit check{' '}
                <b>will not effect your credit score.</b>
              </p>
              <Form
                onSubmit={onSubmit}
                defaultValues={defaultValues}
                allFieldsDirty={allFieldsDirty}
                setAllFieldsDirty={setAllFieldsDirty}
              >
                <div className="l-margin-right-24">
                  <DriversLicenseFormField disabled={disableEditing} />
                  <BirthDateFormFields disabled={disableEditing} />
                  <AddressFormFields disabled={disableEditing} />
                  <ConsentCheckbox
                    consentType="CreditCheck"
                    consentEvent={onConsentEventHandler}
                    disabled={disableEditing}
                  />
                </div>
                <div className="l-flex-center">
                  <div className={classes.buttonContainer}>
                    <AnimatedButton
                      enableSubmit={enableSubmit}
                      processing={processing}
                      success={success}
                      text={'Submit'}
                      onAnimationEnd={onAnimationEnd}
                    />
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      : <VerifyingSpinner />}
      <Footer />
    </>
  );
};
export default CreditCheckForm;
