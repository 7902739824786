import React from 'react';
import classes from './PickupInfo.module.scss';
import {useNavigate} from 'react-router-dom';
import LeasePackage from '../../../../../components/widgets/LeasePackage/LeasePackage';
import PickupDropoffSchedule from '../../../../../components/PickupDropoffSchedule/PickupDropoffSchedule';
import PickupDropoffLocation from '../../../../../components/PickupDropoffLocation/PickupDropoffLocation';

const PickupInfo: React.FC<{ locationId: string }> = ({ locationId }) => {
  const navigate = useNavigate();
  const chargingInfo = ({ title, subTitle }) => {
    return (
      <section
        className={classes.charging_section}
        onClick={() => {
          navigate('/support/charging-guide');
        }}
      >
        <img src={'/assets/images/charger.svg'} alt="charging station icon" className={classes.charging_icon_section} />
        <div className={classes.charging_info_section}>
          <p className={classes['charging-title']}>{title}</p>
          <p className={classes['charging-sub-title']}>{subTitle}</p>
        </div>
      </section>
    );
  };
  return (
    <div>
      <h4 className={classes.heading} style={{ marginTop: '32px' }}>
        Pickup Details:
      </h4>
      <div className={classes.white_card}>
        <PickupDropoffSchedule />
        <PickupDropoffLocation locationId={locationId} isPickup={true} />
      </div>
      <div style={{ marginTop: '32px' }}>
        <LeasePackage />
      </div>
      <div className={classes.charging}>
        {chargingInfo({
          title: 'Charging Guides & Apps',
          subTitle:
            'Discover everything you \n' + 'need to know about \n' + 'charging your electric \n' + 'vehicle here.',
        })}
        <div className={classes['right-chevron']}>
          <i className="pi pi-chevron-right" style={{ color: '#FFFFFF' }} />
        </div>
      </div>
    </div>
  );
};

export default PickupInfo;
