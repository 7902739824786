import { getRequestForBff, postRequestForBff, putRequestForBff } from '../axios/axiosVerbs';
import { UserProfile } from '../models/UserProfile';
import { SetPasswordRequest } from '../models/SetPasswordRequest';
import { SetPasswordRequestWithConsent } from '../models/SetPasswordRequestWithConsent';
import { SetPasswordResponse } from '../models/SetPasswordResponse';
import { SetPasswordWithConsentResponse } from '../models/SetPasswordWithConsentResponse';
import { UserType } from '../models/UserTypeResponse';

export const getUserProfile = () => getRequestForBff<UserProfile>(`v1/user/profile`, true);

export const getUserProfileForBff = () => getRequestForBff<UserProfile>(`v1/user/profile`, true);

export const setPassword = (setPasswordRequest: SetPasswordRequest) =>
  putRequestForBff<SetPasswordResponse>(`v1/user/password`, setPasswordRequest, true);

export const setPasswordWithConsent = (setPasswordRequestWithConsent: SetPasswordRequestWithConsent) =>
  putRequestForBff<SetPasswordWithConsentResponse>(
    `v1/user/password-with-consent`,
    setPasswordRequestWithConsent,
    true
  );

export const getUserAccountType = () => getRequestForBff<UserType>(`v1/user/account-type`, true);

export const logoutUser = () => postRequestForBff(`logout`, {}, false);
