import React from 'react';
import Dropdown from '../Dropdown';
import PickupDropoffLocationV2 from '../PickupDropoffLocationV2';
import PickupDropoffScheduleV2 from '../PickupDropoffScheduleV2/PickupDropoffScheduleV2';

function DropdownPickupDropoffLocation({ reservation, isPickup, expanded }) {
  const title = isPickup ? 'Vehicle Pick Up Location' : 'Vehicle Return Location';
  return (
    <Dropdown title={title} expanded={expanded} key={expanded}>
      <div
        style={{
          padding: '0 16px',
          display: 'flex',
          rowGap: '15px',
          flexDirection: 'column',
          alignItems: 'stretch',
        }}
      >
        <PickupDropoffScheduleV2 reservation={reservation} isPickup={isPickup} />
        <PickupDropoffLocationV2 locationId={reservation?.pickupLocationId} isPickup={isPickup} />
      </div>
    </Dropdown>
  );
}

export default DropdownPickupDropoffLocation;
