export const defaultRetryConfigGet = {
    retries: 3,
    retryDelay: () => 2000,
    retryCondition: shouldRetry(),
};

export const defaultRetryConfigPost = {
    retries: 3,
    retryDelay: () => 2000,
    retryCondition: shouldRetry(),
};

function shouldRetry() {
    return error => {
        return error.code !== 'ECONNABORTED' &&
            (!error.response || (error.response.status === 408 || error.response.status === 504 || error.response.status === 429));
    };
}
