import { trackPackageSelectionTileButtonClick } from '../../services/analyticsService';
import { PricingDisplayInfoWithSelection } from '../../models/PricingDisplayInfo';

interface MileagePackageCardProps {
  pricingDisplayInfoWithSelectedList: PricingDisplayInfoWithSelection[];
  handleClick?: (id: string) => void;
  showSelectedOnly?: boolean;
}

export const PackageCard = ({
  pricingDisplayInfoWithSelectedList,
  handleClick,
  showSelectedOnly = false,
}: MileagePackageCardProps) => {
  const onClick = (pricingDisplayInfo: PricingDisplayInfoWithSelection) => {
    trackPackageSelectionTileButtonClick({
      weekly_pricing_rate: pricingDisplayInfo.weeklyRate.toString(),
    });
    handleClick(pricingDisplayInfo.billingPlanId);
  };
  return (
    <>
      {pricingDisplayInfoWithSelectedList
        ?.filter((info) => !showSelectedOnly || info.isSelected)
        .map((pricingDisplayInfo) => {
          return (
            <div
              className={`card--mileage-package ${
                pricingDisplayInfo.isSelected && !showSelectedOnly ? 'mileage-package--selected' : ''
              }`}
              key={pricingDisplayInfo.id}
              onClick={() => onClick(pricingDisplayInfo)}
            >
              <p
                className="font-sm mileage-package__header"
                data-testid="tier-name"
                data-pid={pricingDisplayInfo.billingPlanId}
              >
                {pricingDisplayInfo.bundle?.toUpperCase()}
              </p>
              <h3 className="mileage-package__amount">
                <p className="weekly-rate">
                  <sup className="superscript">$</sup>
                  {pricingDisplayInfo.weeklyRate}.00<span className="superscript">/wk</span>
                </p>
              </h3>
              {pricingDisplayInfo.mileageLimit > 99999 ?
                <p className="font-sm mileage-package__desc" data-testid="description">
                  Unlimited miles included
                </p>
              : <p className="font-sm mileage-package__desc" data-testid="description">
                  {pricingDisplayInfo.mileageLimit} miles included, then ${pricingDisplayInfo.meteredRate?.toFixed(2)}
                  /mile
                </p>
              }
            </div>
          );
        })}
    </>
  );
};
