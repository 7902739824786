import React from 'react';
import classes from "./CreditNavCard.module.scss";


interface Props {
    label: string;
    nickname?: string;
    primary: boolean;
    handleNav?: () => void;
}

const CreditNavCard = ({label, nickname, handleNav, primary}: Props) => {
    return (
        <div className={`${classes.container} ${primary && classes.wave_border}`} onClick={handleNav}>
            <div>
                <p className={classes.nickname}>{nickname}</p>
                <p style={{marginTop: nickname ? '8px' : ''}} className={classes.label}>{`xxxx xxxx xxxx ${label}`}</p>
            </div>
            <img src={"/assets/images/CaretRight.svg"}  alt="Navigation caret for edit payment page"/>
            {primary &&
                <div style={{marginTop: nickname ? '4px' : '12px'}} className={classes.primary_spacer}><p>Primary</p></div>}
        </div>
    );
};

export default CreditNavCard;
