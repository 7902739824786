import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Input } from '../../FormComponents';
import classes from './BirthDateFormFields.module.scss';
import { FormModel, InputModel } from '../../models';

const Child: React.FC<FormModel> = memo(
    ({register, formState: {errors}, disabled}) => (
        <>
            <Input register={register}
                   name="birthDay"
                   title="Birth Day"
                   maxLength={2}
                   containerClassName={classes.input_width_small}
                   placeholder='00'
                   errors={errors}
                   hideErrors = {true}
                   disabled={disabled}
                   rules={{
                       required: {
                           value: true,
                           message: 'Required'
                       },
                       minLength: {
                           value: 2,
                           message: '2 digits required'
                       },
                       pattern: {
                           value: /^(0[1-9]|[12]\d|3[01])$/,
                           message: 'Invalid'
                       }
                   }}/>
        </>
    ));
const BirthDayFormField: React.FC<InputModel> = ({ disabled = false }) => {
    const methods = useFormContext();
    return <Child {...methods} disabled={disabled} />;
};
export default BirthDayFormField;
