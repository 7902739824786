import {JourneySource} from "../models/JourneySource";
import {PageKey} from "../models/PageKey";
import {demandJourney} from "../services/journeyService";
import {ampli} from "../ampli";
import { NavigateFunction } from "react-router-dom";

export const createJourney = async (marketCode: string, navigate: NavigateFunction) => {
    const journeyRequest = {
        journeySource: JourneySource.NA,
        workflowId: 'UBER',
        pageKey: PageKey.PACKAGE_SELECTION,
        metadata: {
            market: marketCode,
            experianVerified: false,
        },
    };
    try {
        const journeyResponse = await demandJourney(journeyRequest);
        if (journeyResponse?.status == 200) {
            if (journeyResponse?.data?.userId) {
                ampli.identify(journeyResponse.data.userId, {
                    user_azure_id: journeyResponse.data.userId,
                    market: marketCode,
                    orchestrator: 'Flow',
                });
            }
            navigate('/account-package-selection');
        }
    } catch (e) {
        navigate('/error');
    }
};