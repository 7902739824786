import React from "react";
import classes from "./Condition.module.scss";

interface ConditionProps {
  slNo: number;
  condition: string;
}
const Condition: React.FC<ConditionProps> = ({ slNo, condition }) => {
  return (
    <div className={classes.tc_condition}>
      <div>{slNo}.</div>
      <div className={classes.tc_left_space}>{condition}</div>
    </div>
  );
};

export default Condition;
