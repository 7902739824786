import React from 'react';
import BirthMonthFormField from './BirthMonthFormField';
import BirthDayFormField from './BirthDayFormField';
import BirthYearFormField from './BirthYearFormField';
import warningIcon from '../../images/icon--warning.svg';
import classes from './BirthDateFormFields.module.scss';
import { useFormState } from 'react-hook-form';
import { InputModel } from '../../models';

const BirthDateFormFields: React.FC<InputModel> = ({ disabled = false }) => {
  return (
    <div className={classes.containerWithAlert}>
      <div className={classes.container}>
        <BirthMonthFormField disabled={disabled} />
        <BirthDayFormField disabled={disabled} />
        <BirthYearFormField disabled={disabled} />
      </div>
      {DisplayAlert() && (
        <span role="error" className={classes.error}>
          <img src={warningIcon} /> Please enter a valid date of birth
        </span>
      )}
    </div>
  );
};

const DisplayAlert = () => {
  const formState = useFormState();
  return formState.errors?.birthDay || formState.errors?.birthMonth || formState.errors?.birthYear;
};
export default BirthDateFormFields;
