import {getUserProfileForBff} from "../../services/userService";

export async function getUserAddress() {
    try {
        const response = await getUserProfileForBff();
        return {
            "homeAddress": response.data.address.line1,
            "city": response.data.address.city,
            "state": response.data.address.state,
            "zipCode": response.data.address.postalCode
        };
    } catch (e){
        console.log(e);
    }
}