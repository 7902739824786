import React from "react";
import classes from "./ChargingCard.module.scss";

interface ChargingCardProps {
    cardImage: string;
    cardImageAlt: string;
    cardTitle: string;
}

const ChargingCard: React.FC<ChargingCardProps> = ({
                                                       cardImage,
                                                       cardImageAlt,
                                                       cardTitle,
                                                       children
                                                   }) => {
    return (
        <div className={classes.charging_info_container}>
            <div>
                <img
                    className={classes.charging_info_image}
                    src={cardImage}
                    alt={cardImageAlt}
                />
            </div>
            <div className={classes.support_heading}>
                <p>{cardTitle}</p>
            </div>
            <div>
                {children}
            </div>
        </div>
    );
};

export default ChargingCard;
