import { SecondaryProfileRequest } from '../../models/SecondaryProfileRequest';

export type SecondaryProfileFormValues = {
  birthDay: string;
  birthMonth: string;
  birthYear: string;
  city: string;
  driversLicenseNumber: string;
  firstName: string;
  homeAddress: string;
  lastName: string;
  state: string;
  zipCode: string;
};

export function createRequest(data: SecondaryProfileFormValues, consent: string) {
  const secondaryPartialProfile: SecondaryProfileRequest = {
    ...data,
    dateOfBirth: data.birthMonth + '/' + data.birthDay + '/' + data.birthYear,
    consent: consent,
    homeAddress: {
      streetAddress: data.homeAddress,
      city: data.city,
      state: data.state,
      zipcode: data.zipCode,
      apartmentNumber: '',
      country: '',
    },
  };
  return secondaryPartialProfile;
}
