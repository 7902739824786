export enum PageKey {
  PACKAGE_SELECTION = 'PACKAGE_SELECTION',
  CREDIT_INFO = 'CREDIT_INFO',
  PAYMENT = 'PAYMENT',
  SIGNUP_SUCCESS = 'SIGNUP_SUCCESS',
}

export enum PageURL {
  SIGN_UP = '/sign-up?referrer=bonjour.uber.com',
  PACKAGE_SELECTION = '/account-package-selection',
  CREDIT_INFO = '/additional-profile',
  PAYMENT = '/payments',
  APPROVED = '/approved',
  SIGNUP_SUCCESS = '/success',
  PROFILE_SETTINGS = '/settings',
}

/**
 * @deprecated
 * todo replace usages with above PageURL enum
 */
export enum NavigationURLForPageKey {
  PACKAGE_SELECTION = '/account-package-selection',
  CREDIT_INFO = '/additional-profile',
  PAYMENT = '/payments',
  SIGNUP_SUCCESS = '/success',
}
