import React from 'react';
import classes from './MobileHeader.module.scss';

const MobileHeader = () => {
    return (
        <header className={classes.header} data-testid='headerId' role="banner">
            <img className={classes['ford-drive-logo']}
                 src={"/assets/images/forddrive.svg"} alt="Ford Drive Logo"/>
            <img className={classes.wave} src={"/assets/images/wave-bg-m.png"} alt="wave background"/>
        </header>
    );
};


export default MobileHeader;
