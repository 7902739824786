import React, { memo, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ConsentCheckBoxComponent } from '../../FormComponents';
import { getConsentTexts } from '../../../../services/consentService';
import { Consent } from '../../../../models/Consent';
import { FormModel } from '../../models';

const Child: React.FC<
  FormModel & { consentInfo: any } & { onConsentHandler: any } & { lightColor: any } & { setValue: any }
> = memo(({ setValue, register, formState: { errors }, onConsentHandler, consentInfo, lightColor, disabled }) => (
  <>
    <ConsentCheckBoxComponent
      register={register}
      name="consents"
      text={consentInfo.textValue}
      errors={errors}
      id={consentInfo.legalLanguageCode}
      onConsentHandler={onConsentHandler}
      lightColor={lightColor}
      disabled={disabled}
      setValue={setValue}
      rules={{
        required: {
          value: true,
          message: 'Consent is required',
        },
      }}
    />
  </>
));

const ConsentCheckbox: React.FC<any> = ({
  consentType,
  consentEvent,
  useStatic,
  lightColor = false,
  disabled = false,
}) => {
  const methods = useFormContext();
  const [consentData, setConsentData] = useState([]);
  const [consentInfo, setConsentInfo] = useState({ legalLanguageCode: '', textValue: '' });

  const onConsentHandler = (consent: Consent) => {
    consentEvent(consent);
  };

  useEffect(() => {
    (async () => {
      try {
        const consentResponse = await getConsentTexts(consentType);
        setConsentData(consentResponse.data);
        setConsentInfo({
          legalLanguageCode: consentResponse.data.map((data) => data.legalLanguageCode).join(','),
          textValue: !useStatic
            ? consentResponse.data.map((data) => data.textValue).join(',')
            : 'I agree to the website <a href=https://forddrive.com/termsandconditions target=_blank> Terms of Use ' +
              '</a>  and acknowledge the <a href=https://www.ford.com/help/privacy/ target=_blank> Privacy Notice ' +
              '</a> & <a href=https://www.ford.com/help/privacy/#caPrivacy target=_blank> Notice at Collection </a>',
        });
      } catch (e) {
        setConsentData([]);
      }
    })();
  }, []);

  return (
    <div>
      {consentData && Array.isArray(consentData) && (
        <div>
          <Child
            {...methods}
            onConsentHandler={onConsentHandler}
            consentInfo={consentInfo}
            key={consentInfo.legalLanguageCode}
            lightColor={lightColor}
            disabled={disabled}
          />
        </div>
      )}
    </div>
  );
};
export default ConsentCheckbox;
