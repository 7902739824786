import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { initAuthLogin } from '../../../services/noAuthFlowService';

const EmailLogin = () => {
  const navigate = useNavigate();
  const invokeEmailPolicy = async () => {
    try {
      const authLogin = await initAuthLogin();
      window.location.replace(authLogin.data);
    } catch (e) {
      console.error(e);
      navigate('/error');
    }
  };
  useEffect(() => {
    invokeEmailPolicy();
  }, []);

  return <></>;
};

export default EmailLogin;
