import { fetchJourney } from '../../../services/journeyService';
import { getLatestReservation, reservationExists } from '../../../services/reservationService';
import { useNavigate } from 'react-router-dom';
import { NavigationURLForPageKey } from '../../../models/PageKey';
import { getUserAccountType, getUserProfile } from '../../../services/userService';
import { ampli } from '../../../ampli';
import { AxiosResponse } from 'axios';
import { Journey } from '../../../models/Journey';
import { is25OrOlder } from '../../../utils/dateUtil';
import { EmailType, sendEmail } from '../../../services/emailService';
import { Reservation } from '../../../models/Reservation';
import { UserProfile } from '../../../models/UserProfile';
import { UserType } from '../../../models/UserTypeResponse';

export enum AccountType {
  OTP_ACCOUNT = 'OTPAccount',
  PASSWORD_ACCOUNT = 'PasswordAccount',
}

const journeyAmplitudeIdentify = (journey: AxiosResponse<Journey>, orchestrator: string) => {
  if (journey?.data?.userId) {
    const properties =
      journey?.data?.metadata?.market ?
        {
          user_azure_id: journey.data.userId,
          market: journey.data.metadata.market,
          orchestrator: orchestrator,
        }
      : { user_azure_id: journey.data.userId, orchestrator: orchestrator };

    ampli.identify(journey.data.userId, properties);
  }
};

const reservationAmplitudeIdentify = async (accountTypeResponse: AxiosResponse<UserType>) => {
  if (accountTypeResponse?.data?.id) {
    const userId = accountTypeResponse.data.id;
    let properties: { user_azure_id: string; market?: string } = { user_azure_id: userId };
    try {
      const reservation = await getLatestReservation();
      if (reservation?.data?.market) {
        properties = { user_azure_id: userId, market: reservation.data.market };
      }
    } catch (e) {
      console.error(e);
    }
    ampli.identify(userId, properties);
  }
};

function useContinueJourneyHook(orchestrator?: string) {
  const navigate = useNavigate();

  async function handleUnderageUserInLA(
    reservationResponse: AxiosResponse<Reservation>,
    userProfileResponse: AxiosResponse<UserProfile>
  ) {
    const [month, day, year] = userProfileResponse.data.dateOfBirth.split('/');
    const data = reservationResponse.data;
    if (
      reservationResponse &&
      data &&
      data.market === 'LA' &&
      data.status !== 'ACTIVE' &&
      !is25OrOlder(year, month, day)
    ) {
      console.log('User is under 25 in LA dashboard');
      await sendEmail({ emailType: EmailType.UNDER_25_LA });
      navigate('/under-25-la');
      return true;
    }
    return false;
  }

  // Helper function to process journey response
  function processJourneyResponse(journeyResponse: AxiosResponse<Journey>) {
    if (journeyResponse?.data?.metadata == null) {
      console.error("Missing journey metadata, can't continue journey");
      navigate('/sign-up');
    } else {
      navigate(NavigationURLForPageKey[journeyResponse?.data?.pageKey]);
    }
  }

  // Global error handler
  function handleGlobalError(e: any) {
    if (e.config?.url.includes('v1/email')) {
      navigate('/under-25-la');
      return;
    }
    navigate('/error');
  }

  // Main continueJourney function
  async function continueJourney() {
    try {
      const journeyResponse = await fetchJourney();
      if (journeyResponse?.status == 200) {
        journeyAmplitudeIdentify(journeyResponse, orchestrator);
        processJourneyResponse(journeyResponse);
        return;
      } else {
        const reservationExistsResponse = await reservationExists();
        if (reservationExistsResponse.status !== 200 || !reservationExistsResponse?.data?.isExist) {
          console.log("Reservation does not exist for this user");
          navigate('/sign-up');
        } else {
          try {
            const reservationResponse = await getLatestReservation();
            const userProfileResponse = await getUserProfile();
            if (await handleUnderageUserInLA(reservationResponse, userProfileResponse)) {
              return;
            }
          } catch (e) {
            console.error(e);
          }
          await navigateAsPerLoginAccountType();
          return;
        }
      }
    } catch (e) {
      handleGlobalError(e);
    }
  }

  async function navigateAsPerLoginAccountType() {
    const accountTypeResponse = await getUserAccountType();
    await reservationAmplitudeIdentify(accountTypeResponse);
    const accountType = accountTypeResponse?.data?.accountType;
    switch (accountType) {
      case AccountType.OTP_ACCOUNT:
        navigate('/create-password');
        break;
      case AccountType.PASSWORD_ACCOUNT:
        navigate('/dashboard');
        break;
      default:
        navigate('/error');
        break;
    }
  }

  return {
    continueJourney,
  };
}

export default useContinueJourneyHook;
