import React, { createContext, useEffect, useState } from 'react';
import Footer from '../../components/Footer/Footer';
import PickupPendingBody from './components/PickupPending/PickupPendingBody/PickupPendingBody';
import PickupPendingBodyV2 from './components/PickupPending/PickupPendingBodyV2/PickupPendingBodyV2';
import { getLatestReservation } from '../../services/reservationService';
import { ReservationStatus } from '../../models/ReservationStatus';
import { UserProfile } from '../../models/UserProfile';
import { getUserProfile } from '../../services/userService';
import WaitlistBody from './components/Waitlist/WaitlistBody/WaitlistBody';
import ActiveBody from './components/Active/ActiveBody/ActiveBody';
import PendingReturnBody from './components/PendingReturn/PendingReturnBody/PendingReturnBody';
import ClosedBody from './components/Closed/ClosedBody/ClosedBody';
import { Reservation } from '../../models/Reservation';
import classes from './Dashboard.module.scss';
import Support from '../../components/PageParts/Support/Support';
import LeaveReservation from '../../components/PageParts/LeaveReservation/LeaveReservation';
import { trackHomeScreenView } from '../../services/analyticsService';
import { atomWithStorage } from 'jotai/utils';
import { useAtom } from 'jotai';
import { showToast } from '../../components/CustomToast/CustomToast';
import { formatMondayDate, is25OrOlder } from '../../utils/dateUtil';
import { useMileagePackage } from './useMileagePackage';
import UserDetailsContainer from '../../components/UserDetailsContainer/UserDetailsContainer';
import LeaveReservationModal from '../../components/LeaveReservationModal/LeaveReservationModal';
import { PricingDisplayInfo } from '../../models/PricingDisplayInfo';
import { newPricingSelectedAtom } from '../../components/PricingTierSwitchConfirmationModal/PricingTierSwitchConfirmationModal';
import { useNavigate } from 'react-router-dom';
import { EmailType, sendEmail } from '../../services/emailService';
import InsuranceAnnouncementModal from '../../components/Modal/InsuranceAnnouncementModal/InsuranceAnnouncementModal';
import InsuranceContactModal from '../../components/Modal/InsuranceContactModal/InsuranceContactModal';
import { getPricingDisplayInfoById } from '../../services/pricingService';
import NeedHelp from '../../components/NeedHelp/NeedHelp';
import DashboardHeader from '../../components/DashboardHeader/DashboardHeader';

export const reservationStorageAtom = atomWithStorage('reservation', null);
export const selectedPricingDisplayInfoAtom = atomWithStorage<PricingDisplayInfo | null>(
  'selectedPricingDisplayInfo',
  null
);

export const PricingDisplayInfoContext = createContext(null);

const Dashboard = () => {
  const navigate = useNavigate();
  const [reservation, setReservation] = useState<Reservation>();
  const [userProfile, setUserProfile] = useState<UserProfile>();
  const [isLeaveReservationModalOpen, setIsLeaveReservationModalOpen] = useState(false);
  const [, setReservationAtom] = useAtom(reservationStorageAtom);
  const [newPricingSelected] = useAtom(newPricingSelectedAtom);
  const [selectedPricingDisplayInfo, setSelectedPricingDisplayInfo] = useAtom(selectedPricingDisplayInfoAtom);
  const { pricingList } = useMileagePackage();
  const [isBanner, setIsBanner] = useState(false);
  const [isInsuranceAnnouncementModalOpen, setIsInsuranceAnnouncementModalOpen] = React.useState(true);
  const [doNotShowAgain, setDoNotShowAgain] = React.useState(false);
  const [insuranceModalAnnouncementProps, setInsuranceAnnouncementProps] = React.useState({});
  const [isInsuranceContactModalOpen, setIsInsuranceContactModalOpen] = React.useState(false);
  const [isInsuredTier, setIsInsuredTier] = React.useState(true);
  const [pricingDisplayInfo, setPricingDisplayInfo] = React.useState<PricingDisplayInfo>();
  // should return true for all states other than closed, returned and removed
  const shouldShowSupportForActiveStates = ![
    ReservationStatus.CLOSED,
    ReservationStatus.RETURNED,
    ReservationStatus.REMOVED,
  ].includes(reservation?.status);
  const shouldShowChargeGuide = reservation?.status !== ReservationStatus.PENDING;
  const shouldShowLeaveReservation = false;
  const shouldShowNeedHelp = [
    ReservationStatus.PENDING,
    ReservationStatus.ACTIVE,
    ReservationStatus.PENDING_RETURN,
  ].includes(reservation?.status);

  const maintenanceBanner = (
    <div className="toast--maintenance" style={{ display: isBanner ? '' : 'none' }}>
      <div className="toast__content__banner">
        <img src={`${process.env.PUBLIC_URL}/assets/images/BlueAlert.svg`} alt="" />
        <img
          onClick={() => setIsBanner(false)}
          src={`${process.env.PUBLIC_URL}/assets/images/x.svg`}
          style={{ cursor: 'pointer' }}
          alt="close icon"
        />
      </div>
    </div>
  );

  useEffect(() => {
    (async () => {
      try {
        const reservationResponse = await getReservation();
        const data = reservationResponse.data;
        setReservation(data);
        setReservationAtom(data);
        //pricingLineDetailsId will be null for closed VR1 reservation since it is not setting in VR2 backend
        if (data?.billingPlanId) {
          const pricingDisplayInfoResponse = await getPricingDisplayInfoById(data?.billingPlanId);
          const pricingDisplayInfoData: PricingDisplayInfo = pricingDisplayInfoResponse.data;
          setPricingDisplayInfo(pricingDisplayInfoData);
          setIsInsuredTier(pricingDisplayInfoData?.insuranceIncluded);
        }
        const userProfileResponse = await getUserProfile();
        setUserProfile(userProfileResponse.data);

        // Check to see if user is under 25 in LA
        const [month, day, year] = userProfileResponse.data.dateOfBirth.split('/');
        if (
          reservationResponse &&
          data &&
          data.market === 'LA' &&
          data.status !== 'ACTIVE' &&
          !is25OrOlder(year, month, day)
        ) {
          console.log('User is under 25 in LA dashboard');
          await sendEmail({ emailType: EmailType.UNDER_25_LA });
          navigate('/under-25-la');
          return;
        }
        shouldSetSelectedPricingDisplayInfo(reservationResponse);
        //boolean check whether date is toastExpired
        const isExpired = new Date(newPricingSelected?.nextMonday).getTime() < new Date().getTime();
        const isExpectedStatus = [ReservationStatus.ACTIVE, ReservationStatus.PENDING_RETURN].includes(
          reservationResponse?.data?.status
        );
        shouldShowToast(isExpired, isExpectedStatus);
      } catch (e) {
        console.error(e);
        if (e.config?.url.includes('v1/email')) {
          navigate('/under-25-la');
          return;
        }
      }
    })();
  }, [pricingList]);

  const getReservation = async () => {
    const reservationResponse = await getLatestReservation();
    if (reservationResponse.status !== 200) {
      trackHomeScreenView(undefined, undefined);
      navigate('/sign-up');
    }
    return reservationResponse;
  };
  const shouldSetSelectedPricingDisplayInfo = (reservationResponse) => {
    if (!selectedPricingDisplayInfo) {
      setSelectedPricingDisplayInfo(
          pricingList.find((p) => p.billingPlanId === reservationResponse.data?.billingPlanId)
      );
    }
  };

  const shouldShowToast = (isExpired: boolean, isExpectedStatus: boolean) => {
    const isAllowedToShowToast = [ReservationStatus.ACTIVE, ReservationStatus.PENDING_RETURN].includes(
        newPricingSelected?.currentReservationStatus
    );
    const mileageToDisplayOnPopUp =
        newPricingSelected?.mileage == 999999 ? 'Unlimited' : newPricingSelected?.mileage.toString();

    if (newPricingSelected.isNew && !isExpired && isExpectedStatus && isAllowedToShowToast) {
      showToast(
          'Lease Package Updated',
          `Your ${mileageToDisplayOnPopUp} mile package will go into effect on ${formatMondayDate(
              new Date(newPricingSelected?.nextMonday)
          )}`,
          `${process.env.PUBLIC_URL}/assets/images/Info.svg`,
          '#026AD2'
      );
    }
  };
  const insuranceAnnouncementModalOnClose = () => {
    setIsInsuranceAnnouncementModalOpen(false);
  };

  const insuranceContactModalOnClose = () => {
    setIsInsuranceContactModalOpen(false);
  };

  const insuranceContactModalOnOpen = () => {
    setIsInsuranceContactModalOpen(true);
  };

  useEffect(() => {
    if (reservation?.status && pricingDisplayInfo?.weeklyRate) {
      trackHomeScreenView(reservation, pricingDisplayInfo.weeklyRate.toString());
    }
  }, [reservation, pricingDisplayInfo]);

  useEffect(() => {
    doNotShowAgain && localStorage.setItem(insuranceModalAnnouncementProps['localStorageItem'], 'true');
  }, [doNotShowAgain]);

  useEffect(() => {
    if (JSON.parse(window.__RUNTIME_CONFIG__.REACT_APP_INSURANCE_LAUNCHED)) {
      setInsuranceAnnouncementProps({
        localStorageItem: 'hideInsurancePostLaunchAnnouncement',
        headerText: 'Insurance Now Included',
        sectionText: (
          <p>
            Our pricing plans have increased to include bundled insurance. If you'd like to change your pricing plan or
            opt-in to our insurance. please call us.
            <br />
          </p>
        ),
        buttonText: 'Call Now',
        buttonAction: insuranceContactModalOnOpen,
      });
    } else {
      setInsuranceAnnouncementProps({
        localStorageItem: 'hideInsurancePreLaunchAnnouncement',
        headerText: 'Insurance Coming Soon',
        sectionText: (
          <p>
            Starting December 06, 2023, our weekly pricing will include bundled insurance.
            <br /> <br />
            If you prefer to keep your own personal insurance, you can continue with your current pricing plan.
            <br /> <br />
            However, if you want to switch pricing plans, you will need to opt into a new weekly plan that includes
            insurance.
            <br />
          </p>
        ),
        buttonText: 'Okay',
        buttonAction: insuranceAnnouncementModalOnClose,
      });
    }
  }, []);

  const renderBody = () => {
    switch (reservation?.status) {
      case ReservationStatus.WAITLISTED:
        return JSON.parse(window.__RUNTIME_CONFIG__.REACT_APP_CC_REMOVED) ?
            <PickupPendingBodyV2 reservation={reservation} />
          : <WaitlistBody />;
      case ReservationStatus.PENDING:
        return JSON.parse(window.__RUNTIME_CONFIG__.REACT_APP_CC_REMOVED) ?
            <PickupPendingBodyV2 reservation={reservation} />
          : <PickupPendingBody locationId={reservation?.pickupLocationId} />;
      case ReservationStatus.ACTIVE:
        return <ActiveBody reservation={reservation} />;
      case ReservationStatus.PENDING_RETURN:
        return <PendingReturnBody reservation={reservation} />;
      case ReservationStatus.RETURNED:
      case ReservationStatus.CLOSED:
      case ReservationStatus.REMOVED:
        return <ClosedBody marketCode={reservation?.market} userProfile={userProfile} />;
      default:
        break;
    }
  };

  const shouldShowAnnouncementModal =
    JSON.parse(window.__RUNTIME_CONFIG__.REACT_APP_INSURANCE_ANNOUNCEMENT) &&
    !JSON.parse(localStorage.getItem(insuranceModalAnnouncementProps['localStorageItem'])) &&
    !isInsuredTier;

  return (
    <div className={classes.container}>
      <DashboardHeader />
      <section className={classes.content}>
        {maintenanceBanner}
        <UserDetailsContainer userProfile={userProfile} reservation={reservation} />
        <PricingDisplayInfoContext.Provider value={pricingDisplayInfo}>
          {renderBody()}
        </PricingDisplayInfoContext.Provider>
        {shouldShowSupportForActiveStates && <Support shouldShowChargeGuide={shouldShowChargeGuide} />}
        {shouldShowNeedHelp && <NeedHelp reservationStatus={reservation.status} />}
        {shouldShowLeaveReservation && <LeaveReservation setIsModalOpen={() => setIsLeaveReservationModalOpen(true)} />}
        <LeaveReservationModal
          reservation={reservation}
          isOpen={isLeaveReservationModalOpen}
          onClose={() => setIsLeaveReservationModalOpen(false)}
        />
        {shouldShowAnnouncementModal && (
          <InsuranceAnnouncementModal
            isOpen={isInsuranceAnnouncementModalOpen}
            onClose={insuranceAnnouncementModalOnClose}
            setDoNotShowAgain={setDoNotShowAgain}
            insuranceModalAnnouncementProps={insuranceModalAnnouncementProps}
          />
        )}
        <InsuranceContactModal isOpen={isInsuranceContactModalOpen} onClose={insuranceContactModalOnClose} />
      </section>
      <Footer />
    </div>
  );
};

export default Dashboard;
