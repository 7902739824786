import React from 'react';
import useContinueJourneyHook from './useContinueJourneyHook';
import classes from './ContinueJourney.module.scss';

const ContinueJourney = () => {
  const { continueJourney } = useContinueJourneyHook();

  const handleContinue = () => {
    continueJourney();
  };

  return (
    <div className={classes.container}>
      <p>Hi, Found like you got dropped in between. Do you want to continue?</p>
      <button data-testid="continue-journey" className={classes.button} onClick={handleContinue}>
        Continue Journey
      </button>
    </div>
  );
};

export default ContinueJourney;
