import React from "react";
import classes from "./AccordingBody.module.scss";
import SubHeader from "./SubHeader";

const MyOptions = () => {
    return (
        <>
            <table className={classes.table}>
                <SubHeader/>
                <tbody className={classes.tbody}>
                    <tr>
                        <td colSpan={2}>
                            <hr className={classes.section_divider}/>
                        </td>
                    </tr>

                    <tr>
                        <td className={classes.padding_bottom}>Standard 120V socket.</td>
                        <td>Installed wall box.</td>
                    </tr>
                    <tr>
                        <td>An easy and available option that requires longer charge times.
                            <strong className={classes.strong}>or</strong>
                            NEMA 14-50 Wall Outlet.
                        </td>
                        <td>Recommended as the best option for home charging.</td>
                    </tr>
                    <tr>
                        <td>Recommended as the second-best option for home charging.</td>
                    </tr>
                </tbody>
            </table>
        </>
    );
};

export default MyOptions;
