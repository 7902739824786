import React from "react";
import classes from "./AccordingBody.module.scss";
import SubHeader from "./SubHeader";

const Need = () => {
    return (
        <>
            <table className={classes.table}>
                <SubHeader/>
                <tbody className={classes.tbody}>
                <tr>
                    <td colSpan={2}>
                        <hr className={classes.section_divider}/>
                    </td>
                </tr>

                <tr>
                    <td>
                        Comes standard. <br/><br/> Need a 15-amp circuit-recommended dedicated. Within range of cord (no
                        extension cord).<strong className={classes.strong}>or</strong>Requires a NEMA 14-50 wall outlet.
                        If not available, installation is done by a licensed electrician. Ford Drive recommends Qmerit.
                    </td>
                    <td>Must purchase the Charging Station and schedule installation with a licensed electrician. Ford
                        Drive Recommends Qmerit.
                    </td>
                </tr>
                </tbody>
            </table>
        </>
    );
};

export default Need;
