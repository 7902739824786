import React, { useEffect, useState } from 'react';
import PersonalInfo from './components/PersonalInfo/PersonalInfo';
import classes from './AccountSettings.module.scss';
import { useNavigate } from 'react-router-dom';
import { UserProfile } from '../../models/UserProfile';
import { getUserProfile } from '../../services/userService';
import NavWebHeader from '../../components/NavWebHeader/NavWebHeader';
import Footer from '../../components/Footer/Footer';
import {reservationExists} from "../../services/reservationService";

const AccountSettings = () => {
  const [userProfile, setUserProfile] = useState<UserProfile>();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const reservationIsExistResponse = await reservationExists();
        if (reservationIsExistResponse.status == 200 && reservationIsExistResponse?.data?.isExist) {
          const userProfileResponse = await getUserProfile();
          setUserProfile(userProfileResponse.data);
        } else {
          navigate('/sign-up');
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  return (
    <>
      <div className="l-height-bg">
        <NavWebHeader title="Account Settings" shouldNav={true} handleBackNavigation={() => navigate(`/dashboard`)} />
        <div className="l-border-radius-bg">
          <div className="l-center">
            <div className={classes.account_setting_content} role="main">
              <div className={classes.body_container}>
                <PersonalInfo userProfile={userProfile} />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AccountSettings;
