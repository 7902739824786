import c from './VerifyingSpinner.module.scss';

const VerifyingSpinner = ({
  height = '483px',
  useBorderRadius = true,
  title = '',
  imageHeight: imageDimensions = 25,
}) => {
  const borderRadius = useBorderRadius ? '0 48px 0 0' : '';

  return (
    <div data-testid={'verifying-spinner'}>
      <div className={c.container} role="main" style={{ height: height, borderRadius: borderRadius }}>
        <section className={c.content}>
          {title && <h5 className={c.title}>{title}</h5>}
          <div className={`${c.expSpinner} spinner`} style={{ height: imageDimensions, width: imageDimensions }}>
            <img
              src={process.env.PUBLIC_URL + '/assets/images/L.png'}
              alt="Logo"
              style={{ height: imageDimensions, width: imageDimensions }}
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default VerifyingSpinner;
