import React from 'react';
import CopyrightNotification from './components/CopyrightNotification';
import DepictionOfVehicles from './components/DepictionOfVehicles';
import Disclaimer from './components/Disclaimer';
import IndemnityAndHoldHarmless from './components/IndemnityAndHoldHarmless';
import IntroductionNote from './components/IntroductionNote';
import LimitedLicenses from './components/LimitedLicenses';
import LinkToTheSite from './components/LinkToTheSite';
import Miscellaneous from './components/Miscellaneous';
import OtherConditions from './components/OtherConditions';
import ThirdPartyInfo from './components/ThirdPartyInfo';
import TradeMarkAndPrivacy from './components/TradeMarkAndPrivacy';
import UnitedStatesSiteAndPricing from './components/UnitedStatesSiteAndPricing';
import classes from './TermsAndConditions.module.scss';
import NavWebHeader from '../../components/NavWebHeader/NavWebHeader';

const TermsAndConditions = () => {
  return (
    <>
      <NavWebHeader title="T & C" shouldNav={false} />
      <div className={classes.wrapper}>
        <div className={classes.body_container}>
          <IntroductionNote />
          <UnitedStatesSiteAndPricing />
          <DepictionOfVehicles />
          <ThirdPartyInfo />
          <LimitedLicenses />
          <LinkToTheSite />
          <OtherConditions />
          <TradeMarkAndPrivacy />
          <IndemnityAndHoldHarmless />
          <CopyrightNotification />
          <Disclaimer />
          <Miscellaneous />
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
