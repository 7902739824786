import React, { useEffect, useState } from 'react';
import c from './Approved.module.scss';
import Footer from '../../components/Footer/Footer';
import DropdownPaymentForm from '../../components/DropdownPaymentForm';
import DropdownPasswordForm from '../../components/DropdownPasswordForm';
import CongratsModal from '../../components/Modal/CongratsModal/CongratsModal';
import {
  trackUserClickedLetGoButtonInApprovedPage,
  trackYouAreApprovedPageLoad,
} from '../../services/analyticsService';
import ProgressBar from '../../components/ProgressBar';
import { useHandleBackButton } from '../../hooks/useHandleBackButton';
import { PageURL } from '../../models/PageKey';

const Approved = () => {
  const [modelOpen, setModelOpen] = useState(true);
  const [isCardSubmitted, setIsCardSubmitted] = useState(false);

  useEffect(() => {
    (async () => {
      trackYouAreApprovedPageLoad();
    })();
  }, []);

  useHandleBackButton(PageURL.CREDIT_INFO);

  function handleCloseModal() {
    setModelOpen(false);
    trackUserClickedLetGoButtonInApprovedPage();
  }

  return (
    <>
      <div className="l-progress-bar-wrapper">
        <ProgressBar currentStep={4} />
      </div>
      <main className={`${c.main} content l-section`}>
        <div>
          <h3 className={c.title} data-testid="heading">
            Next Steps
          </h3>
          <p className={c.desc}>
            No deposit is required. We simply ask for a payment card to save your reservation spot.
            <span className={c.chargeNotice}>
              You will not be charged until you pick up the car and hit the road.
              <span className={c.desc}> Swap your card anytime.</span>
            </span>
          </p>
        </div>

        <section className={`${c.sub} l-margin-top-32`}>
          <h4 className={c.subtitle}>Step 1: Add Payment Card</h4>
          <DropdownPaymentForm setIsCardSubmitted={setIsCardSubmitted} calledFrom="SignUp" />
        </section>

        <section className={`${c.sub} l-margin-top-32`}>
          <h4 className={c.subtitle}>Step 2: Create Password</h4>
          <DropdownPasswordForm expanded={isCardSubmitted} />
        </section>
      </main>
      <Footer />
      <CongratsModal isOpen={modelOpen} onClose={handleCloseModal} />
    </>
  );
};

export default Approved;
