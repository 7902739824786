import React from 'react';
import Dropdownless from '../Dropdownless';
import NeedHelpBody from './NeedHelpBody';
import { ReservationStatus } from '../../models/ReservationStatus';

interface NeedHelpProps {
  reservationStatus: ReservationStatus;
}

const NeedHelp: React.FC<NeedHelpProps> = ({ reservationStatus }) => {
  return (
    <div className="l-center" data-testid="need-help">
      <Dropdownless title="Need Help?">
        <NeedHelpBody reservationStatus={reservationStatus} />
      </Dropdownless>
    </div>
  );
};
export default NeedHelp;
