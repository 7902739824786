import React from "react";
import {Dialog} from "primereact/dialog";
import modalClasses from "../Modal.module.scss";
import classes from "./InsuranceContactModal.module.scss";

const InsuranceContactModal = ({ isOpen, onClose }) => {
  return (
    <Dialog
      className={modalClasses.dialog}
      visible={isOpen}
      onHide={onClose}
      closeOnEscape
      blockScroll
      draggable={false}
      closable={false}
    >
      <div data-testid="insurance-contact-modal-container" className={modalClasses.container}>
        <section className={classes.contact_text}>
          Contact Ford Drive Customer Care
        </section>
        <br />
        <hr />
        <br />
        <a className={classes.link_item} href="tel:1-800-805-5462">
          1-800-805-5462
        </a>
        <div className={modalClasses.redesign_button_container}>
          <button className={modalClasses.redesign_button_white} onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default InsuranceContactModal;
