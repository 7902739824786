import { getRequestForBff } from '../axios/axiosVerbs';
import { PricingDisplayInfo, PricingDisplayInfoWithSelection } from '../models/PricingDisplayInfo';
import { Reservation } from '../models/Reservation';

export type PackageSelectionApiData = {
  reservation: Reservation;
  pricingDisplayInfoWithSelected: PricingDisplayInfoWithSelection[];
};

export const getPricingList = (marketCode: string) =>
  getRequestForBff<PricingDisplayInfo[]>(`v2/pricing/${marketCode}`, true);

export const getPricingDisplayInfoById = (billingPlanId: string) =>
  getRequestForBff<PricingDisplayInfo[]>(`v2/pricing/info/${billingPlanId}`, true);

export function getPackageSelectionFromWidget() {
  return getRequestForBff<PackageSelectionApiData>(`v2/pricing/package-selection`, true);
}

export function getUpcomingPlan() {
  return getRequestForBff<PricingDisplayInfo>(`v2/pricing/plan/upcoming`, true);
}
