import { PaymentMethod, TokenResult } from '@stripe/stripe-js';

export const blockCards = (result: TokenResult) => {
  return ['prepaid', 'unknown'].includes(result.token.card.funding);
};

export const handlePaymentMethod = (pm: string | PaymentMethod) => (typeof pm === 'string' ? pm : pm.id);

export const appearance = {
  theme: 'none',
  rules: {
    '.Input': {
      backgroundColor: '#F6F8FA',
      borderRadius: '10px',
      fontFamily: 'Gill Sans, sans-serif',
      fontSize: '14px',
      height: '38px',
      padding: '14px',
    },
    '.Input:disabled, .Input--invalid:disabled': {
      color: 'lightgray',
    },
    '.Label': {
      color: '#4D4D4D',
      fontFamily: 'Ford Antenna',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '18px',
      marginBottom: '2px',
    },
  },
};
