import { createSearchParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

//this component will redirect the user from /retail to the /sign-up page while appending the flow query param as RETAIL to the url
const RetailFlow = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate({
      pathname: '/sign-up',
      search: `?${createSearchParams({
        flow: 'RETAIL',
      })}`,
    });
  }, [navigate]);
  return null;
};

export default RetailFlow;
