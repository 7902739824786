import { getAllCards, setPrimaryCard } from '../../services/customerService';
import { TokenResult } from '@stripe/stripe-js';
import {
  trackCreditCardAndConfirmationPageNextButtonClick,
  trackCreditCardTileButtonClick,
  trackStripePaymentErrorReceived,
} from '../../services/analyticsService';
import { useEffect, useState } from 'react';
import { CardResponse } from '../../models/CardResponse';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { blockCards, handlePaymentMethod } from './utils';
import { PageKey } from '../../models/PageKey';
import { updateJourney } from '../../services/journeyService';
import { useNavigate } from 'react-router-dom';
import { HTTP_STATUS } from '../../models/HttpStatus';
import { submitErrorToast } from './showErrorOnSubmitToast';
import { addWaitListReservation } from '../../services/reservationService';
import { getPricingDisplayInfoById } from '../../services/pricingService';
import { PricingDisplayInfo } from '../../models/PricingDisplayInfo';
import { Journey } from '../../models/Journey';

export default function useCreditCardConfirmationWrapper(clientSecret, journey: Journey) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState([]);
  const [cards, setCards] = useState<CardResponse[]>([]);
  const [cardElementEmpty, setCardElementEmpty] = useState(true);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [cardFilledIn, setCardFilledIn] = useState(false);

  const onCardFocus = () => {
    trackCreditCardTileButtonClick();
  };

  const enableButton = () => {
    setProcessing(false);
    setEnableSubmit(cardFilledIn);
  };

  const onChangeCard = (e) => {
    if (e.complete === cardFilledIn) return;
    setCardFilledIn(e.complete);
    if (e.complete === enableSubmit || processing) return;
    setEnableSubmit(e.complete);
  };

  async function handleSubmit() {
    trackCreditCardAndConfirmationPageNextButtonClick();
    setProcessing(true);
    setEnableSubmit(false);
    try {
      const card = elements.getElement('card');
      if (cards.length > 0 && cardElementEmpty) {
        await setPrimaryCard('');
        enableButton();
        return;
      }

      const tokenResult: TokenResult = await stripe.createToken(card);
      if (tokenResult.error) {
        setErrorMessage(['Card Error: ' + tokenResult.error.message]);
        trackStripePaymentErrorReceived({ stripe_error: `Token result error: ${tokenResult.error.message}` });
        enableButton();
        return;
      }
      if (blockCards(tokenResult)) {
        setErrorMessage([
          'Invalid card number. Please enter a valid payment card number. Prepaid cards are not accepted.',
        ]);
        trackStripePaymentErrorReceived({ stripe_error: 'Prepaid card entered' });
        enableButton();
        return;
      }

      const setupIntent = await stripe.confirmCardSetup(clientSecret, {
        payment_method: { card },
      });
      if (setupIntent.error) {
        trackStripePaymentErrorReceived({ stripe_error: `Setup intent error: ${setupIntent.error.message}` });
        setErrorMessage(['Card Error: ' + setupIntent.error.message]);
        enableButton();
        return;
      }

      await setPrimaryCard(handlePaymentMethod(setupIntent.setupIntent.payment_method));

      const createReservationResponse = await addWaitListReservation(
        journey.metadata.market,
        journey.metadata.packageId
      );
      if (createReservationResponse.status !== 200) {
        console.error('Create reservation response was not 200');
        enableButton();
        return;
      }
      const reservationId = createReservationResponse.data?.id;
      const journeyResponse = await updateJourney({ externalId: reservationId, pageKey: PageKey.SIGNUP_SUCCESS });
      if (journeyResponse?.status !== 200) {
        console.error('Update journey response was not 200');
        enableButton();
        return;
      }

      navigate('/success');
    } catch (e) {
      trackStripePaymentErrorReceived({ stripe_error: `General error:  ${e}` });
      submitErrorToast();
      enableButton();
    }
  }

  useEffect(() => {
    if (!journey) return;
    (async () => {
      const pricingListResponse = await getPricingDisplayInfoById(journey.metadata.packageId);
      if (pricingListResponse.status !== HTTP_STATUS.OK) {
        console.error('Could not retrieve pricing list');
        navigate('/sign-up');
        return;
      }
      const selectedPricingDisplayInfo: PricingDisplayInfo = pricingListResponse.data;
      setSelectedPackage(selectedPricingDisplayInfo);

      const response = await getAllCards();
      if (response.status === HTTP_STATUS.OK) {
        response.data && setCards(response.data);
      }
    })();
  }, [journey]);

  useEffect(() => {
    (async () => {
      if (elements == undefined) {
        return;
      }

      const card = elements.getElement('card');
      card &&
        card.on('change', function (event) {
          if (event.empty) {
            setCardElementEmpty(true);
          } else {
            setCardElementEmpty(false);
          }
        });
    })();
  }, [elements]);

  return { errorMessage, handleSubmit, elements, selectedPackage, enableSubmit, processing, onChangeCard, onCardFocus };
}
