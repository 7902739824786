import { useEffect, useState } from 'react';
import { createPaymentIntentForBff } from '../services/stripeService';
import { HTTP_STATUS } from '../models/HttpStatus';

export function useCreatePaymentIntent() {
  const [status, setStatus] = useState('idle');
  const [clientSecret, setClientSecret] = useState(null);

  useEffect(() => {
    (async () => {
      setStatus('loading');
      const response = await createPaymentIntentForBff();

      if (response.status !== HTTP_STATUS.OK) {
        setStatus('error');
        console.error('Error creating payment intent.');
        return;
      }

      setClientSecret(response.data.clientSecret);
      setStatus('success');
    })();
  }, []);

  return [status, clientSecret];
}
