import React from "react";
import classes from "./ChargingLevel.module.scss";

const ChargingLevel = () => {
  return (
    <div className={classes.charging_level_container}>
      <div>
        <p className={classes.support_sub_heading}>
          There are three charging levels
        </p>
      </div>
      <div className={classes.charging_level_details}>
        <div className={classes.charging_level}>
          <div className={classes.charging_level_image}>
            <img
              src={"/assets/images/charger.svg"}
              alt="charger-icon"
            />
          </div>
          <div className={classes.charging_level_info}>
            <div className={classes.level_heading}>
              <p>Level 1</p>
            </div>
            <div className={classes.level_discription}>
              <p>
                Longest charge time (120V approx. 90 hrs). Standard home outlets
                are 120V.
              </p>
            </div>
          </div>
        </div>
        <div className={classes.charging_level}>
          <div className={classes.charging_level_image}>
            <img
              src={"/assets/images/HomeCharger.svg"}
              alt="home-charger-icon"
            />
          </div>
          <div className={classes.charging_level_info}>
            <div className={classes.level_heading}>
              <p>Level 2</p>
            </div>
            <div className={classes.level_discription}>
              <p>
                Quick charge time (240V approx. 10 hrs). Most homes can
                accommodate an upgrade to 240V.
              </p>
            </div>
          </div>
        </div>
        <div className={classes.charging_level}>
          <div className={classes.charging_level_image}>
            <img
              src={"/assets/images/PublicCharger.svg"}
              alt="public-charger-icon"
            />
          </div>
          <div className={classes.charging_level_info}>
            <div className={classes.level_heading}>
              <p>Level 3</p>
            </div>
            <div className={classes.level_discription}>
              <p>
                Fastest charge time (480V, 20-30 mins). Available mostly in
                commercial settings (retail centers, gas stations, etc).
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChargingLevel;
