import React, { useEffect, useState } from 'react';

const PickupDropoffLocationBody = ({ classes, version, location, isPickup }) => {
  const [instructions, setInstructions] = useState([]);

  useEffect(() => {
    if (location === undefined) return;
    let _instructions = location.returnInstructions;
    if (isPickup) {
      _instructions = location.pickupInstructions;
    }
    setInstructions(_instructions);
  }, [isPickup, location]);

  return (
    <>
      {version === 'V1' && (
        <p className={classes.vehicle_location_title}>
          {' '}
          {isPickup ? 'Vehicle Pickup Location:' : 'Vehicle Return Location:'}
        </p>
      )}
      <div className={classes.location}>
        <div className={classes['image-container']}>
          {location?.imageURL && (
            <img
              className={classes.image}
              alt="Pickup and Drop off location"
              src={'/assets/images/' + `${location?.imageURL}`}
            />
          )}
        </div>
        <div className={classes.address} data-testid="location-address">
          {location?.address?.line1}
          <br />
          {location?.address?.city}
          {', '}
          {location?.address?.state} {location?.address?.zip}
        </div>

        <div>
          {instructions && instructions.length > 0 && (
            <ul className={classes.location_description}>
              {instructions?.map((direction) => <li key={direction}>{direction}</li>)}
            </ul>
          )}
          {!isPickup && (
            <a
              className={classes.location_contact}
              style={{ color: '#00095B', fontWeight: 700 }}
              href="tel:1-800-805-5462"
            >
              1-800-805-5462 extension#1
            </a>
          )}
        </div>
      </div>
    </>
  );
};

export default PickupDropoffLocationBody;
