import Dropdown from '../Dropdown';
import PaymentFormWrapper from '../PaymentsFormWrapper';
import { useCreatePaymentIntent } from '../../hooks/useCreatePaymentIntent';

function DropdownPaymentForm({ setIsCardSubmitted, calledFrom }) {
  const [createPaymentIntentStatus, clientSecret] = useCreatePaymentIntent();

  return (
    <Dropdown title="Payment Card Info" expanded={false}>
      <PaymentFormWrapper
        status={createPaymentIntentStatus}
        clientSecret={clientSecret}
        setIsCardSubmitted={setIsCardSubmitted}
        calledFrom={calledFrom}
      />
    </Dropdown>
  );
}

export default DropdownPaymentForm;
