import { useEffect, useState } from 'react';
import { ReservationStatus } from '../../models/ReservationStatus';
import { useAtom } from 'jotai';
import { getPackageSelectionFromWidget } from '../../services/pricingService';
import { PricingDisplayInfoWithSelection } from '../../models/PricingDisplayInfo';
import { selectedPricingDisplayInfoAtom } from '../Dashboard/Dashboard';

export default function usePackageSelectionFromWidget() {
  const [reservationStatus, setReservationStatus] = useState<ReservationStatus | null>(null);
  const [reservationMarket, setReservationMarket] = useState<ReservationStatus | null>(null);
  const [pricingDisplayInfoList, setSelectedPricingDisplayInfo] = useState<PricingDisplayInfoWithSelection[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [, setSPDIA] = useAtom(selectedPricingDisplayInfoAtom);

  useEffect(() => {
    (async () => {
      try {
        const response = await getPackageSelectionFromWidget();
        setReservationStatus(response?.data?.reservation?.status as ReservationStatus);
        setReservationMarket(response?.data?.reservation?.market);
        setSelectedPricingDisplayInfo(response?.data?.pricingDisplayInfoWithSelected);

        const selected = response?.data?.pricingDisplayInfoWithSelected.find(
          (pricingDisplayInfo) => pricingDisplayInfo.isSelected
        );
        selected &&
          setSPDIA(
            response?.data?.pricingDisplayInfoWithSelected.find((pricingDisplayInfo) => pricingDisplayInfo.isSelected)
          );
      } catch (error) {
        console.error('Error retrieving package selection from widget: ', error);
      }
    })();
  }, []);

  function updateSelected(billingPlanId: string) {
    setSelectedPricingDisplayInfo(
      pricingDisplayInfoList.map((pricingDisplayInfo) => ({
        ...pricingDisplayInfo,
        isSelected: pricingDisplayInfo.billingPlanId === billingPlanId,
      }))
    );
  }

  function updateIsModalOpen(isModalOpen: boolean) {
    setIsModalOpen(isModalOpen);
  }

  return {
    reservationStatus,
    pricingDisplayInfoList,
    updateSelected,
    isModalOpen,
    updateIsModalOpen,
    reservationMarket,
  };
}
