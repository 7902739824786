import React, {memo} from 'react';
import {useFormContext} from 'react-hook-form';
import {HiddenInput} from '../../FormComponents';
import {InputValueModel} from '../../models';

const Child: React.FC<any> = memo(({register, disabled, value}) => (
    <>
        <HiddenInput
            register={register}
            name="referrer"
            data-testid="referrer-code-input"
            disabled={disabled}
            value={value}
        />
    </>
));

const ReferrerFormField: React.FC<InputValueModel> = ({disabled = false, value = ""}) => {
    const methods = useFormContext();
    return <Child {...methods} disabled={disabled} value={value}/>;
};
export default ReferrerFormField;
