import React from 'react';
import { Invoice } from '../../models/InvoicesResponse';
import classes from './InvoiceCard.module.scss';
import { useNavigate } from 'react-router-dom';

interface Props {
  invoice: Invoice;
  currentDate: number;
}

const InvoiceCard = ({ invoice, currentDate }: Props) => {
  const navigate = useNavigate();

  return (
    <div className={`card--invoice l-grid ${classes['card-grid']}`}>
      <div>
        <div className={`l-flex l-space-between ${classes['card-details']}`}>
          <p className="alt-body">{invoice.displayDate}</p>
          <h4>${(invoice.invoiceTotal / 100).toFixed(2)}</h4>
        </div>
        <hr className="spacer" />
        {invoice.overageMiles > 0 && (
          <p className="font-sm" style={{ color: '#00095B', marginBottom: '12px' }}>
            {invoice.overageMiles} overage miles
          </p>
        )}
      </div>
      <a
        data-testid="invoice-card-link"
        className={classes['invoice-nav-link']}
        onClick={() => {
          navigate('/invoices/' + invoice.id);
        }}
      >
        <img
          src={'/assets/images/CaretRight.svg'}
          alt="Navigation Caret for add payment method page"
          className="caret--nav"
        />
      </a>
      {invoice.status === 'open' && invoice.invoiceDueDate >= currentDate && (
        <span className="pending font-sm">Pending</span>
      )}
      {invoice.status === 'open' && invoice.invoiceDueDate < currentDate && (
        <span className="overdue font-sm">Overdue</span>
      )}
    </div>
  );
};

export default InvoiceCard;
