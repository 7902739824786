import React, { useEffect } from 'react';
import { Invoice, Past } from '../models/InvoicesResponse';
import Support from './PageParts/Support/Support';
import { trackInvoiceView } from '../services/analyticsService';
import InvoiceCard from './InvoiceCard/InvoiceCard';
import PastInvoiceTable from './PastInvoiceTable/PastInvoiceTable';
import NavWebHeader from './NavWebHeader/NavWebHeader';

interface Props {
  invoices: Invoice[];
  pastInvoices: Map<string, Past[]>;
}

const InvoiceHistory = ({ invoices, pastInvoices }: Props) => {
  useEffect(() => {
    trackInvoiceView();
  }, []);

  return (
    <>
      <NavWebHeader title="Your Invoices" />
      <section className="content l-section">
        <p className="font-caps l-margin-bottom-16">WEEKLY INVOICE</p>
        <div style={{ display: 'grid', gridRowGap: '20px' }}>
          {invoices &&
            invoices.map((invoice) => (
              <InvoiceCard key={invoice.id} invoice={invoice} currentDate={new Date().getTime()} />
            ))}
        </div>
      </section>
      {pastInvoices && Object.keys(pastInvoices).length > 0 && <PastInvoiceTable pastInvoices={pastInvoices} />}
      <div style={{ margin: 'auto 24px' }}>
        <Support shouldShowChargeGuide={true} />
      </div>
    </>
  );
};

export default InvoiceHistory;
