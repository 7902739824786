import React, { useEffect } from 'react';
import classes from './SignUp.module.scss';
import SignUpFormFields from '../../components/form/components/SignUpFormFields/SignUpFormFields';
import { Form } from '../../components/form/FormComponents';
import ConsentCheckbox from '../../components/form/components/ConsentCheckbox/ConsentCheckbox';
import useSignup from './useSignUp';
import AnimatedButton from '../../components/AnimatedButton/AnimatedButton';
import { useSearchParams } from 'react-router-dom';
import ReferrerFormField from '../../components/form/components/SignUpFormFields/ReferrerFormField';
import UberLogin from '../UberLogin/UberLogin';
import WorkflowIdFormField from '../../components/form/components/SignUpFormFields/WorkflowIdFormField';
import Footer from '../../components/Footer/Footer';
import Spacer from '../../components/Spacer';
import ProgressBar from '../../components/ProgressBar';

const SignUp = () => {
  const [searchParams] = useSearchParams();
  const {
    defaultValues,
    onSubmit,
    handleSignIn,
    setConsent,
    enableSubmit,
    processing,
    allFieldsDirty,
    setAllFieldsDirty,
  } = useSignup();

  // If the flow is not retail or the referrer is not bonjour.uber.com (means they didn't come from uber), will redirect users to the UBER login page
  const flowId = searchParams.get('flow');
  const referrer = searchParams.get('referrer');
  const isUberFlow = referrer?.includes('bonjour.uber.com');
  const isRetailFlow = JSON.parse(window.__RUNTIME_CONFIG__.REACT_APP_RETAIL_FLOW_FLAG) && flowId === 'RETAIL';

  useEffect(() => {
    if (isRetailFlow || isUberFlow) {
      document.title = 'Ford Drive Login';
    }
  }, []);

  if (!(isRetailFlow || isUberFlow)) {
    return <UberLogin />;
  }

  return (
    <>
      <div className="l-progress-bar-wrapper">
        <ProgressBar currentStep={1} />
      </div>
      <main>
        <div className={classes.wrapper}>
          <div className={classes.signupContentWrapper}>
            <Form
              onSubmit={onSubmit}
              defaultValues={defaultValues}
              allFieldsDirty={allFieldsDirty}
              setAllFieldsDirty={setAllFieldsDirty}
            >
              <SignUpFormFields disabled={processing} />
              <ReferrerFormField value={searchParams.get('referrer') || ''} />
              <WorkflowIdFormField value={searchParams.get('flow') || ''} />
              <ConsentCheckbox consentType="Signup" consentEvent={setConsent} useStatic={true} disabled={processing} />
              <Spacer height={32} />
              <div className={classes.button_container}>
                <AnimatedButton
                  enableSubmit={enableSubmit}
                  processing={processing}
                  text={'Get Started'}
                  disabledColor={'#AEB4BF'}
                  disabledBgColor={'#D6DCEA'}
                  bgColor={'#00095B'}
                  color={'#F8F9FA'}
                />
              </div>
            </Form>
            <Spacer height={30} />
            <p className={classes.already_signed_up_text}>Already signed up?</p>
            <p className={classes.sign_in_text} onClick={handleSignIn}>
              Sign In
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default SignUp;
