import classes from './RememberToBring.module.scss';
import React, { useEffect } from 'react';

const RememberToBringBody = () => {
  const [driverLicenseChecked, setDriverLicenseChecked] = React.useState(
    JSON.parse(localStorage.getItem('driverLicenseChecked') ?? 'false')
  );
  const [smartPhoneChecked, setSmartPhoneChecked] = React.useState(
    JSON.parse(localStorage.getItem('smartPhoneChecked') ?? 'false')
  );
  const [creditCardChecked, setCreditCardChecked] = React.useState(
    JSON.parse(localStorage.getItem('creditCardChecked') ?? 'false')
  );
  const [fasTrackChecked, setFasTrackChecked] = React.useState(
    JSON.parse(localStorage.getItem('fasTrackChecked') ?? 'false')
  );

  useEffect(() => {
    localStorage.setItem('driverLicenseChecked', driverLicenseChecked?.toString());
  }, [driverLicenseChecked]);
  useEffect(() => {
    localStorage.setItem('smartPhoneChecked', smartPhoneChecked?.toString());
  }, [smartPhoneChecked]);
  useEffect(() => {
    localStorage.setItem('creditCardChecked', creditCardChecked?.toString());
  }, [creditCardChecked]);
  useEffect(() => {
    localStorage.setItem('fasTrackChecked', fasTrackChecked?.toString());
  }, [fasTrackChecked]);

  return (
    <div className={classes.wrapper} data-testid="remember-to-bring-body">
      <p className={classes.text}>
        <input
          type="checkbox"
          id="driver-license"
          name="driver-license"
          value="driver-license"
          checked={driverLicenseChecked}
          onChange={(e) => setDriverLicenseChecked(e.target.checked)}
        />
        <label className={classes.label} htmlFor="driver-license">
          Driver License
        </label>
      </p>
      <p className={classes.text}>
        <input
          type="checkbox"
          id="smart-phone"
          name="smart-phone"
          value="smart-phone"
          checked={smartPhoneChecked}
          onChange={(e) => setSmartPhoneChecked(e.target.checked)}
        />
        <label className={classes.label} htmlFor="smart-phone">
          Smart Phone
        </label>
      </p>
      <p className={classes.text}>
        <input
          type="checkbox"
          id="credit-card"
          name="credit-card"
          value="credit-card"
          checked={creditCardChecked}
          onChange={(e) => setCreditCardChecked(e.target.checked)}
        />
        <label className={classes.label} htmlFor="credit-card">
          Payment Card (used for booking)
        </label>
      </p>
      <p className={classes.text}>
        <input
          type="checkbox"
          id="fastrack-tag"
          name="fastrack-tag"
          value="fastrack-tag"
          checked={fasTrackChecked}
          onChange={(e) => setFasTrackChecked(e.target.checked)}
        />
        <label className={classes.label} htmlFor="fastrack-tag">
          FasTrack toll tag (if applicable)
        </label>
      </p>
    </div>
  );
};

export default RememberToBringBody;
