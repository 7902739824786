import Dropdown from '../Dropdown';
import NoShowPolicyBody from './NoShowPolicyBody';

const NoShowPolicy = ({ expanded }) => {
  return (
    <Dropdown title={`"No Show" Policy`} expanded={expanded} data-testid="no-show-policy">
      <NoShowPolicyBody />
    </Dropdown>
  );
};
export default NoShowPolicy;
