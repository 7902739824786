import React from 'react';
import classes from './NavWebHeader.module.scss';
import { useNavigate } from 'react-router-dom';

interface NavHeaderProps {
  title: string;
  shouldNav?: boolean;
  handleBackNavigation?: () => void;
}

const NavArrow = (shouldNav: boolean, handleBackNavigation: () => void) => {
  const navigate = useNavigate();

  if (!shouldNav) return null;

  if (!handleBackNavigation) {
    handleBackNavigation = () => navigate(-1);
  }

  return (
    <div data-testid="back-arrow" onClick={handleBackNavigation} className={classes.arrow_wrapper}>
      <img className={classes['back-arrow']} src={'/assets/images/caretleft.svg'} alt="left arrow" />
    </div>
  );
};

const NavWebHeader = ({ title, shouldNav = true, handleBackNavigation }: NavHeaderProps) => {
  return (
    <header className={classes['nav-header']}>
      {NavArrow(shouldNav, handleBackNavigation)}
      <p className={classes.title}>{title}</p>
    </header>
  );
};

export default NavWebHeader;
