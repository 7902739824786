import { useEffect, useState } from 'react';
import { getPricingList } from '../../services/pricingService';
import { getLatestReservation } from '../../services/reservationService';
import { HTTP_STATUS } from '../../models/HttpStatus';
import { PricingDisplayInfoWithSelection } from '../../models/PricingDisplayInfo';

export const useMileagePackage = () => {
  const [pricingList, setPricingList] = useState<PricingDisplayInfoWithSelection[]>([]);

  const fetchPricingList = async () => {
    const reservationResp = await getLatestReservation();
    if (reservationResp.status !== HTTP_STATUS.OK) {
      console.error('Could not retrieve latest reservation');
      return;
    }

    const response = await getPricingList(reservationResp.data.market);
    if (response.status === HTTP_STATUS.OK) {
      setPricingList(response.data.map((pricing) => ({ ...pricing, isSelected: false })));
    } else {
      setPricingList([]);
    }
  };

  const updatePricingList = (id: string) => {
    const updatedPricingList = pricingList.map((pricing) => {
      if (pricing.billingPlanId === id) {
        return { ...pricing, isSelected: true };
      } else {
        return { ...pricing, isSelected: false };
      }
    });
    setPricingList(updatedPricingList);
  };

  useEffect(() => {
    (async () => {
      await fetchPricingList();
    })();
  }, []);

  return { pricingList, updatePricingList };
};
