import {
  ampli,
  ApplicationApprovalPageDurationProperties,
  ConfirmationPageProperties,
  CreditCardAndConfirmationPageDurationProperties,
  EventOptions,
  ExperianCheckInProgressProperties,
  ExperianErrorReportedProperties,
  ExperianSuccessProperties,
  PackageSelectionPageDurationProperties,
  PackageSelectionTileButtonClickProperties,
  PasswordAfterLoginCreateAccountErrorProperties,
  PricingTierSelectedProperties,
  QualifiedAndPasswordCreationPageCreateAccountErrorProperties,
  SignUpPageDurationProperties,
  UpdateAddressErrorPageDurationProperties,
  UserMarketProperties,
} from '../ampli/index';
import { PricingTierSelectedFromWaitlistFlowProperties, StripePaymentErrorReceivedProperties } from '../ampli';
import { Reservation } from '../models/Reservation';

export const trackContinueToEnterpriseClick = (options?: EventOptions) => ampli.enterpriseCheckYesContinue(options);
export const trackDiscontinueToEnterpriseClick = (options?: EventOptions) => ampli.enterpriseGoBack(options);
export const trackExperianCheck = (properties: ExperianCheckInProgressProperties, options?: EventOptions) =>
  ampli.experianCheckInProgress(properties, options);
export const trackExperianError = (properties: ExperianErrorReportedProperties, options?: EventOptions) =>
  ampli.experianErrorReported(properties, options);
export const trackExperianSuccess = (properties: ExperianSuccessProperties, options?: EventOptions) => {
  ampli.experianSuccess(properties, options);
};

export const trackFooterLinkClick = (footerLink, options?: EventOptions) => {
  ampli.footerLinks(options);
  ampli.identify('', {
    footer_visited: footerLink,
  });
};
export const trackHomeScreenView = (reservation: Reservation, weeklyRate: string, options?: EventOptions) => {
  ampli.homescreenViewed(options);
  ampli.identify(reservation?.userId, {
    reservation_status: reservation?.status,
    weekly_pricing_rate: weeklyRate,
    market_code: reservation?.market,
  });
};
export const amplitudeFlush = () => ampli.client.flush();
export const amplitudeSetTransport = (transport) => ampli.client.setTransport(transport);
export const trackInvoiceView = (options?: EventOptions) => ampli.invoice(options);
export const trackLoginPageView = (options?: EventOptions) => ampli.loginPageViewed(options);
export const trackLogout = (options?: EventOptions) => ampli.logout(options);
export const trackPaymentMethodsView = (options?: EventOptions) => ampli.paymentMethods(options);
export const trackSupportGuideLinkClick = (options?: EventOptions) => ampli.supportGuideLinks(options);
export const trackUserMarket = (properties: UserMarketProperties, options?: EventOptions) =>
  ampli.userMarket(properties, options);
export const trackProfileView = (options?: EventOptions) => ampli.viewProfile(options);
export const trackLeaseButtonClick = (options?: EventOptions) => ampli.leaseButtonClick(options);
export const trackRentalButtonClick = (options?: EventOptions) => ampli.rentalButtonClick(options);
export const trackCreateReservationReachedWithMarketCode = (properties: UserMarketProperties, options?: EventOptions) =>
  ampli.createReservationReachedWithMarketCode(properties, options);
export const trackConfirmationPage = (properties: ConfirmationPageProperties, options?: EventOptions) =>
  ampli.confirmationPage(properties, options);
export const trackExperianGenericError = (properties: ConfirmationPageProperties, options?: EventOptions) =>
  ampli.experianGenericErrorOccurred(properties, options);
export const trackExperianSSNError = (properties: ConfirmationPageProperties, options?: EventOptions) =>
  ampli.experianSsnErrorOccurred(properties, options);
export const trackExperianAddressError = (properties: ConfirmationPageProperties, options?: EventOptions) =>
  ampli.experianAddressErrorOccurred(properties, options);
export const trackPricingTier = (properties: PricingTierSelectedProperties, options?: EventOptions) =>
  ampli.pricingTierSelected(properties, options);
export const trackPricingTierSelectedFromWaitlistFlow = (
  properties: PricingTierSelectedFromWaitlistFlowProperties,
  options?: EventOptions
) => ampli.pricingTierSelectedFromWaitlistFlow(properties, options);
export const trackStripePaymentErrorReceived = (
  properties: StripePaymentErrorReceivedProperties,
  options?: EventOptions
) => ampli.stripePaymentErrorReceived(properties, options);

export const trackPasswordCreationAfterLoginPageLoad = (options?: EventOptions) =>
  ampli.passwordCreationAfterLoginPageLoad(options);
export const trackPasswordAfterLoginCreateAccountButtonClick = (options?: EventOptions) =>
  ampli.passwordAfterLoginCreateAccountButtonClick(options);
export const trackPasswordAfterLoginCreateAccountError = (
  properties: PasswordAfterLoginCreateAccountErrorProperties,
  options?: EventOptions
) => ampli.passwordAfterLoginCreateAccountError(properties, options);

export const trackQualifiedAndPasswordCreationPageLoad = (options?: EventOptions) =>
  ampli.qualifiedAndPasswordCreationPageLoad(options);
export const trackQualifiedAndPasswordCreationPageCreateAccountButtonClick = (options?: EventOptions) =>
  ampli.qualifiedAndPasswordCreationPageCreateAccountButtonClick(options);
export const trackQualifiedAndPasswordCreationPageCreateAccountError = (
  properties: QualifiedAndPasswordCreationPageCreateAccountErrorProperties,
  options?: EventOptions
) => ampli.qualifiedAndPasswordCreationPageCreateAccountError(properties, options);

export const trackSignUpPageDuration = (properties: SignUpPageDurationProperties, options?: EventOptions) =>
  ampli.signUpPageDuration(properties, options);
export const trackSignUpPageLoad = (options?: EventOptions) => {
  ampli.identify('');
  ampli.signUpPageLoad(options);
};
export const trackGetStartedButtonClick = (options?: EventOptions) => ampli.getStartedButtonClick(options);
export const trackSignInButtonClickFromSignUpPage = (options?: EventOptions) =>
  ampli.signInButtonClickFromSignUpPage(options);

export const trackPackageSelectionPageLoad = (options?: EventOptions) => ampli.packageSelectionPageLoad(options);
export const trackPackageSelectionTileButtonClick = (
  properties: PackageSelectionTileButtonClickProperties,
  options?: EventOptions
) => {
  ampli.packageSelectionTileButtonClick(properties, options);
  ampli.identify('', {
    weekly_pricing_rate: properties.weekly_pricing_rate,
  });
};
export const trackPackageSelectionPageDuration = (
  properties: PackageSelectionPageDurationProperties,
  options?: EventOptions
) => ampli.packageSelectionPageDuration(properties, options);

export const trackApplicationApprovalPageLoad = (options?: EventOptions) => ampli.applicationApprovalPageLoad(options);
export const trackApplicationApprovalSubmitButtonClick = (options?: EventOptions) =>
  ampli.applicationApprovalSubmitButtonClick(options);
export const trackApplicationApprovalPageDuration = (
  properties: ApplicationApprovalPageDurationProperties,
  options?: EventOptions
) => ampli.applicationApprovalPageDuration(properties, options);

export const trackSsnInputPageLoad = (options?: EventOptions) => ampli.ssnInputPageLoad(options);
export const trackSsnContinueButtonClick = (options?: EventOptions) => ampli.ssnContinueButtonClick(options);

export const trackCreditCheckGenericErrorPageLoad = (options?: EventOptions) =>
  ampli.creditCheckGenericErrorPageLoad(options);
export const trackCreditCheckGenericErrorContactUsButtonClick = (options?: EventOptions) =>
  ampli.creditCheckGenericErrorContactUsButtonClick(options);

export const trackLowFicoScorePageLoad = (options?: EventOptions) => ampli.lowFicoScorePageLoad(options);
export const trackLowFicoScoreContactUsButtonClick = (options?: EventOptions) =>
  ampli.lowFicoScoreContactUsButtonClick(options);

export const trackUpdateAddressErrorPageLoad = (options?: EventOptions) => ampli.updateAddressErrorPageLoad(options);
export const trackUpdateAddressErrorSubmitButtonClick = (options?: EventOptions) =>
  ampli.updateAddressErrorSubmitButtonClick(options);
export const trackUpdateAddressErrorPageDuration = (
  properties: UpdateAddressErrorPageDurationProperties,
  options?: EventOptions
) => ampli.updateAddressErrorPageDuration(properties, options);

export const trackCreditCardAndConfirmationPageLoad = (options?: EventOptions) =>
  ampli.creditCardAndConfirmationPageLoad(options);
export const trackCreditCardTileButtonClick = (options?: EventOptions) => ampli.creditCardTileButtonClick(options);
export const trackCreditCardAndConfirmationPageNextButtonClick = (options?: EventOptions) =>
  ampli.creditCardAndConfirmationPageNextButtonClick(options);
export const trackCreditCardAndConfirmationPageDuration = (
  properties: CreditCardAndConfirmationPageDurationProperties,
  options?: EventOptions
) => ampli.creditCardAndConfirmationPageDuration(properties, options);

export const trackYouAreApprovedPageLoad = (options?: EventOptions) => ampli.youAreApprovedPageLoad(options);
export const trackCreditCardAddedOnYouAreApprovedPage = (options?: EventOptions) =>
  ampli.creditCardAddedOnYouAreApprovedPage(options);

export const trackUserClickedLetGoButtonInApprovedPage = (options?: EventOptions) =>
  ampli.userClickedLetsGoButtonInApprovedPage(options);
export const trackPasswordCreatedSuccessfullyOnDashboard = (options?: EventOptions) =>
  ampli.passwordCreatedSuccessfullyOnDashboard(options);
export const trackPasswordCreatedSuccessfullyDuringSignup = (options?: EventOptions) =>
  ampli.passwordCreatedSuccessfullyDuringSignUp(options);
export const trackCreditCardAddedSuccessfullyOnDashboard = (options?: EventOptions) =>
  ampli.creditCardAddedSuccessfullyOnDashboard(options);
export const trackCreditCardAddedSuccessfullyDuringSignup = (options?: EventOptions) =>
  ampli.creditCardAddedSuccessfullyDuringSignUp(options);
export const trackCongratsModalLoad = (options?: EventOptions) => ampli.congratsModalLoaded(options);
