import classes from './DefaultError.module.scss';
import { useEffect } from 'react';
import {
  trackCreditCheckGenericErrorContactUsButtonClick,
  trackCreditCheckGenericErrorPageLoad,
} from '../../services/analyticsService';

const DefaultError = ({ setIsModalOpen }) => {
  const onClick = () => {
    trackCreditCheckGenericErrorContactUsButtonClick();
    setIsModalOpen(true);
  };

  useEffect(() => {
    trackCreditCheckGenericErrorPageLoad();
  }, []);

  return (
    <>
      <div className={classes.container}>
        <h4 className={classes.header}>We need your help to move forward.</h4>
        <div className={classes.text}>
          <p>While trying to access your credit profile, we received an error message.</p>
          <p>
            Please contact us below so we can resolve this and get your Mustang Mach-E<sup>&#174;</sup> experience
            started.
          </p>
        </div>
        <button className={`${classes['button-contact']} button--primary`} onClick={onClick}>
          Contact Us
        </button>
      </div>
    </>
  );
};
export default DefaultError;
