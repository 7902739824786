import React from "react";
import classes from "./UpcomingPaymentInfo.module.scss";

const UpcomingPaymentInfo = ({ odometerReading, balance }) => {
  const { mileage, overageMiles, baseMileageLimit } = odometerReading;

  const mileageToDisplay = mileage === -1 ? "---" : mileage;

  const mileageClassName = () => {
    const className = overageMiles <= 0 ? classes.strong : "";
    return mileage === -1
      ? `${classes.text_danger} ${className}`
      : classes.strong;
  };

  const mileageUnvailableMessage =
    mileage === -1 ? (
      <span
        className={`${classes.text_danger} ${classes.mileage_Not_available}`}
      >
        Reading unavailable
      </span>
    ) : (
      <></>
    );

  return (
    <div
      data-testid="upcomingPaymentInfoContainer"
      className={classes.upcoming_payment_container}
    >
      <div data-testid="mileageId">
        <div>
          <span className={mileageClassName()}>{mileageToDisplay}</span>
          {baseMileageLimit < 99999 ? <span> / {baseMileageLimit} miles </span> : <span> miles </span>}
        </div>
        <span>{mileageUnvailableMessage}</span>
      </div>
      <div data-testid="overageMilesId">
        {baseMileageLimit < 99999 ? <span className={`${overageMiles > 0 ? classes.strong : ""}`}> {overageMiles} overage miles </span> : <span> Unlimited miles </span>}
      </div>
      <div id="amount-due" className={classes.miles}>
        ${balance.amountDue.toFixed(2)}
      </div>
    </div>
  );
};

export default UpcomingPaymentInfo;
