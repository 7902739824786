import React from 'react';
import classes from './HomeZipCodeError.module.scss';
import Footer from '../../components/Footer/Footer';

const HomeZipCodeError = () => {
  const handleLeanMore = () => {
    window.open('https://www.forddrive.com', '_blank');
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <a href="https://www.forddrive.com" target="_blank">
          <img alt="Ford Drive" src={'/assets/images/forddrive.svg'} />
        </a>
      </div>
      <div className={classes.zip_error_container}>
        <div className={classes.zip_error_wrapper}>
          <p className={classes.zip_error_title}>Thanks for your interest!</p>
          <div className={classes.zip_error_body}>
            <p>We’re currently not available in your area.</p>
            <p>But we’ve saved your email and will contact you when we are launching near you.</p>
            <p>Feel free to check out our website to learn more.</p>
            <button className={classes.learn_more_btn} onClick={handleLeanMore}>
              Learn More
            </button>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default HomeZipCodeError;
