import React from 'react';
import classes from './PendingReturnDetails.module.scss';
import SubscriptionDetails from '../SubscriptionDetails/SubscriptionDetails';
import SubscriptionCard from '../../../../../components/cards/SubscriptionCard/SubscriptionCard';
import ContactUsModal from '../../../../../components/Modal/ContactUsModal/ContactUsModal';
import { ReservationBodyProps } from '../../../../../models/Reservation';
import DropdownPickupDropoffLocation from '../../../../../components/DropdownPickupDropoffLocation/DropdownPickupDropoffLocation';

const PendingReturnDetails: React.FC<ReservationBodyProps> = ({ reservation }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  return (
    <div className={classes.container}>
      <div
        className={`${classes.upcoming_payments} ${
          reservation?.odometerReading.overageMiles > 0 ? classes.mileage_exceeds : ''
        }`}
      >
        <SubscriptionCard styles={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }} displayArrow={false}>
          <SubscriptionDetails odometerReading={reservation?.odometerReading} balance={reservation?.balance} />
        </SubscriptionCard>
        <p className={classes.subscription_details}>Your lease will end once your vehicle is returned.</p>
        <div className={classes.help_container}>
          <button onClick={() => setIsModalOpen(true)} data-testid="contactHelpCenterId">
            Contact our help center
          </button>
        </div>
      </div>
      <p className={classes.vehicle_return_details_title}>Return Details:</p>
      <DropdownPickupDropoffLocation reservation={reservation} isPickup={false} expanded={true} />
      <ContactUsModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
  );
};

export default PendingReturnDetails;
