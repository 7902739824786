import React from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './ChargingMache.module.scss';

const ChargingMache = () => {
  const navigate = useNavigate();
  return (
    <div className={classes.charging_easy_container}>
      <div className={classes.charging_easy_heading}>
        <p>Home or on the road: Ford Drive makes charging easy.</p>
      </div>
      <div className={classes.charging_easy_info_container}>
        <div className={classes.charging_easy_info}>
          <div className={classes.charging_easy_image}>
            <img src={'/assets/images/charger.svg'} alt="charger-icon" />
          </div>
          <div className={classes.charging_easy_description}>
            Your Mustang Mach-E comes standard with the Ford Mobile Charger, which can plug into any 120 or 240V outlet.
            Or charge your Mustang Mach-E at multiple public charging stations. Find those easily by downloading charge
            point operator Apps
            <strong data-testid="public" className={classes.link} onClick={() => navigate('/support/public-charging')}>
              {' '}
              here
            </strong>
            .
          </div>
        </div>

        <div className={classes.charging_easy_info}>
          <div className={classes.charging_easy_image}>
            <img src={'/assets/images/FordPass.svg'} alt="ford-pass-icon" />
          </div>
          <div className={classes.charging_easy_description}>
            The FordPass™ App allows you to check the status of charge, and any other charging-related notifications.
            Download the FordPass™ App
            <strong data-testid="home" className={classes.link} onClick={() => navigate('/support/home-charging')}>
              {' '}
              here
            </strong>
            .
          </div>
        </div>

        <div className={classes.charging_easy_info}>
          <div className={classes.charging_easy_image}>
            <img src={'/assets/images/RegenerativeBraking.svg'} alt="regenerative-braking-icon" />
          </div>
          <div className={classes.charging_easy_description}>
            The Mustang Mach-E has the ability to recapture energy while braking. Total range can vary based on the
            available energy in the battery and other conditions such as acceleration, terrain and cabin climate.
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChargingMache;
