import React from "react";
import classes from "./AccordingBody.module.scss";
import SubHeader from "./SubHeader";

const FullCharge = () => {
    return (
        <>
            <table className={classes.table}>
                <SubHeader/>
                <tbody className={classes.tbody}>
                <tr>
                    <td colSpan={2}>
                        <hr className={classes.section_divider}/>
                    </td>
                </tr>

                <tr>
                    <td>95 hours from 0-100% <sup>**</sup> <strong className={classes.strong}>or</strong> 15 hours from 0-100% <sup>**</sup></td>
                    <td>10.9 hours from 0-100% <sup>**</sup></td>
                </tr>
                </tbody>
            </table>
        </>
    );
};

export default FullCharge;
