import Footer from '../../components/Footer/Footer';
import usePackageSelectionFromWidget from './usePackageSelectionFromWidget';
import { hideToasts } from '../../components/CustomToast/CustomToast';
import { ReservationStatus } from '../../models/ReservationStatus';
import { isTimeBetween11pmSundayESTAnd2pmMondayEST } from '../../utils/dateUtil';
import { useAtom } from 'jotai';
import React, { useEffect } from 'react';
import PricingTierSwitchConfirmationModal from '../../components/PricingTierSwitchConfirmationModal/PricingTierSwitchConfirmationModal';
import MachEBanner from '../ChoosePackage/MachEBanner';
import { PackageCard } from '../ChoosePackage/PackageCard';
import { selectedPricingDisplayInfoAtom } from '../Dashboard/Dashboard';
import ChangePlanNoBundle from '../../components/ChangePlanNoBundle';
import NavWebHeader from '../../components/NavWebHeader/NavWebHeader';

const activeStates = [ReservationStatus.ACTIVE, ReservationStatus.PENDING_RETURN];

export default function PackageSelectionFromWidget() {
  const {
    reservationStatus,
    pricingDisplayInfoList,
    updateSelected,
    isModalOpen,
    updateIsModalOpen,
    reservationMarket,
  } = usePackageSelectionFromWidget();
  const [selectedPricingDisplayInfo] = useAtom(selectedPricingDisplayInfoAtom);

  const isActiveState = activeStates.includes(reservationStatus);
  const oneMonthLeaseMinimumMarkets = ['SD', 'BA', 'LA'];

  let infoText =
    isActiveState ?
      'Select the lease package below that best fits your needs. Switch as many times as needed before vehicle pickup.'
    : 'Select the lease package below that best fits your needs. Switch as many times as needed.*';
  infoText +=
    oneMonthLeaseMinimumMarkets.includes(reservationMarket?.valueOf()) ?
      ' Reminder: You cannot cancel until you have met the one month lease minimum.'
    : ' Reminder: You cannot cancel until you have met the four month lease minimum.';
  const selected = pricingDisplayInfoList?.find((pricingDisplayInfo) => pricingDisplayInfo.isSelected)?.id;
  const shouldDisableButton =
    selected ?
      selectedPricingDisplayInfo?.id ===
      pricingDisplayInfoList?.find((pricingDisplayInfo) => pricingDisplayInfo.isSelected)?.id
    : true;
  const shouldShowManualSwitching =
    pricingDisplayInfoList?.find((pricingDisplayInfo) => pricingDisplayInfo.isSelected)?.insuranceIncluded === false;

  useEffect(() => {
    hideToasts('copy');
  }, []);

  return (
    <>
      <NavWebHeader title="Package Selection" />
      <main className="content l-section">
        <h4>Choose Lease Package</h4>
        {shouldShowManualSwitching ?
          <img src={'/assets/images/mach-e-select.png'} alt="mach-e-select" style={{ marginTop: '15px' }} />
        : <MachEBanner />}
        <p style={{ color: '#4D4D4D', marginTop: '26px' }}>{infoText}</p>
        <section className="l-margin-top-32" style={{ display: 'grid', gridRowGap: '20px' }}>
          <PackageCard
            pricingDisplayInfoWithSelectedList={pricingDisplayInfoList || []}
            handleClick={updateSelected}
            showSelectedOnly={shouldShowManualSwitching}
          />
        </section>
        {shouldShowManualSwitching ?
          <ChangePlanNoBundle />
        : <div className="l-abs-center l-margin-top-32">
            <button
              className="button--primary"
              disabled={isTimeBetween11pmSundayESTAnd2pmMondayEST(new Date()) || shouldDisableButton}
              onClick={() => updateIsModalOpen(true)}
            >
              Next
            </button>
          </div>
        }
        {isActiveState && !shouldShowManualSwitching && (
          <p className="font-sm l-margin-top-32" style={{ color: '#4D4D4D' }}>
            * Switching packages will be temporarily unavailable from 11pm EST Sunday to 2pm EST Monday. Please switch
            before or after this time period.
          </p>
        )}
        <PricingTierSwitchConfirmationModal
          isOpen={isModalOpen}
          selectedPackage={pricingDisplayInfoList?.find((pricingDisplayInfo) => pricingDisplayInfo.isSelected)}
          onClose={() => updateIsModalOpen(false)}
        />
      </main>
      <Footer />
    </>
  );
}
