import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Input } from '../../FormComponents';
import classes from './BirthDateFormFields.module.scss';
import { FormModel, InputModel } from '../../models';

const Child: React.FC<FormModel> = memo(({ register, formState: { errors }, disabled }) => (
  <>
    <Input
      register={register}
      name="birthYear"
      title="Birth Year"
      containerClassName={classes.input_width_high}
      placeholder="0000"
      maxLength={4}
      errors={errors}
      hideErrors={true}
      disabled={disabled}
      rules={{
        required: {
          value: true,
          message: 'Required',
        },
        minLength: {
          value: 4,
          message: '4 digits required',
        },
        pattern: {
            value:  /^(19\d\d|20[0-2][0-2])$/,
          message: 'Invalid',
        },
      }}
    />
  </>
));
const BirthYearFormField: React.FC<InputModel> = ({ disabled = false }) => {
  const methods = useFormContext();
  return <Child {...methods} disabled={disabled} />;
};
export default BirthYearFormField;
