import React from 'react';
import classes from './ActiveDetails.module.scss';
import SubscriptionCard from '../../../../../components/cards/SubscriptionCard/SubscriptionCard';
import {ReservationBodyProps} from '../../../../../models/Reservation';
import MileageSnapshot from '../Mileage/MileageSnapshot';
import InvoiceSnapshot from '../Invoice/InvoiceSnapshot';
import CreditCardDetails from '../../../../../components/CreditCardDetails/CreditCardDetails';

const ActiveDetails: React.FC<ReservationBodyProps> = ({ reservation }) => {
  const invoiceDateToDisplay =
    reservation.stripeLatestInvoice == null ? '' : reservation?.stripeLatestInvoice.displayDate;
  const latestInvoice =
    reservation?.stripeLatestInvoice == null ?
      { totalMilesDriven: -1, invoiceTotal: 0, overageMiles: 0, baseMileageLimit: 0 }
    : reservation?.stripeLatestInvoice;

  const DateDiff = {
    inDays: function (d1, d2) {
      const t2 = d2.getTime();
      const t1 = d1.getTime();
      return (t2 - t1) / (24 * 3600 * 1000);
    },
  };

  const isWeek1 = () => {
    if (reservation?.actualPickUpDate) {
      const today = new Date();
      const actu = new Date(reservation.actualPickUpDate);
      const diffDays = DateDiff.inDays(actu, today);
      if (diffDays >= 7 || actu.getDay() >= today.getDay() || (actu.getDay() == 0 && today.getDay() != 0)) {
        return false;
      }
      return true;
    }
  };

  return (
    <div className={classes.vehicle_details_container}>
      <h2>MILEAGE SNAPSHOT</h2>
      <div
        className={`${classes.upcoming_payments_container} ${
          reservation?.odometerReading.overageMiles > 0 ? classes.mileage_exceeds : ''
        }`}
      >
        <SubscriptionCard subscriptionText={`${reservation?.displayDate}`} displayArrow={false}>
          <MileageSnapshot odometerReading={reservation?.odometerReading} />
        </SubscriptionCard>
      </div>
      {!isWeek1() && (
        <>
          <div className={`${classes.h2_invoice_spacing}`}>
            <h2>INVOICE</h2>
          </div>
          <div className={`${classes.previous_invoice_container}`}>
            <SubscriptionCard subscriptionText={invoiceDateToDisplay} linkUrl="/invoices">
              <InvoiceSnapshot stripeLatestInvoice={latestInvoice} />
            </SubscriptionCard>
          </div>
        </>
      )}
      <CreditCardDetails />
    </div>
  );
};

export default ActiveDetails;
