import React from 'react';
import classes from './NavCard.module.scss';
import {useNavigate} from "react-router-dom";

interface Props {
    label: string;
    navTo: string;
}

const NavCard = ({label, navTo}: Props) => {
    const navigate = useNavigate();

    return (
        <div className={classes.container} onClick={() => navigate(navTo)}>
            <p className={classes.label}>{label}</p>
            <img src={"/assets/images/CaretRight.svg"} alt="Navigation Caret for add payment method page"/>
        </div>
    );
};

export default NavCard;
