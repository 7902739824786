import React from 'react';
import classes from '../Modal.module.scss';
import { Dialog } from 'primereact/dialog';
import { removeWaitlistReservation } from '../../services/reservationService';
import { useNavigate } from 'react-router-dom';
import { ModalProps } from '../Modal/ModalUtils';

const LeaveReservationModal: React.FC<ModalProps> = ({ isOpen, onClose, reservation }) => {
  const navigate = useNavigate();

  const leaveWaitlist = async () => {
    const reservationResponse = await removeWaitlistReservation();
    if (reservationResponse.data) {
      reservation.status = reservationResponse.data.status;
      onClose();
    } else {
      navigate('/error');
    }
  };

  return (
    <Dialog className={classes.dialog} visible={isOpen} onHide={onClose} closeOnEscape blockScroll draggable={false}>
      <div data-testid="modal-container" className={classes.container}>
        <p className={classes.header}>Leave waitlist - are you sure?</p>
        <section className={classes.text}>
          <p>Once you leave the waitlist you will need to re-join, this could delay getting a vehicle further.</p>
        </section>
        <div className={classes.button_container}>
          <button className={classes.button_blue} onClick={() => onClose()}>
            Cancel
          </button>
          <button className={classes.button_red} onClick={() => leaveWaitlist()}>
            Leave Waitlist
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default LeaveReservationModal;
