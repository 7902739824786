import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Input } from '../../FormComponents';
import classes from './SignUpFormFields.module.scss';
import { InputModel } from '../../models';

const Child: React.FC<any> = memo(({ register, formState: { errors }, disabled }) => (
  <>
    <Input
      register={register}
      name="lastName"
      title="Last Name"
      placeholder="Last Name"
      maxLength={128}
      data-testid="last-name-input"
      labelClassName={classes.input_label}
      errors={errors}
      lightColor={true}
      disabled={disabled}
      rules={{
        required: {
          value: true,
          message: 'Last name is required',
        },
        pattern: {
          value: /^[\p{L}\p{M}'-]{1,50}$/u,
          message: 'Enter a valid Last Name',
        },
      }}
    />
  </>
));

const LastNameFormField: React.FC<InputModel> = ({ disabled = false }) => {
  const methods = useFormContext();
  return <Child {...methods} disabled={disabled} />;
};
export default LastNameFormField;
