import c from './CreatePassword.module.scss';
import React, { useEffect, useState } from 'react';
import {
  setPassword as passwordRequest,
  setPasswordWithConsent as passwordWithConsentRequest,
} from '../../services/userService';
import Footer from '../../components/Footer/Footer';
import NavWebHeader from '../../components/NavWebHeader/NavWebHeader';
import AnimatedButton from '../../components/AnimatedButton/AnimatedButton';
import { useNavigate } from 'react-router-dom';
import VerifyingSpinner from '../../components/VerifyingSpinner/VerifyingSpinner';
import consentCheckBoxScss from '../../components/form/ConsentCheckBox/ConsentCheckBox.module.scss';
import {
  trackPasswordAfterLoginCreateAccountButtonClick,
  trackPasswordAfterLoginCreateAccountError,
  trackPasswordCreatedSuccessfullyOnDashboard,
  trackPasswordCreationAfterLoginPageLoad,
} from '../../services/analyticsService';
import { getLoggedInCustomer } from '../../services/noAuthFlowService';
import { reservationExists } from '../../services/reservationService';
import { updateJourney } from '../../services/journeyService';
import { PageKey } from '../../models/PageKey';
import ConsentCheckBox from '../../components/form/ConsentCheckBox/ConsentCheckBox';
import { getConsentByExternalId } from '../../services/consentService';

const CreatePassword = () => {
  const navigate = useNavigate();
  const [showContent, setShowContent] = useState(false);
  const [userId, setUserId] = useState('');
  const [email, setEmail] = useState('');
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [oneLowercase, setOneLowercase] = useState(false);
  const [oneUppercase, setOneUppercase] = useState(false);
  const [oneNumber, setOneNumber] = useState(false);
  const [oneSpecialCharacter, setOneSpecialCharacter] = useState(false);
  const [min8Characters, setMin8Characters] = useState(false);
  const [noWhitespace, setNoWhitespace] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [showConsentBox, setShowConsentBox] = useState(true);
  const [consentBoxChecked, setConsentBoxChecked] = useState(false);
  const [consented, setConsented] = useState(false);
  const [consentCalled, setConsentCalled] = useState(false);
  const [reservationCalled, setReservationCalled] = useState(false);
  const validations = [
    oneLowercase,
    oneUppercase,
    oneNumber,
    oneSpecialCharacter,
    min8Characters,
    noWhitespace,
    passwordsMatch,
    consented,
  ];
  const fetchErrorMessage = 'There was an error processing your request. Please try again.';

  useEffect(() => {
    isUserLoggedIn().catch((e) => {
      console.error(e);
      navigate('/sign-up');
    });
    isReservationExists().catch((e) => {
      console.error(e);
      navigate('/sign-up');
    });
    isUserConsented();
  }, []);

  useEffect(() => {
    if (showContent) trackPasswordCreationAfterLoginPageLoad();
  }, [showContent]);

  useEffect(() => {
    setShowContent(userId && reservationCalled && consentCalled);
  }, [userId, reservationCalled, consentCalled]);

  useEffect(() => {
    setConsented(!showConsentBox || consentBoxChecked);
  }, [showConsentBox, consentBoxChecked]);
  const isUserLoggedIn = async () => {
    const loggedInUserResponse = await getLoggedInCustomer();
    if (loggedInUserResponse?.status !== 200) throw new Error('logged in user response was not 200');
    if (loggedInUserResponse && loggedInUserResponse.status == 200) {
      setUserId(loggedInUserResponse.data.userId);
      setEmail(loggedInUserResponse.data.email);
    }
  };
  const isReservationExists = async () => {
    const reservationExistsResponse = await reservationExists();
    if (!reservationExistsResponse?.data?.isExist) throw new Error('reservation does not exist for user');
    setReservationCalled(true);
  };
  const isUserConsented = async () => {
    const consent = await getConsentByExternalId();
    if (consent && consent.data && consent.status === 200) {
      const consentData = consent.data;
      const legalLanguageCodeList = consentData.map((x) => x.legalLanguageCode);
      const allRequiredLegalLanguageCodeList = [
        window.__RUNTIME_CONFIG__.REACT_APP_PRIVACY_CONSENT_CODE,
        window.__RUNTIME_CONFIG__.REACT_APP_COLLECTION_CONSENT_CODE,
        window.__RUNTIME_CONFIG__.REACT_APP_TERMS_CONSENT_CODE,
      ];
      setShowConsentBox(!allRequiredLegalLanguageCodeList.every((i) => legalLanguageCodeList.includes(i)));
    }
    setConsentCalled(true);
  };

  const handleConsentBoxChecked = (e) => {
    setConsentBoxChecked(e.target.checked);
  };
  const validate = (v) => {
    setPassword(v);
    setOneNumber(/\d/.test(v));
    setOneLowercase(/[a-z]/.test(v));
    setOneUppercase(/[A-Z]/.test(v));
    setOneSpecialCharacter(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(v));
    setMin8Characters(v.length >= 8);
    setNoWhitespace(!/\s/.test(v));
    setPasswordsMatch(v === confirmation);
  };

  const validateConfirmation = (v) => {
    setConfirmation(v);
    setPasswordsMatch(password === v);
  };

  useEffect(() => {
    setEnableSubmit(validations.every((v) => v));
  }, validations);

  useEffect(() => {
    if (!password) {
      setNoWhitespace(false);
      setPasswordsMatch(false);
    }
  }, [password, confirmation]);

  const handleSubmit = async () => {
    setFetchError(false);
    setProcessing(true);
    setEnableSubmit(false);
    try {
      trackPasswordAfterLoginCreateAccountButtonClick();
      if (showConsentBox) {
        const passwordWithConsentRes = await passwordWithConsentRequest({
          id: userId,
          password: password,
          consent: `${window.__RUNTIME_CONFIG__.REACT_APP_PRIVACY_CONSENT_CODE},${window.__RUNTIME_CONFIG__.REACT_APP_COLLECTION_CONSENT_CODE},${window.__RUNTIME_CONFIG__.REACT_APP_TERMS_CONSENT_CODE}`,
        });
        if (passwordWithConsentRes.status !== 200)
          throw new Error(`password with consent response was ${passwordWithConsentRes.status} not 200`);
      } else {
        const passwordRes = await passwordRequest({ id: userId, password: password });
        if (passwordRes.status !== 200) throw new Error(`password response was ${passwordRes.status} not 200`);
      }

      setProcessing(false);
      setSuccess(true);
      trackPasswordCreatedSuccessfullyOnDashboard();
    } catch (e) {
      trackPasswordAfterLoginCreateAccountError({ error_msg: fetchErrorMessage });
      console.error(e);
      setFetchError(true);
      setEnableSubmit(true);
      setProcessing(false);
    }
  };

  const validation = (validationPassed, text, testId) => {
    return validationPassed ?
        <div className={c.validation}>
          <img
            src={process.env.PUBLIC_URL + '/assets/images/check.png'}
            alt={'check'}
            className={`${c.left} ${c.check}`}
            data-testid={`check-${testId}`}
          />
          <div className={`${c.right} ${c.check}`}>{text}</div>
        </div>
      : <div className={c.validation}>
          <img
            src={process.env.PUBLIC_URL + '/assets/images/bullet.png'}
            alt={'bullet'}
            className={`${c.left} ${c.bullet}`}
            data-testid={`bullet-${testId}`}
          />
          <div className={`${c.right} ${c.bullet}`}>{text}</div>
        </div>;
  };

  const successAnimationEnd = () => {
    navigate('/dashboard');
  };

  const navigateBack = async () => {
    try {
      const journeyResponse = await updateJourney({
        pageKey: PageKey.PAYMENT,
      });
      if (journeyResponse?.status !== 200) {
        console.error('Error updating journey: ', journeyResponse?.status);
        return;
      }
      navigate('/payments');
    } catch (e) {
      navigate('/error');
    }
  };
  return (
    <>
      <div className={c.CreatePassword} data-testid={'create-password'}>
        <NavWebHeader title="SignUp - Register Account" shouldNav={true} handleBackNavigation={navigateBack} />
        {showContent ?
          <div className={c.containerCard}>
            <div className={c.container}>
              <div className={c.title}>Lets set up your password</div>
              <div className={c.accountTitle}>
                Account <span className={c.smallText}>(optional)</span>
              </div>
              <div className={c.email}>{email}</div>
              <input
                className={c.password}
                type="password"
                placeholder="Password*"
                onChange={(e) => validate(e.target.value)}
                disabled={processing || success}
                maxLength={50}
                data-testid={'password'}
              />
              <div className={c.validationsContainer}>
                <div className={`${c.validationsColumn} ${c.col1}`}>
                  {validation(oneLowercase, 'One lowercase character', 'lowercase')}
                  {validation(oneUppercase, 'One uppercase character', 'uppercase')}
                  {validation(oneNumber, 'One number', 'number')}
                </div>
                <div className={`${c.validationsColumn} ${c.col2}`}>
                  {validation(oneSpecialCharacter, 'One special character', 'special')}
                  {validation(min8Characters, '8 character minimum', '8minimum')}
                  {validation(noWhitespace, 'No spaces', 'whiteSpace')}
                </div>
              </div>
              <input
                className={`${c.password} ${c.confirmation}`}
                type="password"
                placeholder="Confirm Password*"
                onChange={(e) => validateConfirmation(e.target.value)}
                disabled={processing || success}
                maxLength={50}
                data-testid={'confirmation'}
              />
              <div className={c.validationsContainer}>
                <div className={`${c.validationsColumn} ${c.col1}`}>
                  {validation(passwordsMatch, 'Passwords must match', 'passwordsMatch')}
                </div>
              </div>
              {showConsentBox && (
                <ConsentCheckBox
                  htmlContent={
                    'I agree to the website <a href=https://forddrive.com/termsandconditions> Terms of Use </a> and acknowledge the <a href=https://www.ford.com/help/privacy/> Privacy Notice </a> & <a href=https://www.ford.com/help/privacy/#caPrivacy> Notice at Collection </a>'
                  }
                  checkbox_label={consentCheckBoxScss.checkbox_createpassword_label}
                  onChange={handleConsentBoxChecked}
                ></ConsentCheckBox>
              )}
              <div className={c.buttonTopSpacer} />
              <AnimatedButton
                enableSubmit={enableSubmit}
                processing={processing}
                success={success}
                text={'Create Account'}
                handleSubmit={handleSubmit}
                onAnimationEnd={successAnimationEnd}
              />
              {fetchError && (
                <div className={c.fetchError} data-testid={'fetch-error'}>
                  There was an error processing your request.
                  <br />
                  Please try again.
                </div>
              )}
              <div className={c.spacerBottom} />
            </div>
          </div>
        : <VerifyingSpinner height={'511px'} />}
        <Footer />
      </div>
    </>
  );
};

export default CreatePassword;
