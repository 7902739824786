
import React from "react";
import classes from "../TermsAndConditions.module.scss";

const UnitedStatesSiteAndPricing = () => {
  return (
    <>
      <div className={classes.tc_heading}>
        <p>United States Site and Pricing</p>
      </div>
      <div className={classes.tc_area}>
        <div className={classes.tc_conditions}>
          <div className={classes.tc_condition}>
            Ford administers this Site from Michigan, United States of America
            and this Site is only intended for viewing in the United States. All
            materials and information contained on this Site apply to the United
            States market only. Ford makes no representation that the Site is
            appropriate or available for use outside the United States. If you
            choose to access the Site from outside the United States, you do so
            at your own initiative and are responsible for compliance with
            applicable laws. You agree to comply with all export and re-export
            laws, restrictions and regulations of your government in connection
            with your use of the Site.
          </div>
          <div className={classes.tc_condition}>
            All pricing is in U.S. dollars and the vehicles described are
            offered for sale in the U.S. only. This Site in no way constitutes
            an offer to buy or sell vehicles from Ford. Ford makes reasonable
            efforts to ensure that the pricing and product information contained
            on this Site is correct. Prices, product specifications and all
            other information shown on this Site are for information purposes
            only, are subject to change at any time without obligation, may vary
            from region to region, and may not be completely up to date or
            accurate. Most pricing refers to MSRP. Unless otherwise indicated,
            MSRP is the manufacturer suggested retail price and excludes
            destination and delivery charges, any applicable taxes, any
            applicable fees, such as title and registration, or environmental
            charges or fees, any industry specific fee, any applicable consumer
            rebates or incentives, and the cost of any dealer added options.
            Optional equipment is not included unless specifically indicated.
            Any calculation of any price, tax, incentive, lease or finance terms
            is for your reference only, is an estimate, and may not be
            completely accurate. The promotional programs and/or incentives
            offered on this Site are only available in the United States and may
            be limited to particular states and subject to restrictions and/or
            qualifications, as disclosed by the program.
          </div>
        </div>
      </div>
    </>
  );
};

export default UnitedStatesSiteAndPricing;