import { useNavigate } from 'react-router-dom';
import { useGetJourney } from '../Orchestrator/useJourney/useGetJourney';
import { PageURL } from '../../models/PageKey';
import { useEffect, useState } from 'react';
import { createCustomerForBff, createPaymentIntentForBff } from '../../services/stripeService';
import { appearance } from './utils';
import {
  trackCreditCardAndConfirmationPageDuration,
  trackCreditCardAndConfirmationPageLoad,
  trackExperianSuccess,
} from '../../services/analyticsService';
import { is25OrOlder } from '../../utils/dateUtil';
import { getUserProfile } from '../../services/userService';
import { usePageDuration } from '../../hooks/usePageDuration';
import { EmailType, sendEmail } from '../../services/emailService';
import { ErrorType } from '../ErrorViewer/ErrorViewer';

export default function useCreditCardConfirmation() {
  const navigate = useNavigate();
  const { shouldShowContent, journey } = useGetJourney(PageURL.CREDIT_INFO);
  const [elementOptions, setElementOptions] = useState(null);

  usePageDuration(trackCreditCardAndConfirmationPageDuration, shouldShowContent);

  useEffect(() => {
    if (!shouldShowContent) return;
    trackCreditCardAndConfirmationPageLoad();
    (async () => {
      try {
        const userProfileResponse = await getUserProfile();
        const [month, day, year] = userProfileResponse.data.dateOfBirth.split('/');
        if (journey.metadata && journey.metadata.market === 'LA' && !is25OrOlder(year, month, day)) {
          console.error('Error user under 25 in LA');
          await sendEmail({ emailType: EmailType.UNDER_25_LA });
          navigate('/under-25-la');
          return;
        }

        const { status } = await createCustomerForBff(journey.metadata.market);
        if (status !== 200) {
          console.error('Error creating customer');
          return;
        }

        const clientSecretResponse = await createPaymentIntentForBff();
        if (clientSecretResponse.status !== 200) {
          console.error('Error creating payment intent');
          navigate('/error/' + ErrorType.WAITLIST);
          return;
        }
        if (!clientSecretResponse?.data?.clientSecret) {
          console.error('No client secret');
          navigate('/error/' + ErrorType.WAITLIST);
          return;
        }

        setElementOptions({
          clientSecret: clientSecretResponse.data.clientSecret,
          appearance,
        });
        trackExperianSuccess({ market_code: '' });
      } catch (e) {
        navigate('/error/' + ErrorType.WAITLIST);
      }
    })();
  }, [shouldShowContent]);

  return { shouldShowContent, elementOptions, journey };
}
