import classes from './NeedHelp.module.scss';
import React from 'react';
import { ReservationStatus } from '../../models/ReservationStatus';
import InsuranceSupportModal from '../Modal/InsuranceSupportModal/InsuranceSupportModal';

interface NeedHelpBodyProps {
  reservationStatus: ReservationStatus;
}

const NeedHelpBody: React.FC<NeedHelpBodyProps> = ({ reservationStatus }) => {
  const [isInsuranceModelOpen, setIsInsuranceModelOpen] = React.useState(false);
  return (
    <div className={classes.wrapper}>
      <p
        className={`${classes.content} ${
          reservationStatus == ReservationStatus.ACTIVE ? classes.call_customer_care : ''
        }`}
      >
        General Support: 8am - 5pm PT, M-F
      </p>
      <button className="button-pickup" onClick={() => (window.location.href = 'tel:800-805-5462')}>
        Call Customer Care
      </button>
      {reservationStatus == ReservationStatus.ACTIVE && (
        <>
          <p className={`${classes.content} ${classes.call_roadside_assistance}`}>Roadside & Collision Support: 24/7</p>
          <button className="button-pickup" onClick={() => setIsInsuranceModelOpen(true)}>
            Call Roadside Assistance
          </button>
          <InsuranceSupportModal isOpen={isInsuranceModelOpen} onClose={() => setIsInsuranceModelOpen(false)} />
        </>
      )}
    </div>
  );
};

export default NeedHelpBody;
