import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Input } from '../../FormComponents';
import { FormModel, InputModel } from '../../models';

const Child: React.FC<FormModel> = memo(
    ({register, formState: {errors}, disabled}) => (
        <>
            <Input register={register}
                   name='city'
                   title='City'
                   placeholder='City'
                   maxLength={20}
                   errors={errors}
                   disabled={disabled}
                   rules={{
                       required: {
                           value: true,
                           message: 'Please provide a valid city'
                       },
                       pattern: {
                           value: /^[ A-Za-z-.]*$/,
                           message: 'Invalid characters'
                       }
                   }}/>
        </>
    ));

const CityFormField: React.FC<InputModel> = ({ disabled = false }) => {
  const methods = useFormContext();
  return <Child {...methods} disabled={disabled} />;
};

export default CityFormField;
