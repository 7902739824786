import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import CreditCardConfirmationWrapper from './CreditCardConfirmationWrapper';
import { loadStripe } from '@stripe/stripe-js';
import Footer from '../../components/Footer/Footer';
import NavWebHeader from '../../components/NavWebHeader/NavWebHeader';
import './CreditCardConfirmation.scss';
import VerifyingSpinner from '../../components/VerifyingSpinner/VerifyingSpinner';
import useCreditCardConfirmation from './useCreditCardConfirmation';
import { updateJourney } from '../../services/journeyService';
import { PageKey } from '../../models/PageKey';
import { useNavigate } from 'react-router-dom';

const stripePromise = loadStripe(`${window.__RUNTIME_CONFIG__.REACT_APP_STRIPE_PK}`);

const CreditCardConfirmation = () => {
  const navigate = useNavigate();
  const { elementOptions, journey } = useCreditCardConfirmation();
  const navigateBack = async() => {
    try {
      const journeyResponse = await updateJourney({
        pageKey: PageKey.CREDIT_INFO,
      });
      if (journeyResponse?.status !== 200) {
        console.error('Error updating journey: ', journeyResponse?.status);
        return;
      }
      navigate("/additional-profile"); 
    } catch (e) {
      navigate('/error');
    }
  };
  return (
    <>
      <div className="root-container">
        <NavWebHeader title="Reserve" shouldNav={true} handleBackNavigation={navigateBack}/>
        {elementOptions ? (
          <div className="container" role="main">
            <div className="stripe-element" data-testid={'stripeElement'}>
              <Elements stripe={stripePromise} options={elementOptions}>
                <CreditCardConfirmationWrapper clientSecret={elementOptions.clientSecret} journey={journey} />
              </Elements>
            </div>
          </div>
        ) : (
          <VerifyingSpinner />
        )}
        <Footer />
      </div>
    </>
  );
};

export default CreditCardConfirmation;
