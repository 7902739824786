import React from 'react';
import { Dialog } from 'primereact/dialog';
import classes from './PersonalInfoDeleteModal.module.scss';

interface PersonalInfoDeleteModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const PersonalInfoDeleteModal: React.FC<PersonalInfoDeleteModalProps> = ({ isOpen, onClose }) => {
  return (
    <Dialog visible={isOpen} className={classes.dialog} onHide={onClose} closeOnEscape blockScroll draggable={false}>
      <div data-testid="modal-container" className={classes.container}>
        <p className={classes.header}>We’re sorry to see you go!</p>
        <section className={classes.text}>
          <p>
            To delete your account, give us a call at <br />
            <strong>1-800-805-5462</strong>.
          </p>
          <p>We hope to see you again soon!</p>
        </section>
        <p className={classes.trs}>
          Telecommunications Relay Service (TRS)
          <br />
          Dial 711
        </p>
      </div>
    </Dialog>
  );
};

export default PersonalInfoDeleteModal;
