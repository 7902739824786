import { Journey } from '../../../models/Journey';

export const checkResponseStatus = (status: number) => status === 200;
export const checkPageKey = (data: Journey, expectedPageKey: string) => data?.pageKey === expectedPageKey;
export const checkPreconditions = (data: Journey, preconditionKeys: string[]) => {
  if (!data?.metadata) {
    console.error('No metadata found in journey response');
    return false;
  }
  return preconditionKeys.every(
    (key) => Object.prototype.hasOwnProperty.call(data?.metadata, key) && data?.metadata[key]
  );
};
