import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { InputWithTooltip } from '../../FormComponents';
import classes from './SignUpFormFields.module.scss';
import { InputModel } from '../../models';

const Child: React.FC<any> = memo(({ register, formState: { errors }, disabled }) => (
  <>
    <InputWithTooltip
      register={register}
      name="homeZipCode"
      title="Home Zip Code"
      placeholder="Zip Code"
      errors={errors}
      toolTipTitle="Zip Code"
      toolTipText="This should be your home zip code where you intend to park your vehicle."
      data-testid="home-zip-code-input"
      labelClassName={classes.input_label}
      lightColor={true}
      disabled={disabled}
      rules={{
        required: {
          value: true,
          message: 'Home zip code is required',
        },
        pattern: {
          value: /^\d{5}$/gm,
          message: 'Provided Zip Code must be 5 digits',
        },
      }}
    />
  </>
));

const HomeZipCodeFormField: React.FC<InputModel> = ({ disabled = false }) => {
  const methods = useFormContext();
  return <Child {...methods} disabled={disabled} />;
};
export default HomeZipCodeFormField;
