import React from "react";
import classes from "./AccordingBody.module.scss";

const SubHeader = () => {
    return (
        <>
            <thead className={classes.thead}>
            <tr>
                <th>Ford Mobile Charger</th>
                <th>Ford Connected Charging Station</th>
            </tr>
            </thead>
        </>
    );
};

export default SubHeader;
