import React, {useEffect} from 'react';
import classes from './CreditCardDetails.module.scss';
import {useNavigate} from 'react-router-dom';
import {getCardDetails} from '../../services/customerService';
import {CardResponse} from '../../models/CardResponse';

const CreditCardDetails: React.FC = () => {
  const [cardDetails, setCardDetails] = React.useState<CardResponse>();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const cardResponse = await getCardDetails();
        setCardDetails(cardResponse.data);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const paymentImageUrl = (brand: string) => `/assets/images/cards/${brand}.png`;
  const cardExpYearFormat = (cardYear: number) => cardYear?.toString().slice(-2);

  return (
    <section
      data-testid="credit-card-details"
      className={classes.container}
      onClick={() => navigate('/payment-information')}
    >
      <div className={classes.text}>
        <div className={classes.row}>
          <div>
            <img src={paymentImageUrl(cardDetails?.brand)} alt="unknown" />
          </div>
          <div>
            <span>{cardDetails?.cardHolder}</span>
          </div>
        </div>

        <div className={`${classes.row} ${classes.card_info}`}>
          <div>
            <span>xxxx {cardDetails?.lastFour}</span>
          </div>
          <div>
            <span>
              Exp {cardDetails?.expMonth}/{cardExpYearFormat(cardDetails?.expYear)}
            </span>
          </div>
        </div>
      </div>
      <img
        className={classes.caret}
        src={'/assets/images/CaretRight.svg'}
        alt="Navigation caret for payment-information page"
      />
    </section>
  );
};

export default CreditCardDetails;
