import React from 'react';
import classes from './Card.module.scss';
import { Faq } from '../../../models/Faq';
import parse from 'html-react-parser';

const Card = ({ faq }: { faq: Faq }) => {
  function parseHtml(input: string) {
    if (!input || typeof input !== 'string') return input;
    input = replaceLinks(input);
    return parse(input);
  }

  function replaceLinks(input: string) {
    if (!input || typeof input !== 'string') return input;
    return input.replace(/\[LINK ([^|]+)\|([^\]]+)]/g, `<a class=${classes.link} href="$2" target="_blank">$1</a>`);
  }

  return (
    <div className={classes.card}>
      <div className={classes.question}>{parseHtml(faq.question)}</div>
      <div className={classes['answer-wrapper']}>
        <div className={classes.answer}>{parseHtml(faq.answer)}</div>
        {faq.subscripts?.map((sub, idx) => (
          <div key={sub}>
            <br />
            <p className={`${classes['sub-section']}`}>
              <sup className={classes.subScript}>{idx + 1}</sup>
              {parseHtml(sub)}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Card;
