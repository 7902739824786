import React, {useEffect, useState} from "react";
import classes from "./PastInvoiceTable.module.scss";
import InvoiceFilterModal from "../Modal/InvoiceFilterModal/InvoiceFilterModal";
import {Past} from "../../models/InvoicesResponse";
import {isInvoiceDownloadable,downloadInvoices} from "../../services/downloadInvoices";

interface Props {
  pastInvoices: Map<string, Past[]>;
}

interface Page {
  displayValue: number; // 1, 2, 3, ..
  pageData: string; // Jul-22, Aug-22,...
}

const PastInvoiceTable = ({ pastInvoices }: Props) => {
  const [currentPage, setCurrentPage] = useState<Page>();
  const [pages, setPages] = useState<Page[]>([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [filter, setFilter] = React.useState(Object.keys(pastInvoices));
  const navToPage = (currentPage, change) => {
    const maxPages = pages.length;
    let nextIndex;

    if (change === -1) {
      // previous page
      nextIndex = Math.max(1, currentPage.displayValue - 1);
    } else if (change === 1) {
      // next page
      nextIndex = Math.min(maxPages, currentPage.displayValue + 1);
    }
    setCurrentPage(pages.filter((value) => value.displayValue == nextIndex)[0]);
  };

  useEffect(() => {
    const _invoiceKeys = Object.keys(pastInvoices);
    const invoiceKeys = _invoiceKeys.filter((key) => filter.includes(key));
    const _pages: Page[] = invoiceKeys.map((value, index) => {
      return {
        displayValue: index + 1,
        pageData: value,
      };
    });
    setPages(_pages);
    setCurrentPage(_pages[0]);
  }, [pastInvoices, filter]);

  const paginationContent = (pages, currentPage) => {
    let _paginationPages = pages;
    const totalPages = pages.length;
    if (totalPages > 3) {
      const startIndex = Math.max(0, Math.min(currentPage.displayValue - 2, totalPages - 3));
      const endIndex = Math.min(totalPages, Math.max(currentPage.displayValue + 1, 3));
      _paginationPages = pages.slice(startIndex, endIndex);
    }

    return _paginationPages.map((page, index) => (
      <button
        key={page.pageData}
        attr-month={page.pageData}
        className={`${classes.page} ${page.pageData === currentPage.pageData ? classes.active : ''}`}
        onClick={() => {
          setCurrentPage(page);
        }}
      >
        {index == 0 && page.displayValue > 1 ? <span>...&nbsp;</span> : ''}
        {page.displayValue}
        {index == 2 && page.displayValue < totalPages ? <span>&nbsp;...</span> : ''}
      </button>
    ));
  };

  return (
    <section className="content l-section">
      <div className={classes.past_bill_title_area}>
        <div className={classes.past_bill_title}>View your past bills</div>
        <div className={classes.past_bill_filter}>
          <button type="submit" className={classes.filter_button} onClick={() => setIsModalOpen(true)}>
            <img src="/assets/images/Filter.svg" alt="Filter" />
            Filter
          </button>
        </div>
      </div>
      <div className={classes.past_bill_table}>
        <table>
          <thead>
            <tr>
              <th>Invoice Period</th>
              <th>Amount</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentPage?.pageData &&
              pastInvoices &&
              pastInvoices[currentPage?.pageData] &&
              pastInvoices[currentPage.pageData].map((invoice) => (
                <tr key={invoice.id}>
                  <td>{invoice.displayDate}</td>
                  <td>$ {(invoice.amount / 100).toFixed(2)}</td>
                  <td>
                    { isInvoiceDownloadable(invoice) &&
                    <a href={invoice.invoicePdf} download>
                      Download
                    </a>
                    }
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        { pastInvoices[currentPage?.pageData]?.filter(item => isInvoiceDownloadable(item)).length != 0 &&
        <div className={classes.past_bill_download_all}>
          <img src="/assets/images/Download-15x15.svg" alt="Download" />
          <button
            className={classes.past_bill_download_all_text}
            onClick={() => downloadInvoices(filter)}
            data-testid={'download-button'}>
             Download ({pastInvoices[currentPage?.pageData]?.filter(item => isInvoiceDownloadable(item)).length})
          </button>
        </div>
        }
      </div>

      <div className={classes.past_bill_pagination}>
        <button
          className={classes.arrow_left}
          disabled={currentPage?.displayValue === 1}
          onClick={() => {
            navToPage(currentPage, -1);
          }}
        />
        {paginationContent(pages, currentPage)}
        <button
          className={classes.arrow_right}
          disabled={currentPage?.displayValue === pages.length}
          onClick={() => {
            navToPage(currentPage, 1);
          }}
        />
      </div>
      <InvoiceFilterModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        filterPeriod={setFilter}
        yearAndMonths={Object.keys(pastInvoices)}
      />
    </section>
  );
};

export default PastInvoiceTable;
