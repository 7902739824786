import classes from './PackageSelection.module.css';
import image from './images/machE.png';

export default function MachEBanner() {
    return (
        <div className={classes["vehicle-wrapper"]}>
            <h4 className={classes["machE-banner-header"]}>Mustang Mach-E Select</h4>
            <div className={classes["white-mache-wrapper"]}>
                <div className={classes["mache-background"]}></div>
                <img src={image} alt="pattern"
                     className={classes["white-mache"]} />
            </div>
        </div>
    );
}
