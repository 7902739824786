import React, { useEffect, useState } from 'react';
import { CardResponse } from '../../models/CardResponse';
import { useNavigate } from 'react-router-dom';
import { trackPaymentMethodsView } from '../../services/analyticsService';
import { getAllCards } from '../../services/customerService';
import { HTTP_STATUS } from '../../models/HttpStatus';
import { ErrorType } from '../ErrorViewer/ErrorViewer';
import classes from './PaymentInformation.module.scss';
import CreditNavCard from '../../components/CreditNavCard/CreditNavCard';
import NavCard from '../../components/NavCard/NavCard';
import Footer from '../../components/Footer/Footer';
import NavWebHeader from '../../components/NavWebHeader/NavWebHeader';

const PaymentInformation = () => {
  const [cards, setCards] = useState<CardResponse[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      trackPaymentMethodsView();
      const response = await getAllCards();
      if (response.status === HTTP_STATUS.OK) {
        response.data && setCards(response.data);
      } else {
        navigate('/error/' + ErrorType.WAITLIST);
      }
    })();
  }, []);

  const handleNav = (id: string) => {
    const card = cards.find((card) => card.id === id);
    const state = { ...card, isOnlyCard: cards.length === 1 };
    navigate('/edit-payment-method', { state });
  };

  return (
    <>
      <NavWebHeader title="Payment Information" />
      <section className={classes.container} role="main">
        <h4 className={classes.saved}>Your Saved Cards</h4>
        <div className={classes['credit-card-wrapper']} data-testid="rendered-credit-cards">
          {cards.length ?
            cards.map((card) => (
              <CreditNavCard
                key={card.id}
                primary={card.primary}
                nickname={card.cardName}
                label={card.lastFour}
                handleNav={() => handleNav(card.id)}
              />
            ))
          : null}
        </div>
        <h4 className={classes['add-payment-method']}>Add Payment Method</h4>
        <NavCard label="Add Payment Card" navTo="/add-payment-method" />
      </section>
      <Footer />
    </>
  );
};

export default PaymentInformation;
