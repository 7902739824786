import React, { useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import modalClasses from '../Modal.module.scss';
import classes from './CongratsModal.module.scss';
import { trackCongratsModalLoad } from '../../../services/analyticsService';

const CongratsModal = ({ isOpen, onClose }) => {
  useEffect(() => {
    (async () => {
      trackCongratsModalLoad();
    })();
  }, []);
  return (
    <Dialog
      className={classes.dialog}
      visible={isOpen}
      onHide={() => onClose()}
      closeOnEscape
      blockScroll
      closable={true}
      dismissableMask={true}
      header={
        <div className={classes.headerBg}>
          <div className={classes.left}></div>
          <div className={classes.right}></div>
        </div>
      }
      draggable={false}
    >
      <div data-testid="congrats-modal-container" className={`${modalClasses.container} ${classes.container}`}>
        <section>
          <h3 className={`${modalClasses.header} ${classes.header}`}>Congrats!</h3>
          <p className={classes.subheader}>You're Approved.</p>
          <div className={classes.imgWrapper}>
            <img src={'/assets/images/mach-e.png'} alt="congrats mach-e image" height={110} />
          </div>
        </section>
        <section className={classes.content}>
          <p className={classes.subheader}>
            Customer care will call you to start scheduling your pickup. Operating hours are 9am-5pm PT, Mon-Fri.
          </p>

          <section className={classes.text}>To speed up the process:</section>

          <table data-testid="table-container" className={`${modalClasses.container} ${classes.container2}`}>
            <tbody>
              <tr>
                <td>01</td>
                <td>Add a payment card now. You won’t be charged til pickup.</td>
              </tr>
              <tr>
                <td>02</td>
                <td>Create a password to check your status and access our mobile app.</td>
              </tr>
            </tbody>
          </table>
        </section>

        <div className={classes.cta_container}>
          <button onClick={() => onClose()} className={classes.cta} autoFocus>
            Let's Go!
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default CongratsModal;
