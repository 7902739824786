import React from 'react';
import classes from './Support.module.scss';
import ContactUsModal from '../../Modal/ContactUsModal/ContactUsModal';
import { useNavigate } from 'react-router-dom';
import InsuranceSupportModal from '../../Modal/InsuranceSupportModal/InsuranceSupportModal';

const Support = ({ shouldShowChargeGuide = true }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isInsuranceModelOpen, setIsInsuranceModelOpen] = React.useState(false);
  const navigate = useNavigate();

  return (
    <>
      {shouldShowChargeGuide && (
        <div className={classes.supportWrapper}>
          <div className={classes.container}>
            <h4 className={classes.support}>Support</h4>
            <div className={classes.charging}>
              <p className={classes.chargingPanel}>Charging Guide</p>
              <em
                className={classes['back-arrow']}
                data-testid="back-arrow"
                onClick={() => navigate('/support/charging-guide')}
              />
            </div>
          </div>
        </div>
      )}

      <ContactUsModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <InsuranceSupportModal isOpen={isInsuranceModelOpen} onClose={() => setIsInsuranceModelOpen(false)} />
    </>
  );
};

export default Support;
