import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react';
import {
  trackCreditCardAddedOnYouAreApprovedPage,
  trackCreditCardAddedSuccessfullyDuringSignup,
  trackCreditCardAddedSuccessfullyOnDashboard,
  trackStripePaymentErrorReceived,
} from '../services/analyticsService';
import { blockCards, handlePaymentMethod } from '../pages/CreditCardConfirmation/utils';
import { setPrimaryCard } from '../services/customerService';
import { ExpandContext } from '../components/Dropdown';

export function usePaymentForm(
  clientSecret: string,
  setIsCardSubmitted: React.Dispatch<React.SetStateAction<boolean>>,
  calledFrom: string
) {
  const [status, setStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');
  const [paymentMethod, setPaymentMethod] = useState<any>('');
  const [zipCode, setZipCode] = useState('');
  const [zipCodeIsValid, setZipCodeIsValid] = useState(true);
  const setIsOpen = useContext(ExpandContext);
  const [processing, setProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (elements) {
      setIsOpen(true);
    }
  }, [elements]);

  function handleZipCodeChange(e: ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    const isNumeric = /^\d+$/.test(value);

    if ((value.length <= 5 && isNumeric) || value === '') {
      setZipCode(value);
    }

    if (value.length === 5) {
      setZipCodeIsValid(true);
    }
  }

  function handleZipCodeBlur() {
    const isValid = zipCode.length === 5 && /^\d+$/.test(zipCode);
    setZipCodeIsValid(isValid);
  }

  useEffect(() => {
    (async () => {
      paymentMethod.length > 0 && (await setPrimaryCard(handlePaymentMethod(paymentMethod)));
    })();
  }, [paymentMethod]);

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    setStatus('loading');
    setProcessing(true);
    setErrorMessage('');

    event.preventDefault(); // prevent page from reloading
    event.stopPropagation(); // prevent dropdown from closing

    if (!stripe || !elements) {
      setStatus('error');
      setErrorMessage('Stripe or elements not found.');
      trackStripePaymentErrorReceived({ stripe_error: 'Stripe or elements not found.' });
      setProcessing(false);
      return;
    }

    // get card number element
    const cardNumberElement = elements.getElement(CardNumberElement);
    if (!cardNumberElement) {
      setStatus('error');
      setErrorMessage('Card element not found.');
      trackStripePaymentErrorReceived({ stripe_error: 'Card element not found.' });
      setProcessing(false);
      return;
    }

    // create token to block prepaid cards
    const tokenResult = await stripe.createToken(cardNumberElement);
    if (tokenResult.error) {
      setStatus('error');
      setErrorMessage(tokenResult.error.message);
      trackStripePaymentErrorReceived({ stripe_error: `Token result error: ${tokenResult.error.message}.` });
      setProcessing(false);
      return;
    }

    // block prepaid cards
    if (blockCards(tokenResult)) {
      setStatus('error');
      setErrorMessage('Invalid card number. Please enter a valid payment card number. Prepaid cards are not accepted.');
      trackStripePaymentErrorReceived({ stripe_error: 'Prepaid card entered' });
      setProcessing(false);
      return;
    }

    if (paymentMethod.length > 0) {
      setSuccess(true);
      return;
    }

    const setupIntentResponse = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardNumberElement,
        billing_details: {
          address: { postal_code: zipCode },
        },
      },
    });

    if (setupIntentResponse.error) {
      setStatus('error');
      setErrorMessage(setupIntentResponse.error.message);
      trackStripePaymentErrorReceived({ stripe_error: `Setup intent error: ${setupIntentResponse.error.message}` });
      setProcessing(false);
      return;
    }

    setPaymentMethod(setupIntentResponse.setupIntent.payment_method);
    trackCreditCardAddedOnYouAreApprovedPage();
    setIsOpen(false);
    setIsCardSubmitted(true);
    setProcessing(false);
    setSuccess(true);
    if (calledFrom == 'Dashboard') {
      trackCreditCardAddedSuccessfullyOnDashboard();
    } else {
      trackCreditCardAddedSuccessfullyDuringSignup();
    }
  }

  return {
    success,
    processing,
    errorMessage,
    status,
    zipCode,
    zipCodeIsValid,
    handleZipCodeChange,
    handleZipCodeBlur,
    handleSubmit,
  };
}
