import React, { useState } from "react";
import classes from "./Tooltip.module.scss";

const Tooltip = ({ title, text, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const handleTooltip = () => {
    setShowTooltip((prevShowTooltip) => !prevShowTooltip);
  };

  return (
    <>
      {showTooltip && (
        <div className={classes.tooltip}>
          <div className={classes.title_area}>
            <p className={classes.zip_code}>{title}</p>
            <img
              data-testid="close-button"
              onClick={handleTooltip}
              alt="Close Icon"
              src={process.env.PUBLIC_URL + "/assets/images/CloseIcon.svg"}
            ></img>
          </div>
          <p className={classes.text}>{text}</p>
        </div>
      )}
      <div onClick={() => setShowTooltip(true)} data-testid="child-component-div">{children}</div>
    </>
  );
};

export default Tooltip;
