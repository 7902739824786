import React, { ChangeEvent, useEffect, useState } from 'react';
import Input from '../components/Input/Input';
import Footer from '../components/Footer/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import { CardResponse } from '../models/CardResponse';
import { detachCard, setPrimaryCard, updateCard } from '../services/customerService';
import classes from './PaymentMethod.module.scss';
import { SuccessType } from './Success';
import NavWebHeader from '../components/NavWebHeader/NavWebHeader';

const DEFAULT_CARD_VALUE = {
  id: '',
  brand: '',
  cardHolder: '',
  expMonth: -1,
  expYear: -1,
  lastFour: '',
  primary: false,
  cardName: '',
  isOnlyCard: false,
};

const EditPaymentMethod = () => {
  const [processingUpdate, setProcessingUpdate] = useState(false);
  const [processingDelete, setProcessingDelete] = useState(false);
  const [cardName, setCardName] = useState('');
  const [isPrimaryCard, setIsPrimaryCard] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const card = state ? (state as CardResponse & { isOnlyCard: boolean }) : DEFAULT_CARD_VALUE;

  useEffect(() => {
    if (card.cardName) {
      setCardName(card.cardName);
    }
    if (card.primary) {
      setIsPrimaryCard(true);
    }
  }, [card]);

  const handleUpdateClick = async (id: string) => {
    setProcessingUpdate(true);
    const setUpdateCardResp = await updateCard({ paymentMethodId: id, cardName });

    // set the primary card if it was not originally the primary card and has the primary card checkbox checked
    const shouldSetAsPrimaryCard = !card.primary && isPrimaryCard;
    const setPrimaryCardResp = shouldSetAsPrimaryCard && (await setPrimaryCard(id));

    const isErrorFromEitherAsyncResp =
      (setUpdateCardResp && setUpdateCardResp.status !== 200) ||
      (setPrimaryCardResp && setPrimaryCardResp.status !== 200);

    if (isErrorFromEitherAsyncResp) {
      setProcessingUpdate(false);
    } else {
      navigate(`/success/${SuccessType.UPDATE}`);
    }
  };

  const handleRemoveClick = async (id: string) => {
    setProcessingDelete(true);
    const resp = await detachCard(id);
    setProcessingDelete(false);
    resp.status === 204 && navigate(`/success/${SuccessType.DELETE}`);
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCardName(e.target.value);
  };

  const renderSpinnerWhenProcessingDelete =
    processingDelete ?
      <img className="spinner button-spinner" src={'/assets/images/spinner-white.svg'} alt="processing" />
    : 'Remove Payment Method';

  const renderSpinnerWhenProcessingUpdate =
    processingUpdate ?
      <img className="spinner button-spinner" src={'/assets/images/spinner-white.svg'} alt="processing" />
    : 'Update Payment Method';

  const shoulDisplayRemoveButton = !(card.primary || card.isOnlyCard);

  return (
    <>
      <NavWebHeader title="Edit Payment Method" />
      <section className="l-section l-abs-center" role="main">
        <Input id="cardName" label="Card Name" value={cardName} handleOnChange={handleOnChange} />
        <label className={classes.checkbox_label}>
          <input
            type="checkbox"
            checked={isPrimaryCard}
            onChange={() => setIsPrimaryCard(!isPrimaryCard)}
            disabled={card.primary}
            data-testid="primary-card-checkbox"
          />
          Primary Payment Method
        </label>
        <button
          className="button--primary l-abs-center"
          disabled={!cardName?.length}
          onClick={() => handleUpdateClick(card.id)}
        >
          {renderSpinnerWhenProcessingUpdate}{' '}
        </button>
        {shoulDisplayRemoveButton ?
          <button className="button--danger l-margin-top-16 l-abs-center" onClick={() => handleRemoveClick(card.id)}>
            {renderSpinnerWhenProcessingDelete}
          </button>
        : null}
      </section>
      <Footer />
    </>
  );
};

export default EditPaymentMethod;
