import React from "react";
import classes from "../TermsAndConditions.module.scss";

const DepictionOfVehicles = () => {
  return (
    <>
      <div className={classes.tc_heading}>
        <p>Depiction of Vehicles</p>
      </div>
      <div className={classes.tc_area}>
        <div className={classes.tc_conditions}>
          <div className={classes.tc_condition}>
            Vehicles shown on this Site are for general illustration only.
            Vehicles shown are typically a sample vehicle only of the same model
            year. Details of a selected vehicle may vary from the vehicle shown
            depending on the features you choose. Vehicle availability may be
            limited.
          </div>
        </div>
      </div>
    </>
  );
};

export default DepictionOfVehicles;