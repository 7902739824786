
import React from "react";
import classes from "../TermsAndConditions.module.scss";

const Miscellaneous = () => {
  return (
    <>
      <div className={classes.tc_heading}>
        <p>Miscellaneous</p>
      </div>
      <div className={classes.tc_area}>
        <div className={classes.tc_conditions}>
          <div className={classes.tc_condition}>
            These Terms and Conditions together with any additional terms to
            which you agree when using particular elements of this Site
            constitute the entire agreement regarding the Site and any services,
            and supersede all prior or contemporaneous communications, whether
            electronic, oral or written between you and us with respect to the
            Site or the services provided on the Site. The Site is operated from
            Ford's offices in the United States of America. These terms and
            conditions are governed by, and construed in accordance with, the
            laws of the State of Michigan, regardless of principles of conflicts
            of laws that may require the application of the laws of another
            jurisdiction. If a court determines that any term or condition in
            these Terms and Conditions is illegal or unenforceable, then such
            term will be eliminated and the remaining terms and conditions will
            remain in force and effect. Ford's failure to exercise or enforce
            any right or provision of these Terms and Conditions shall not
            constitute a waiver of such right or provision. If any provision of
            these Terms and Conditions is found by a court of competent
            jurisdiction to be invalid, the parties nevertheless agree that the
            court should endeavor to give effect to the parties' intentions as
            reflected in the provision, and the other provisions of these Terms
            and Conditions remain in full force and effect. All lawsuits arising
            from or relating to these Terms and Conditions shall be brought in
            the Federal or State courts located in Wayne County, Michigan, and
            you hereby irrevocably submit to the exclusive personal jurisdiction
            of such courts for such purpose.
          </div>
          <div className={classes.tc_condition}>
            Effective Date: March 3, 2011
          </div>
        </div>
      </div>
    </>
  );
};

export default Miscellaneous;