import classes from './ChoosePackage.module.scss';
import lightningBolt from './images/lightning.svg';
import useChoosePackage from './useChoosePackage';
import Footer from '../../components/Footer/Footer';
import VerifyingSpinner from '../../components/VerifyingSpinner/VerifyingSpinner';
import InsuranceInfoModal from '../../components/Modal/InsuranceInfoModal/InsuranceInfoModal';
import ProgressBar from '../../components/ProgressBar';
import { hideToasts } from '../../components/CustomToast/CustomToast';
import { useEffect, useState } from 'react';
import { PackageCard } from './PackageCard';
import { sortPricingList, vehicleImage } from '../../ampli/experiments';
import { useHandleBackButton } from '../../hooks/useHandleBackButton';
import { PageURL } from '../../models/PageKey';

export default function ChoosePackage() {
  const [modelOpen, setModelOpen] = useState(false);

  const { pricingDisplayInfoList, updateSelected, showContent } = useChoosePackage();

  useHandleBackButton(PageURL.SIGN_UP);

  useEffect(() => {
    hideToasts('copy');
  }, []);

  return (
    <>
      <div className="l-progress-bar-wrapper">
        <ProgressBar currentStep={2} />
      </div>
      {showContent ?
        <main className="content l-section">
          <h4 className={classes.header}>Select Your Ideal Package</h4>
          <h4 className={classes.sub_header}>Each Package Includes</h4>
          <div className={classes.body}>
            <div className={classes.lightning_img}>
              <img src={lightningBolt} alt="pattern"></img>
            </div>
            <div className={classes.description}>
              <div>Award-Winning EV</div>
              <div>Vehicle Maintenance</div>
              <div>
                Ultimate Flexibility<sup>*</sup>
              </div>
              <div>
                <a className={classes.bundled_insurance} onClick={() => setModelOpen(true)}>
                  Bundled Insurance
                </a>
              </div>
            </div>
            <div className={classes.mache_img}>
              <img src={vehicleImage()} alt="package-selection-mache" />
            </div>
          </div>
          <section className={classes.package_card}>
            <PackageCard
              pricingDisplayInfoWithSelectedList={sortPricingList(pricingDisplayInfoList) || []}
              handleClick={updateSelected}
            />
          </section>
          <div className={classes.driver_info}>
            <sup>*</sup>You can switch your weekly plan anytime
          </div>
        </main>
      : <VerifyingSpinner useBorderRadius={false} />}
      <Footer />
      {modelOpen && <InsuranceInfoModal isOpen={modelOpen} onClose={() => setModelOpen(false)} />}
    </>
  );
}
