import React from 'react';
import Dropdown from '../Dropdown';
import RememberToBringBody from './RememberToBringBody';

const RememberToBring = ({ expanded }) => {
  return (
    <Dropdown title="Remember To Bring" expanded={expanded} data-testid="remember-to-bring">
      <RememberToBringBody />
    </Dropdown>
  );
};
export default RememberToBring;
