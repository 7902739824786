import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { HiddenInput } from '../../FormComponents';
import { InputValueModel } from '../../models';

const Child: React.FC<any> = memo(({ register, disabled, value }) => (
  <HiddenInput
    register={register}
    name="workflowId"
    data-testid="workflowId-code-input"
    disabled={disabled}
    value={value}
  />
));

//This component is used to set the workflowId in the form
const WorkflowIdFormField: React.FC<InputValueModel> = ({ disabled = false, value = '' }) => {
  const methods = useFormContext();
  return <Child {...methods} disabled={disabled} value={value} />;
};
export default WorkflowIdFormField;
