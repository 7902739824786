import React from 'react';
import Condition from './Condition';
import classes from '../TermsAndConditions.module.scss';

const CopyrightNotification = () => {
  const communicationGuidelineDocs = [
    { line: 1, content: 'Your physical or electronic signature.' },
    {
      line: 2,
      content:
        'Identification of the copyrighted work you claim to have been infringed, or, if there are multiple copyrighted works, a representative list of such works.',
    },
    {
      line: 3,
      content:
        'Identification of the material that you claim to be infringing, and information reasonably sufficient to permit us to locate the material.',
    },
    {
      line: 4,
      content:
        'Information reasonably sufficient to permit us to contact you, such as an address, telephone number, and, if available, an email address.',
    },
    {
      line: 5,
      content:
        'A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by you or the law.',
    },
    { line: 6, content: 'A statement that the information your notification is accurate.' },
  ];

  return (
    <>
      <div className={classes.tc_heading}>
        <p>Designated Agent for Copyright Notification</p>
      </div>
      <div className={classes.tc_area}>
        <div className={classes.tc_conditions}>
          <div className={classes.tc_condition}>
            If you believe that any content on this Site infringes your copyright rights, please contact Ford's
            designated agent for receiving such notifications in writing as follows:
            <br />
            DMCA Agent
            <br />
            Ford Motor Company
            <br />
            One American Road, 4th Floor
            <br />
            Dearborn, Michigan 48126
            <br />
            Email address: <b className={classes.tc_bold}>DMCA@ford.com</b>
            <br />
            Phone number: (313) 323-2875
          </div>
          <div className={classes.tc_condition}>In your communication, please include:</div>
          <div className={classes.tc_condition}>
            {communicationGuidelineDocs.map(function (i) {
              return <Condition slNo={i.line} condition={i.content} key={i.line} />;
            })}
          </div>
          <div className={classes.tc_condition}>
            If the communication is submitted by someone on your behalf, the communication must also contain a statement
            that, under penalty of perjury, the submitter is authorized to act on your behalf.
          </div>
        </div>
      </div>
    </>
  );
};

export default CopyrightNotification;
