import {useEffect} from 'react';
import {amplitudeFlush, amplitudeSetTransport} from '../services/analyticsService';

export function usePageDuration(trackFunction, pageAccessible) {
  const leavePage = (pageClosing = false) => {
    let elapsedTime;
    if (document.visibilityState === 'hidden' && pageClosing) {
      elapsedTime = 0;
    } else {
      const startTime = Number(new Date(sessionStorage.getItem('startTime')));
      const endTime = Number(new Date());
      elapsedTime = (endTime - startTime) / 1000;
    }

    const prevTime = Number(sessionStorage.getItem('prevTime'));
    const combinedTime = elapsedTime + prevTime;
    if (pageClosing) {
      trackFunction({time_spent: String(combinedTime)});
      amplitudeSetTransport('beacon');
      amplitudeFlush();
    } else {
      sessionStorage.setItem('prevTime', String(combinedTime));
    }
  };

  const onBeforeUnload = () => {
    leavePage(true);
  };

  const onVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      leavePage();
    } else {
      sessionStorage.setItem('startTime', String(new Date()));
    }
  };

  useEffect(() => {
    if (!pageAccessible) return;
    sessionStorage.setItem('startTime', String(new Date()));
    sessionStorage.setItem('prevTime', String(0));
    window.addEventListener('beforeunload', onBeforeUnload);
    window.addEventListener('visibilitychange', onVisibilityChange);
    return () => {
      leavePage(true);
      window.removeEventListener('beforeunload', onBeforeUnload);
      window.removeEventListener('visibilitychange', onVisibilityChange);
      amplitudeSetTransport('http');
    };
  }, [pageAccessible]);
}
