import { ComponentPropsWithoutRef, ReactNode, useEffect, useRef } from 'react';
import styles from './Dropdownless.module.scss';

interface DropdownlessProps extends ComponentPropsWithoutRef<'div'> {
  children: ReactNode;
  title: string;
}

function Dropdownless({ children, title, ...props }: DropdownlessProps) {
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.height = `${contentRef.current.scrollHeight}px`;
    }
  }, []);

  return (
    <div {...props} className={styles.dropdownless} data-testid="dropdownless">
      <div className={styles.dropdownlessTitle}>{title}</div>
      <div className={styles.dropdownlessContent} ref={contentRef}>
        {children}
      </div>
    </div>
  );
}

export default Dropdownless;
