import React from 'react';
import classes from './InvoiceDetails.module.scss';
import { useNavigate } from 'react-router-dom';

import CreditCardDetails from '../CreditCardDetails/CreditCardDetails';
import { CaretLeftIcon, CaretRightIcon } from '../Icons';
import { formatDateFromMillis } from '../../utils/dateUtil';
import { Invoice } from '../../models/InvoicesResponse';
import Support from '../PageParts/Support/Support';
import { isInvoiceDownloadable } from '../../services/downloadInvoices';
import NavWebHeader from '../NavWebHeader/NavWebHeader';

interface Props {
  invoice: Invoice;
  nextInvoiceId: string;
  prevInvoiceId: string;
}

const overageProgress = (invoice: Invoice) => {
  return (
    <>
      {invoice.overageMiles > 0 ?
        <progress max={1500} value={invoice.overageMiles} />
      : <div className={classes.empty_progress}></div>}
    </>
  );
};

const InvoiceDetails = ({ invoice, nextInvoiceId, prevInvoiceId }: Props) => {
  const navigate = useNavigate();

  const dateFormat: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  };

  const simpleDateFormat: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
  };

  const currentDate = new Date().getTime();

  return (
    <>
      <NavWebHeader title="Invoice Details" />

      <section className={`content l-section ${classes.invoice_details_wrapper}`}>
        <div className={`l-margin-bottom-16 ${classes.header}`}>
          <p className="text-center">
            Invoice **** - {invoice?.invoiceNumber && invoice?.invoiceNumber.split('-')[1]}
            {isInvoiceDownloadable(invoice) && (
              <a href={invoice?.invoicePdf} download>
                <img src="/assets/images/Download-24x22.svg" alt="Download" />
              </a>
            )}
          </p>
        </div>

        <div className={`l-margin-top-16 text-center ${classes.billing_period}`}>
          <button
            data-testid="prev-invoice"
            disabled={!prevInvoiceId}
            onClick={() => navigate('/invoices/' + prevInvoiceId)}
          >
            <CaretLeftIcon />
          </button>
          <div>
            <p className={classes.title}>Billing Period</p>
            <p className={classes.duration}>
              {' '}
              {formatDateFromMillis(invoice?.weeklySubscriptionStartDate, dateFormat)} -{' '}
              {formatDateFromMillis(invoice?.weeklySubscriptionEndDate, dateFormat)}{' '}
            </p>
          </div>
          <button
            data-testid="next-invoice"
            disabled={!nextInvoiceId}
            onClick={() => navigate('/invoices/' + nextInvoiceId)}
          >
            <CaretRightIcon />
          </button>
        </div>

        <div className={`l-margin-top-16 ${classes.lease_plan}`}>
          <p className={classes.package}>
            {`${invoice?.baseMileageLimit < 99999 ? '' : ''} Weekly Subscription`}
            <span className={classes.price}>{`$${(invoice.weeklyAmount / 100).toFixed(2)}`}</span>
          </p>
          <p className={`${classes.price} ${classes.mileage}`}>
            {`${invoice?.baseMileageLimit < 99999 ? '' : ''} (${formatDateFromMillis(
              invoice?.weeklySubscriptionStartDate,
              simpleDateFormat
            )} - ${formatDateFromMillis(invoice?.weeklySubscriptionEndDate, simpleDateFormat)})`}
          </p>
        </div>
        <hr className={classes.hr} />
        {invoice?.baseMileageLimit < 99999 && (
          <div>
            <div className={`l-margin-bottom-16 ${classes.miles}`}>
              <p className={classes.title}>Miles Covered</p>
              <div>
                <p className={classes.previous}>{`Based on previous week’s plan (${invoice.displayDate})`}</p>
                <progress
                  value={invoice?.totalMilesDriven - invoice?.overageMiles}
                  max={invoice?.baseMileageLimit}
                  title={invoice?.totalMilesDriven - invoice?.overageMiles + ' miles'}
                />
              </div>
              <div className={classes.progress_info}>
                <span className={classes.mileage}>
                  {invoice?.totalMilesDriven - invoice?.overageMiles} / {invoice?.baseMileageLimit} mi
                </span>
                <span className={classes.mileage_included}>Included</span>
              </div>
            </div>
            <div className={`l-margin-bottom-16 ${classes.additional_miles}`}>
              <p className={classes.title}>Additional Details</p>
              <p className={classes.additional}>{`Calculated from previous week (${invoice.displayDate})`}</p>
              <div>{overageProgress(invoice)}</div>
              <div className={classes.progress_info}>
                <span className={classes.mileage}>
                  <strong>{invoice.overageMiles}</strong> miles @ $ 0.20/mi
                </span>
                <span className={classes.mileage_included}>${(invoice.overageAmount / 100).toFixed(2)}</span>
              </div>
            </div>
            <hr className={classes.hr} />
          </div>
        )}
        <div className={classes.fees}>
          <div>
            <span>Taxes & Fees A</span>
            <span>$ {(invoice.tax / 100).toFixed(2)}</span>
          </div>
          <div>
            <span>Total</span>
            <span className={classes.total_price}>${(invoice.invoiceTotal / 100).toFixed(2)}</span>
          </div>
          <div className={classes.status}>
            {invoice.status === 'open' && invoice.invoiceDueDate >= currentDate && (
              <span className="font-sm">
                <img src="/assets/images/icon-check.svg" alt="check icon" /> Pending
              </span>
            )}
            {invoice.status === 'open' && invoice.invoiceDueDate < currentDate && (
              <span className="font-sm">
                <img src="/assets/images/icon-warn.svg" alt="warn icon" />
                Overdue
              </span>
            )}
          </div>
        </div>

        <div className={`l-margin-bottom-16 ${classes.autopay}`}>
          <p className={classes.title}>Autopay</p>
          <p className={classes.desc}>
            The total amount will be charged to the primary payment card on
            <time> {formatDateFromMillis(invoice.invoiceDueDate, dateFormat)}</time>
          </p>
        </div>

        <div className={classes.credit_card}>
          <CreditCardDetails />
        </div>
      </section>
      <hr className={classes.separator} />
      <div style={{ margin: 'auto 24px' }}>
        <Support shouldShowChargeGuide={true} />
      </div>
    </>
  );
};

export default InvoiceDetails;
