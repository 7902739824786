import * as React from 'react';
import styles from './ProgressStep.module.scss';
import { ProgressStepProps } from './ProgressStep.model';

function ProgressStep({ step, currentStep, isFirst, isLast }: ProgressStepProps) {
  const isActive = step.id < currentStep;
  const isCurrent = step.id === currentStep;

  let fillColor = '#aeb4bf'; // default color (grey for not visited)
  let labelClass = styles.notVisitedLabel;
  if (isActive) {
    fillColor = '#047B41'; // green for active
    labelClass = styles.activeLabel;
  } else if (isCurrent) {
    fillColor = '#00095B'; // blue for current
    labelClass = styles.currentLabel;
  }

  let pathD =
    'M9.00109 1.26987C9.24234 0.988404 9.59454 0.826416 9.96525 0.826416H81.2043C81.4225 0.826416 81.5391 1.08342 81.3955 1.24763L77.3195 5.90591L73.1709 10.8938C73.1226 10.9519 73.0511 10.9854 72.9756 10.9854H1.05762C0.838483 10.9854 0.722215 10.7265 0.867805 10.5627L5.57156 5.27098L9.00109 1.26987Z'; // default path
  if (isFirst) {
    pathD =
      'M9.51237e-06 5.90591C9.51237e-06 3.10058 2.27418 0.826416 5.07951 0.826416H80.7725C81.0513 0.826416 81.1992 1.15584 81.0139 1.36419L80.363 2.09629L77.0475 5.90591L72.6893 10.8864C72.6342 10.9493 72.5547 10.9854 72.4711 10.9854H5.0795C2.27417 10.9854 9.51237e-06 8.71124 9.51237e-06 5.90591Z';
  } else if (isLast) {
    pathD =
      'M81.5281 5.90585C81.5281 8.71118 79.2539 10.9854 76.4486 10.9854L0.755608 10.9853C0.476807 10.9853 0.328903 10.6559 0.51416 10.4476L1.16512 9.71547L4.48055 5.90585L8.83878 0.925337C8.89383 0.862433 8.97335 0.826351 9.05694 0.826351L76.4486 0.826356C79.2539 0.826357 81.5281 3.10052 81.5281 5.90585Z';
  }

  return (
    <div className={`${styles.progressStep}`}>
      <span className={`${styles.stepLabel} ${labelClass} ${!isFirst ? styles.stepLabelMargin : ''}`}>
        {step.label}
      </span>
      <svg width="82" height="11" viewBox="0 0 82 11" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <path d={pathD} fill={fillColor} />
      </svg>
    </div>
  );
}

export default ProgressStep;
