import React from "react";
import classes from "./AccordingBody.module.scss";
import SubHeader from "./SubHeader";

const Voltage = () => {
    return (
        <>
            <table className={classes.table}>
                <SubHeader/>
                <tbody className={classes.tbody}>
                <tr>
                    <td colSpan={2}>
                        <hr className={classes.section_divider}/>
                    </td>
                </tr>
                <tr>
                    <td>120V <strong className={classes.strong}>or</strong> 240V</td>
                    <td>240V</td>
                </tr>
                </tbody>
            </table>
        </>
    );
};

export default Voltage;
