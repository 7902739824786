
import React from "react";
import classes from "../TermsAndConditions.module.scss";

const OtherConditions = () => {
  return (
    <>
      <div className={classes.tc_heading}>
        <p>Other Conditions/Restrictions</p>
      </div>
      <div className={classes.tc_area}>
        <div className={classes.tc_conditions}>
          <div className={classes.tc_condition}>
            CAUTION: ANY ATTEMPT BY ANY INDIVIDUAL TO DELIBERATELY DAMAGE ANY
            WEBSITE, TAMPER WITH THE SUBMISSION PROCESS, OR OTHERWISE UNDERMINE
            FORD’S LEGITIMATE BUSINESS OPERATIONS MAY BE A VIOLATION OF CRIMINAL
            AND CIVIL LAWS AND SHOULD SUCH AN ATTEMPT BE MADE, FORD RESERVES THE
            RIGHT TO COOPERATE IN THE PROSECUTION OF ANY SUCH INDIVIDUAL(S) AND
            TO PURSUE ALL REMEDIES TO THE FULLEST EXTENT PERMITTED BY LAW.
          </div>
          <div className={classes.tc_condition}>
            This Site is intended as a resource and discussion forum for people
            interested in Ford Motor Company vehicles, projects and initiatives.
            Unfortunately, this isn’t the place to address warranty or customer
            service questions or concerns or products sold by Ford Motor
            Company. If you have a question about the Ford Drive service, please
            contact Ford Drive Customer Care at 1 800-805-5462 during normal
            business hours.
          </div>
        </div>
      </div>
    </>
  );
};

export default OtherConditions;