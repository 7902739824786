import React from 'react';
import Condition from './Condition';
import classes from '../TermsAndConditions.module.scss';

const LimitedLicenses = () => {
  const limitedLicensesDoc = [
    {
      line: 1,
      content:
        'you must retain, on all copies of the materials downloaded, all copyright and other proprietary notices contained in the materials;',
    },
    {
      line: 2,
      content:
        'you may not modify the materials in any way or reproduce or publicly display, perform, distribute, or otherwise use them for any public or commercial purpose;',
    },
    {
      line: 3,
      content:
        'you must not transfer the materials to any other person unless you give them notice of, and they agree to accept, the obligations arising under these terms and conditions of use.',
    },
  ];

  return (
    <>
      <div className={classes.tc_heading}>
        <p>Limited Licenses</p>
      </div>
      <div className={classes.tc_area}>
        <div className={classes.tc_conditions}>
          <div className={classes.tc_condition}>
            Copyright 2010, Ford Motor Company. All rights reserved. This Site, including all materials, is protected by
            worldwide copyright laws and treaty provisions whether or not a copyright notice is present on the
            materials. All text, images, graphics, animation, videos, music, sounds and other materials on this Site are
            subject to the copyrights and other intellectual property rights of Ford, its affiliated companies and its
            licensors. You agree to comply with all copyright laws worldwide in your use of this Site and to prevent any
            unauthorized copying of the materials. Except as expressly provided herein, Ford does not grant any express
            or implied right to you under any patents, trademarks or copyrights.
          </div>
          <div className={classes.tc_condition}>
            Subject to your continued compliance with these terms and conditions, Ford grants you a non-exclusive,
            non-transferable, limited right to access, use, display and listen to this Site and the information, images,
            sounds and text ("materials") thereon. You agree not to interrupt or attempt to interrupt the operation of
            the Site in any way. You agree not to dispute Ford's claims of ownership or validity of its rights in the
            materials on this Site.
          </div>
          <div className={classes.tc_condition}>
            Ford authorizes you to view and download the materials at this Site only for your personal, non-commercial
            use. Ford may suspend or terminate this authorization at any time for any reason. This authorization is not
            a transfer of title in the materials and copies of the materials and is subject to the following
            restrictions:
          </div>
          <div className={classes.tc_condition}>
            {limitedLicensesDoc.map(function (i) {
              return <Condition slNo={i.line} condition={i.content} key={i.line} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default LimitedLicenses;
