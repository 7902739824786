import React from 'react';
import classes from './Under25LA.module.scss';
import Footer from '../components/Footer/Footer';

const Under25LA = () => {
  return (
    <>
      <div className={classes['header-background-wrapper']}>
        <img className={classes.wave} src={'/assets/images/HeaderBackground.png'} alt="wave background" />
      </div>
      <header className={classes['nav-header']}>
        <div>
          <a href="https://www.forddrive.com" target="_blank">
            <img src={'/assets/images/forddrive.svg'} alt="Ford Drive Logo" />
          </a>
        </div>
      </header>
      <section className={classes['under-age-text-section'] + ' ' + 'l-section'} role="main">
        <div className="l-abs-center">
          <h4 className={classes.header}>Thanks for your interest!</h4>
          <p className={classes['under-age-text']}>
            This product is not currently offered to drivers under the age of 25 in Los Angeles.
          </p>
          <p className={classes['under-age-text']}>
            We apologize for any inconvenience. Your soft credit check will not be conducted.
          </p>
          <p className={classes['under-age-text']}>
            Please refer to Uber’s minimum driver requirements for more details.
          </p>
          <button
            className="button--primary--next l-justify-self"
            onClick={() => {
              window.location.href = 'https://www.uber.com/us/en/drive/requirements/';
            }}
          >
            Learn More
          </button>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Under25LA;
