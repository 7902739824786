import React from 'react';
import classes from './UberLogo.module.scss';

const UberLogo = () => {
    return (
        <div className={classes.logo}>
            <span>Uber</span>
        </div>
    );
};

export default UberLogo;
