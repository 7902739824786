import Dropdown from '../Dropdown';
import PasswordForm from '../PasswordForm';

function DropdownPasswordForm({ expanded }) {
  return (
    <Dropdown title="Create Password" key={expanded}>
      <PasswordForm expanded={expanded} />
    </Dropdown>
  );
}

export default DropdownPasswordForm;
