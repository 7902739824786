import React from "react";
import {Dialog} from "primereact/dialog";
import modalClasses from "../Modal.module.scss";
import classes from "./InusranceAnnouncementModal.module.scss";

const InsuranceAnnouncementModal = ({ isOpen, onClose, setDoNotShowAgain, insuranceModalAnnouncementProps }) => {
    return (
        <Dialog
            className={modalClasses.dialog}
            visible={isOpen}
            onHide={onClose}
            closeOnEscape
            blockScroll
            draggable={false}
        >
            <div data-testid="insurance-announcement-modal-container" className={modalClasses.container}>
                <p className={`${modalClasses.header} ${modalClasses.contact_us_top}`}>
                    {insuranceModalAnnouncementProps.headerText}
                </p>
                <section className={modalClasses.text}>
                    {insuranceModalAnnouncementProps.sectionText}
                </section>
                        <label className={classes.checkbox_label}>
                            <input
                                type="checkbox"
                                onChange={(e) => setDoNotShowAgain(e.target.checked)}
                            />
                            Do Not Show Me Again
                        </label>
                    <div className={modalClasses.redesign_button_container}>
                        <button className={modalClasses.redesign_button_light_blue} onClick={insuranceModalAnnouncementProps.buttonAction}>
                            {insuranceModalAnnouncementProps.buttonText}
                        </button>
                    </div>
            </div>
        </Dialog>
    );
};

export default InsuranceAnnouncementModal;
