import Button from '../Button';
import Divider from '../Divider';
import Spacer from '../Spacer';

import { useNavigate } from 'react-router-dom';

function ChangePlanNoBundle() {
  const navigate = useNavigate();

  function handleCallClick() {
    window.location.href = 'tel:800-805-5462';
  }

  function handleDismissClick() {
    // go to previous page
    navigate(-1);
  }

  return (
    <>
      <Divider marginTop={'32px'} marginBottom={'32px'} />
      <p className="l-center" style={{ maxWidth: '282px', textAlign: 'center' }}>
        Our pricing plans have increased to include bundled insurance. If you’d like to change your pricing plan or
        opt-in to our insurance, please call us.
      </p>
      <Spacer height={32} />
      <Button variant="primary" style={{ width: '100%' }} onClick={handleCallClick}>
        Call to Update
      </Button>
      <Divider marginTop={'32px'} marginBottom={'0'} />
      <Button variant="borderless" style={{ width: '100%' }} onClick={handleDismissClick}>
        Dismiss
      </Button>
    </>
  );
}

export default ChangePlanNoBundle;
