import toast from 'react-hot-toast';
import classes from './PaymentWrapper.module.scss';
import React from 'react';

export function submitErrorToast() {
  toast(
    (t) => (
      <div
        style={{
          animation: t.visible ? 'custom-enter 0.3s ease-in!important' : 'custom-exit 1s ease-out!important',
        }}
        className={`${classes.toast}`}
      >
        <img className={classes.warning} src={'/assets/images/warning.svg'} alt="" />
        <p>
          We’re unable to process your
          <br />
          request at this time. Please try later.
        </p>
      </div>
    ),
    {
      duration: 3700,
      style: {
        display: 'block',
        background: 'none',
        boxShadow: 'unset',
        lineHeight: 'unset',
        maxWidth: 'unset',
        padding: '0',
      },
    }
  );
}
