import React from "react";
import {Dialog} from "primereact/dialog";
import {ModalProps} from "../ModalUtils";
import classes from "../Modal.module.scss";

const ContactUsModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  return (
    <Dialog
      className={classes.dialog}
      visible={isOpen}
      onHide={onClose}
      closeOnEscape
      blockScroll
      draggable={false}
    >
      <div data-testid="modal-container" className={classes.container}>
        <p className={`${classes.header} ${classes.contact_us_top}`}>
          We’re here for you
        </p>
        <section className={classes.text}>
          <p>
            Have a question about our services?
            <br />
            Browse our{" "}
            <a target='_blank'
               rel='noopener noreferrer' href="https://forddrive.com/faq" className={classes.link_item}>
              FAQs.
            </a>
          </p>
          <p>
            Still have questions?
            <br />
            <strong>Chat with us using the icon below.</strong>
          </p>
          <hr className={classes.contact_line_break}></hr>
          <p className={classes.contact_us_bottom}>
            Text us -{" "}
            <a className={classes.link_item} href="sms:(949)(704-5462)">
              {" "}
              (949) 704-5462{" "}
            </a>
            <br />
            Call us -{" "}
            <a className={classes.link_item} href="tel:800-805-5462">
              {" "}
              (800) 805-5462{" "}
            </a>
          </p>
        </section>
      </div>
    </Dialog>
  );
};

export default ContactUsModal;
