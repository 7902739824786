import React from "react";
import classes from "../TermsAndConditions.module.scss";

const TradeMarkAndPrivacy = () => {
  return (
    <>
      <div className={classes.tc_heading}>
        <p>Trademark Notice</p>
      </div>
      <div className={classes.tc_area}>
        <div className={classes.tc_conditions}>
          <div className={classes.tc_condition}>
            The Ford name, and all trademarks and logos displayed on this Site
            are owned or used under license by Ford. These trademarks include,
            but are not limited to, product brand names (e.g., Ford, Lincoln,
            Motorcraft<sup className={classes.tc_sup}>®</sup>
            ), vehicle model names (e.g., Mustang, Taurus, Explorer, F-150,
            Focus), slogans (e.g.,Built Ford Tough), and logos and emblems. The
            unauthorized use of any trademark displayed on this Site is strictly
            prohibited.
          </div>
        </div>
      </div>
      <div className={classes.tc_heading}>
        <p>Changes to the Site</p>
      </div>
      <div className={classes.tc_area}>
        <div className={classes.tc_conditions}>
          <div className={classes.tc_condition}>
            Ford and its affiliates reserve the right to change, modify,
            suspend, discontinue or permanently cancel this Site's operation or
            portions thereof without any notice to you, including but not
            limited to product specifications or prices, at any time and from
            time to time without notice and without incurring any obligations.
            Your use of this Site after any changes are posted will be
            considered acceptance of such changes.
          </div>
        </div>
      </div>
      <div className={classes.tc_heading}>
        <p>Privacy</p>
      </div>
      <div className={classes.tc_area}>
        <div className={classes.tc_conditions}>
          <div className={classes.tc_condition}>
            For an explanation of Ford's policies and practices regarding
            information that we may collect from you in connection with your use
            of this Site, you should review Ford's
            <a
              href="https://www.ford.com/help/privacy"
              target="_blank"
              className={classes.tc_link}
            >
              <b className={classes.tc_bold}> Privacy Policy </b>
            </a>
            the terms of which are incorporated herein. By using this Site, you
            are agreeing to this Privacy Policy and you will be bound by the
            terms of such Privacy Policy.
          </div>
        </div>
      </div>
    </>
  );
};

export default TradeMarkAndPrivacy;