import React from "react";
import classes from "./AccordingBody.module.scss";
import SubHeader from "./SubHeader";

const TotalCost = () => {
    return (
        <>
            <table className={classes.table}>
                <SubHeader/>
                <tbody className={classes.tbody}>
                <tr>
                    <td colSpan={2}>
                        <hr className={classes.section_divider}/>
                    </td>
                </tr>

                <tr>
                    <td>$0 <strong className={classes.strong}>or</strong> $0 + outlet installation
                        cost (approx. $1,500
                        $2,000).
                    </td>
                    <td>$799 Charger cost + outlet installation cost (approx. $1,500 - $2,000).</td>
                </tr>

                <tr>
                    <td className={classes.top}>Charger comes with vehicle.</td>
                </tr>
                </tbody>
            </table>
        </>
    );
};

export default TotalCost;
