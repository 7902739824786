import React from 'react';
import Condition from './Condition';
import classes from '../TermsAndConditions.module.scss';

const LinkToTheSite = () => {
  const guidelineDocs = [
    {
      line: 1,
      content:
        'do not incorporate any content from this Site into your site (e.g., by in-lining, framing or creating other browser or border environments around the website content). You may only link to, not replicate, the Site content;',
    },
    { line: 2, content: 'you may not use any Ford trademarks, logos, designs or service marks in your links;' },
    { line: 3, content: 'you may not create the appearance of a relationship or affiliation with Ford; and' },
    { line: 4, content: 'your site may not contain offensive, distasteful, illegal or inappropriate content.' },
  ];

  return (
    <>
      <div className={classes.tc_heading}>
        <p>Linking, crawling and archiving</p>
      </div>
      <div className={classes.tc_area}>
        <div className={classes.tc_conditions}>
          <div className={classes.tc_condition}>
            If you would like to link to this Site, you must comply with the following guidelines:
          </div>
          <div className={classes.tc_condition}>
            {guidelineDocs.map(function (i) {
              return <Condition slNo={i.line} condition={i.content} key={i.line} />;
            })}
          </div>
          <div className={classes.tc_condition}>
            If you would like to use a web crawler or other search tool to gather information from the Site, you must
            comply with the provisions of the robots.txt file of the site and you may not circumvent CAPTCHA or other
            security measures.
          </div>
        </div>
      </div>
    </>
  );
};

export default LinkToTheSite;
