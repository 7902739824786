import { useEffect, useState } from 'react';
import { getLatestReservation } from '../../../services/reservationService';

export default function useInsuranceSupportHook() {
  const [collisionContactNumber, setCollisionContactNumber] = useState('800-367-3221');

  useEffect(() => {
    (async () => {
      try {
        const reservationResponse = await getLatestReservation();
        if (!reservationResponse || !reservationResponse.data) {
          return;
        }
        const odometerReading = reservationResponse.data.currentOdometerReading;
        if (odometerReading && odometerReading <= 60000) {
          setCollisionContactNumber('800-241-3673');
        }
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  return {
    collisionContactNumber,
  };
}
