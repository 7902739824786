import React from 'react';
import classes from './InvoiceSnapshot.module.scss';

const InvoiceSnapshot = ({ stripeLatestInvoice }) => {
  const { overageMiles, totalMilesDriven, baseMileageLimit, invoiceTotal, autoPayDate } = stripeLatestInvoice;
  const isInvoiceAvailable = totalMilesDriven !== -1;
  const mileageToDisplay = isInvoiceAvailable ? totalMilesDriven : '---';
  const mileageToDisplayText = isInvoiceAvailable ? `${mileageToDisplay}` : 'Invoice Unavailable';
  const baseMileageToDisplayText = isInvoiceAvailable ? `/ ${baseMileageLimit} miles` : '';
  const totalToDisplayText = isInvoiceAvailable
    ? new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(invoiceTotal * 0.01)
    : '';
  const overageToDisplay = isInvoiceAvailable ? overageMiles : '---';
  const overageToDisplayText = isInvoiceAvailable ? `${overageToDisplay} overage miles` : 'Please check after sometime';
  const autoPayToDisplay = isInvoiceAvailable ? autoPayDate : '--/--';
  const autoPayToDisplayText = isInvoiceAvailable ? `Autopay on ${autoPayToDisplay}` : '';
  const unlimitedMile = 999999;
  const isNotUnlimitedMile = baseMileageLimit < unlimitedMile;

  return (
    <div data-testid="invoiceSnapshotInfoContainer" className={classes.invoiceSnapshotcontainer}>
      <div data-testid="mileageId" className={classes.invoice}>
        <div>
          <span className={`${isInvoiceAvailable ? classes.strong : classes.invoiceNotAvailableHeader}`}>
            {mileageToDisplayText}{' '}
          </span>
          <span className={`${isInvoiceAvailable ? classes.baseMileageLimit : classes.invoiceNotAvailableHeader}`}>
            {isNotUnlimitedMile ? baseMileageToDisplayText : '/ Unlimited miles'}
          </span>
        </div>
      </div>
      {isNotUnlimitedMile && (
        <div
          data-testid="overageMilesId"
          className={`${isInvoiceAvailable ? classes.overageMiles : classes.invoiceNotAvailableContent}`}
        >
          {overageToDisplayText}{' '}
        </div>
      )}
      {isInvoiceAvailable && (
        <>
          <div className={`${classes.dashes}`}></div>
          <div data-testid="invoiceAmountId" className={classes.invoiceAmount}>
            {totalToDisplayText}
          </div>
          <div className={`${classes.autoPayDateFloater}`}>{autoPayToDisplayText}</div>
        </>
      )}
    </div>
  );
};

export default InvoiceSnapshot;
