import React, { ChangeEvent, useEffect, useState } from 'react';
import Footer from '../components/Footer/Footer';
import { PaymentMethod } from '@stripe/stripe-js';
import { setPrimaryCard, updateCard } from '../services/customerService';
import classes from './PaymentMethod.module.scss';
import Input from '../components/Input/Input';
import StripePaymentForm from '../components/StripePaymentForm/StripePaymentForm';
import NavWebHeader from '../components/NavWebHeader/NavWebHeader';

const AddPaymentMethod = () => {
  const [paymentMethod, setPaymentMethod] = useState('');
  const [cardName, setCardName] = useState('');
  const [isPrimaryCard, setIsPrimaryCard] = useState(false);

  useEffect(() => {
    (async () => {
      if (paymentMethod.length) {
        await updateCard({
          paymentMethodId: paymentMethod,
          cardName,
        });

        if (isPrimaryCard) {
          await setPrimaryCard(paymentMethod);
        }
      }
    })();
  }, [paymentMethod]);

  const handlePaymentMethod = (pm: string | PaymentMethod) => {
    if (typeof pm === 'string') {
      setPaymentMethod(pm);
    } else {
      setPaymentMethod(pm.id);
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCardName(e.target.value);
  };

  return (
    <>
      <NavWebHeader title="Payment Information" />
      <section className="l-section" role="main">
        <form className="l-abs-center">
          <Input id="cardName" label="Card Name" handleOnChange={handleOnChange} />
          <label className={classes.checkbox_label}>
            <input
              type="checkbox"
              checked={isPrimaryCard}
              onChange={() => setIsPrimaryCard(!isPrimaryCard)}
              data-testid="primary-card-checkbox"
            />
            Primary Payment Method
          </label>
        </form>
        <StripePaymentForm paymentMethod={handlePaymentMethod} isNameEmpty={!cardName.length} />
      </section>
      <Footer />
    </>
  );
};

export default AddPaymentMethod;
