import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer/Footer';
import { getInvoices } from '../services/customerService';
import { Invoice, Past } from '../models/InvoicesResponse';
import { useParams } from 'react-router-dom';
import InvoiceDetails from '../components/InvoiceDetails/InvoiceDetails';
import InvoiceHistory from '../components/InvoiceHistory';

const Invoices = () => {
  const { invoiceId } = useParams();
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [pastInvoices, setPastInvoices] = useState<Map<string, Past[]>>();
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice>();
  const [showInvoiceHistory, setShowInvoiceHistory] = useState<string>();
  const [nextInvoiceId, setNextInvoiceId] = useState<string>();
  const [prevInvoiceId, setPrevInvoiceId] = useState<string>();

  useEffect(() => {
    (async () => {
      const resp = await getInvoices();
      resp.status === 200 && setInvoices(resp.data.recent);
      resp.status === 200 && setPastInvoices(resp.data.past);
    })();
  }, []);

  useEffect(() => {
    if (invoices && invoices.length > 0) {
      setSelectedInvoice(invoices[0]);
      setShowInvoiceHistory(invoiceId ? invoiceId : '');
    }
  }, [invoices, invoiceId]);

  useEffect(() => {
    if (invoices.length > 1) {
      const currentInvoicePosition = invoices.findIndex((value) => value.id === showInvoiceHistory);
      setSelectedInvoice(invoices[currentInvoicePosition]);
      if (currentInvoicePosition <= 0) {
        setPrevInvoiceId(invoices[currentInvoicePosition + 1].id);
        setNextInvoiceId('');
      } else if (currentInvoicePosition === invoices.length - 1) {
        setPrevInvoiceId('');
        setNextInvoiceId(invoices[currentInvoicePosition - 1].id);
      } else {
        setPrevInvoiceId(invoices[currentInvoicePosition + 1].id);
        setNextInvoiceId(invoices[currentInvoicePosition - 1].id);
      }
    }
  }, [showInvoiceHistory]);

  return (
    <>
      {showInvoiceHistory ? (
        <InvoiceDetails invoice={selectedInvoice} nextInvoiceId={nextInvoiceId} prevInvoiceId={prevInvoiceId} />
      ) : (
        <InvoiceHistory invoices={invoices} pastInvoices={pastInvoices} />
      )}
      <Footer />
    </>
  );
};

export default Invoices;
