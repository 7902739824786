import * as React from 'react';
import ProgressStep from './ProgressStep';
import styles from './ProgressBar.module.scss';
import { ProgressBarProps, Step } from './ProgressBar.model';

const steps: Step[] = [
  { id: 1, label: 'Join Now' },
  { id: 2, label: 'Package' },
  { id: 3, label: 'Application' },
  { id: 4, label: 'Checkout' },
];

function ProgressBar({ currentStep }: ProgressBarProps) {
  return (
    <div
      className={styles.progressBar}
      role="progressbar"
      aria-labelledby="Progress"
      aria-valuenow={currentStep}
      aria-valuemin={1}
      aria-valuemax={steps.length}
      aria-valuetext={`${currentStep} out of ${steps.length} steps completed`}
    >
      {steps.map((step, index) => (
        <ProgressStep
          key={step.id}
          step={step}
          currentStep={currentStep}
          isFirst={index === 0}
          isLast={index === steps.length - 1}
        />
      ))}
    </div>
  );
}

export default ProgressBar;
