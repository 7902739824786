import c from './AnimatedButton.module.scss';

const AnimatedButton = ({
  enableSubmit,
  processing,
  text,
  success = false,
  handleSubmit = () => {
    return;
  },
  onAnimationEnd = () => {
    return;
  },
  disabledBgColor = '#D6DCEA',
  disabledColor = '#888D95',
  bgColor = '#00095B',
  color = '#F8F9FA',
}) => {
  const buttonClass = () => {
    if (enableSubmit) {
      return c.enabled;
    }
    if (processing) {
      return c.processing;
    }
    return c.disabled;
  };

  return (
    <>
      {success ?
        <button
          className={`${c.submit} ${c.success}`}
          disabled
          style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/images/greenCircle.png'})` }}
          data-testid={'success'}
          onAnimationEnd={onAnimationEnd}
        >
          <img src={'/assets/images/successCheck.png'} className={c.successCheck} />
        </button>
      : <button
          className={`${c.submit} ${buttonClass()}`}
          onClick={handleSubmit}
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/images/greenCircle.png'})`,
            backgroundColor: `${buttonClass() === c.disabled ? disabledBgColor : bgColor}`,
            color: `${buttonClass() === c.disabled ? disabledColor : color}`,
          }}
          disabled={!enableSubmit}
          type={'submit'}
          data-testid={`button-${buttonClass()}`}
        >
          {processing ?
            <img
              className={`spinner button-spinner ${c.spinner}`}
              src={process.env.PUBLIC_URL + '/assets/images/spinner-white.svg'}
              alt="processing"
            />
          : text}
        </button>
      }
    </>
  );
};

export default AnimatedButton;
