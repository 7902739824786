import React from 'react';
import classes from './SuccesByType.module.scss';
import { useNavigate } from 'react-router-dom';
import {SuccessType} from "../../pages/Success";

const TEXT_BY_TYPE: { [key in SuccessType]: string } = {
  add: 'Your card was added successfully!',
  update: 'Your card was updated successfully!',
  delete: 'Your card was removed successfully!',
};

interface Props {
  type: SuccessType;
}

const SuccessByType = ({ type }: Props) => {
  const navigate = useNavigate();

  const handleSubmit = async () => {
    navigate(`/dashboard`);
  };

  return (
    <div className={classes.container} role="main">
      <div className={classes.image}>
        <img
          src={'/assets/images/save-success.gif'}
          data-testid="add-card-success-image"
          alt="add card success image"
        />
      </div>
      <p className={classes.successMessage}>{TEXT_BY_TYPE[type]}</p>
      <button className="button--primary" onClick={handleSubmit}>
        Head Back Home
      </button>
    </div>
  );
};

export default SuccessByType;
