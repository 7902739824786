import React from 'react';
import VehicleStatus from '../../../../../components/VehicleStatus/VehicleStatus';
import NotesCard from '../../../../../components/cards/NotesCard/NotesCard';
import WaitlistDetails from '../WaitlistDetails/WaitlistDetails';
import CreditCardDetails from '../../../../../components/CreditCardDetails/CreditCardDetails';
import LeasePackage from '../../../../../components/widgets/LeasePackage/LeasePackage';
import Spacer from '../../../../../components/Spacer';

const WaitlistBody = () => {
  return (
    <div className="l-center">
      <VehicleStatus />
      <NotesCard>
        <WaitlistDetails />
      </NotesCard>
      <CreditCardDetails />
      <LeasePackage />
      <Spacer height={32} />
    </div>
  );
};

export default WaitlistBody;
