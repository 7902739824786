import React from 'react';
import UberLogo from '../UberLogo/UberLogo';
import classes from './UserDetails.module.scss';

const formatPhoneNumber = (phoneNumberString: string | undefined) => {
    const woi18n = ('' + phoneNumberString).replace('+1', '');
    const cleaned = woi18n.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
};

const UserDetails = ({userProfile}) => {
    return (
        <div className={classes.container}>
            <div>
                <UberLogo/>
            </div>
            <div className={classes.user_details_container}>
                <div className={classes.name}>
                    {userProfile?.name?.firstName}
                </div>
                <div className={classes.email}>
                    {userProfile?.email}
                </div>
                <div className={classes.number}>
                    {formatPhoneNumber(userProfile?.phoneNumber)}
                </div>
            </div>
        </div>
    );
};

export default UserDetails;
