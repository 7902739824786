export const longDateFormat: Intl.DateTimeFormatOptions = {
  weekday: 'short',
  month: '2-digit',
  day: '2-digit',
  year: 'numeric',
};

export const shortDateFormat: Intl.DateTimeFormatOptions = {
  month: '2-digit',
  day: '2-digit',
};

export const leaseDateFormat: Intl.DateTimeFormatOptions = {
  month: 'long',
  day: '2-digit',
  year: 'numeric',
};

export const formatDate = (dateString: string, dateFormat: Intl.DateTimeFormatOptions) => {
  const milliSeconds = Date.parse(dateString);
  if (isNaN(milliSeconds)) {
    return '';
  }
  const parsedDate = new Date(milliSeconds);
  return parsedDate.toLocaleDateString('en-US', dateFormat);
};

export const formatDateFromMillis = (milliSeconds: number, dateFormat: Intl.DateTimeFormatOptions) => {
  if (isNaN(milliSeconds)) {
    return '';
  }
  const parsedDate = new Date(milliSeconds);
  return parsedDate.toLocaleDateString('en-US', dateFormat);
};

export const formatPickupOrReturnDate = (dateString: string) => {
  const milliSeconds = Date.parse(dateString);
  if (isNaN(milliSeconds)) {
    return '';
  }
  const parsedDate = new Date(milliSeconds);
  const driverDateFormat: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  };
  const formatter = new Intl.DateTimeFormat('en-US', driverDateFormat);
  return formatter
    .formatToParts(parsedDate)
    .map(({ type, value }) => {
      if (type === 'month') return `${value}.`;
      else return value;
    })
    .join('');
};

export const getPreviousMonday = (pickupDate, currentDate) => {
  const actualPickupDate = new Date(pickupDate);
  const prevMonday = new Date(currentDate);

  prevMonday.setDate(prevMonday.getUTCDate() - (prevMonday.getUTCDay() - 1));

  if (actualPickupDate > prevMonday) {
    return actualPickupDate;
  } else {
    return prevMonday;
  }
};

function getOrdinalSuffix(value: number) {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const newValue = value % 100;

  return newValue + (suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0]);
}

export function getNextMonday(date: Date) {
  const day = date.getUTCDay();
  const daysUntilNextMonday = (1 - day + 7) % 7 || 7;
  const nextMonday = new Date(
    Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() + daysUntilNextMonday)
  );

  return nextMonday.toISOString().slice(0, -1);
}

export function formatMondayDate(date: Date) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const month = months[date.getMonth()];
  const day = getOrdinalSuffix(date.getDate());
  const year = date.getFullYear();

  return `Monday ${month} ${day}, ${year}`;
}

export const isTimeBetween11pmSundayESTAnd2pmMondayEST = (now: Date) => {
  const easternTime = now.toLocaleString('en-US', { timeZone: 'America/New_York' });
  const easternDateTime = new Date(easternTime);
  const hours = easternDateTime.getHours();
  const day = easternDateTime.getDay();

  if (day === 0) {
    return hours >= 23;
  } else if (day === 1) {
    return hours <= 13;
  }
  return false;
};

export const convertStringToTimeValue = (stringValue: string) => {
  if (stringValue === null || stringValue === undefined || stringValue.length === 0) {
    return '';
  }
  const timestamp = parseInt(stringValue);
  const dateObj = new Date(timestamp);
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();

  let period = 'AM';
  let formattedHours = hours;

  if (hours >= 12) {
    period = 'PM';
    formattedHours = hours % 12;
  }

  if (formattedHours === 0) {
    formattedHours = 12;
  }

  // Format the time value as "H:MM AM/PM"
  return `${formattedHours}:${minutes.toString().padStart(2, '0')} ${period}`;
};

export function is25OrOlder(year, month, day) {
  // Create date objects for the birthday and the current date
  const birthDate = new Date(year, month - 1, day);
  const currentDate = new Date();
  // Calculate the age
  let age = currentDate.getFullYear() - birthDate.getFullYear();
  const m = currentDate.getMonth() - birthDate.getMonth();
  // Adjust the age if the birthday hasn't occurred yet this year
  if (m < 0 || (m === 0 && currentDate.getDate() < birthDate.getDate())) {
    age--;
  }
  return age >= 25;
}
