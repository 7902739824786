import React, { useEffect, useState } from 'react';
import classes from './SubscriptionDetails.module.scss';
import UpcomingPaymentInfo from '../../Active/UpcomingPaymentInfo/UpcomingPaymentInfo';
import { PaymentDetails } from '../../../../../models/PaymentDetails';
import { getLatestPaymentDetails } from '../../../../../services/paymentDetailsService';

const SubscriptionDetails = ({ odometerReading, balance }) => {
  const [paymentDetails, setPaymentDetails] = useState<PaymentDetails>();
  useEffect(() => {
    (async () => {
      try {
        const paymentDetailsResponse = await getLatestPaymentDetails();
        setPaymentDetails(paymentDetailsResponse.data);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);
  return (
    <div className={classes.subscription_details}>
      <UpcomingPaymentInfo odometerReading={odometerReading} balance={balance} />
      {paymentDetails?.paymentDate && (
        <div className={classes.last_payment}>Last payment made on {paymentDetails.paymentDate}. </div>
      )}
    </div>
  );
};

export default SubscriptionDetails;
