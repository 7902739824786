import { useNavigate } from 'react-router-dom';
import { useLeasePackage } from './useLeasePackage';
import { formatDate } from '../../../utils/dateUtil';
import React from 'react';

interface LeasePackageProps {
  disableSwitch?: boolean;
}

const LeasePackage: React.FC<LeasePackageProps> = ({ disableSwitch = false }) => {
  const { pricingDisplayInfo, upcomingPlan } = useLeasePackage();
  const navigate = useNavigate();

  const longDateFormat: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
  };

  const getNextLeasePackageUpdateDate = () => {
    const nextMonday = new Date();
    nextMonday.setDate(nextMonday.getDate() + ((((7 - nextMonday.getDay()) % 7) + 1) % 7));
    const isExpired = nextMonday.getTime() < new Date().getTime();
    if (upcomingPlan === null || pricingDisplayInfo.id === upcomingPlan.id || isExpired) {
      return null;
    }
    return (
      <p style={{ color: '#6E6E6E', fontSize: '12px' }}>
        This plan takes effect on {formatDate('' + nextMonday, longDateFormat)}
      </p>
    );
  };

  return (
    <>
      <h4 style={{ marginBottom: '16px' }}>Lease Package</h4>
      <section
        className="card--lease-widget"
        data-testid="widget"
        onClick={() => (disableSwitch ? navigate('#') : navigate('/package-selection'))}
        style={{ cursor: disableSwitch ? 'not-allowed' : 'pointer' }}
      >
        <div style={{ display: 'grid' }}>
          <p style={{ fontWeight: 700 }}>{upcomingPlan?.bundle}</p>
          <div className="l-flex" style={{ marginTop: '16px' }}>
            <p style={{ paddingRight: '24px' }}>${upcomingPlan?.weeklyRate}.00/wk</p>
            {upcomingPlan?.mileageLimit < 99999 ?
              <p>{upcomingPlan?.mileageLimit} miles</p>
            : <p>Unlimited miles</p>}
          </div>
          {getNextLeasePackageUpdateDate()}
        </div>
        <div className="navigation-caret">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/CaretRight.svg`}
            alt="Navigation caret for lease payment page"
          />
        </div>
      </section>
    </>
  );
};

export default LeasePackage;
