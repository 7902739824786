import Dropdown from '../Dropdown';
import ScheduleYourPickupBody from './ScheduleYourPickupBody';

const ScheduleYourPickup = ({ expanded }) => {
  return (
    <Dropdown title="Schedule Your Pickup" expanded={expanded} data-testid="schedule-your-pickup">
      <ScheduleYourPickupBody />
    </Dropdown>
  );
};
export default ScheduleYourPickup;
