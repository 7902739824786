import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Input } from '../../FormComponents';
import classes from './SignUpFormFields.module.scss';
import { InputModel } from '../../models';

const Child: React.FC<any> = memo(({ register, formState: { errors }, disabled }) => (
  <Input
    register={register}
    name="firstName"
    title="First Name"
    placeholder="First Name"
    data-testid="first-name-input"
    maxLength={128}
    className={`${errors?.firstName && classes.error_warning}`}
    labelClassName={classes.input_label}
    errors={errors}
    lightColor={true}
    disabled={disabled}
    rules={{
      required: {
        value: true,
        message: 'First name is required',
      },
      pattern: {
        value: /^[\p{L}\p{M}'-]{1,50}$/u,
        message: 'Enter a valid First Name',
      },
    }}
  />
));

const FirstNameFormField: React.FC<InputModel> = ({ disabled = false }) => {
  const methods = useFormContext();
  return <Child {...methods} disabled={disabled} />;
};
export default FirstNameFormField;
