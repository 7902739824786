import AccordionItem from './AccordionItem';
import React from 'react';
import classes from './Accordion.module.scss';

const Accordion = ({ data }) => {
  return (
    <ul className={classes.accordion}>
      {data.map((data) => (
        <AccordionItem key={data.title} item={data} />
      ))}
    </ul>
  );
};

export default Accordion;
