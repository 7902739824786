import React from "react";
import { useNavigate } from "react-router-dom";
import classes from "./ChargingInfo.module.scss";

interface ChargingInfoProps {
  imageToDisplay: string;
  title: string;
  navigateTo: string;
  description: string;
}

const ChargingInfo: React.FC<ChargingInfoProps> = ({
  imageToDisplay,
  title,
  navigateTo,
  description,
}) => {
  const navigate = useNavigate();
  return (
    <div className={classes.charging_info_container}>
      <div>
        <img
          className={classes.charging_info_image}
          src={"/assets/images/" + imageToDisplay}
          alt="charging-image"
        />
      </div>
      <div>
        <p className={classes.heading}>{title}</p>
        <p className={classes.description}>{description}</p>
      </div>
      <div className={classes.button_container}>
        <button
          className={classes.charging_button}
          onClick={() => navigate("/support/" + navigateTo)}
        >
          {title}
        </button>
      </div>
    </div>
  );
};

export default ChargingInfo;
