import CardElementWrapper from '../CardElementWrapper';
import styles from './PaymentForm.module.scss';
import { usePaymentForm } from '../../hooks/usePaymentForm';
import React from 'react';
import AnimatedButton from '../AnimatedButton/AnimatedButton';

type PaymentFormProps = {
  clientSecret: string;
  setIsCardSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  calledFrom: string;
};

function PaymentForm({ clientSecret, setIsCardSubmitted, calledFrom }: PaymentFormProps) {
  const {
    success,
    processing,
    errorMessage,
    zipCode,
    zipCodeIsValid,
    handleZipCodeChange,
    handleZipCodeBlur,
    handleSubmit,
  } = usePaymentForm(clientSecret, setIsCardSubmitted, calledFrom);

  return (
    <form data-testid="payment-form" onSubmit={handleSubmit} className={styles.formContainer}>
      <p className={`${styles.sizeFull} ${styles.formInfo}`}>
        Your card will not be charged until pickup. You can change your card at any time.
      </p>
      <CardElementWrapper className={styles.sizeFull} element="number" label="Card number" />
      <CardElementWrapper className={styles.sizeFive} element="expiry" label="Expiry" />
      <CardElementWrapper className={styles.sizeFour} element="cvc" label="CVC" />
      <Input
        className={styles.sizeSeven}
        id="zipcode"
        isValid={zipCodeIsValid}
        label="Zip code"
        max={99999}
        min={10000}
        onBlur={handleZipCodeBlur}
        onChange={handleZipCodeChange}
        placeholder="Zip Code"
        style={{ width: '100%' }}
        type="text"
        value={zipCode}
      />
      {errorMessage && <p className={`${styles.sizeFull} ${styles.message} ${styles.error}`}>{errorMessage}</p>}
      <div className={`${styles.sizeFull}`}>
        <AnimatedButton enableSubmit={!success} processing={processing} success={success} text={'Submit Card'} />
      </div>
    </form>
  );
}

function Input({ id, isValid, className, label, ...delegated }) {
  return (
    <label className={`${className} ${styles.label}`} htmlFor={id}>
      {label}
      <input {...delegated} className={`${styles.inputWrapper} ${!isValid ? styles.zipCodeError : ''}`} id={id} />
    </label>
  );
}

export default PaymentForm;
