import React, { useContext } from 'react';
import { ExpandContext } from '../Dropdown';
import classes from './RedeemVoucher.module.scss';
import Spacer from '../Spacer';

const RedeemVoucherBody = () => {
  const setIsOpen = useContext(ExpandContext);
  return (
    <div className={classes.wrapper}>
      <p className={classes.text}>for a free Uber ride to the pick up location,</p>
      <p className={classes.text}>or you can meet us at the pick up location.</p>
      <Spacer height={16} />
      <button
        className="button-pickup"
        onClick={() => {
          setIsOpen(false);
          window.open('https://vouchers.uber.com/c/rT7180d8eFJ', '_blank', 'noopener');
        }}
      >
        Redeem Voucher
      </button>
    </div>
  );
};

export default RedeemVoucherBody;
