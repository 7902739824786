import React from 'react';
import classes from './UserDetailsContainer.module.scss';
import SubscriptionCard from '../cards/SubscriptionCard/SubscriptionCard';
import UserDetails from '../UserDetails/UserDetails';
import { UserProfile } from '../../models/UserProfile';
import { Reservation } from '../../models/Reservation';

type Props = {
  userProfile: UserProfile;
  reservation: Reservation;
};

const UserDetailsContainer = ({ userProfile, reservation }: Props) => {
  return (
    <div className={classes.container}>
      <div className={classes.welcome_container}>
        <h2 className={classes.title}>Welcome</h2>
      </div>
      <div>
        <SubscriptionCard linkUrl="/settings" displayArrow={reservation && !!reservation.billingCustomerId}>
          <UserDetails userProfile={userProfile} />
        </SubscriptionCard>
      </div>
    </div>
  );
};

export default UserDetailsContainer;
