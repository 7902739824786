import React, {useEffect, useState} from "react";
import {Dialog} from "primereact/dialog";
import modalClasses from "../Modal.module.scss";
import classes from "./InvoiceFilterModal.module.scss";

export interface ModalProps {
    isOpen: boolean,
    onClose: () => void,
    filterPeriod: (array) => void,
    yearAndMonths: string[]
}

const InvoiceFilterModal: React.FC<ModalProps> = ({isOpen, onClose, filterPeriod, yearAndMonths}) => {
    const [years, setYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState("");
    const [selectedMonths, setSelectedMonths] = useState([]);
    const [monthsHaveInvoice, setMonthsHaveInvoice] = useState([]);
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const [yearAndMonthsMap, setYearAndMonthsMap] = useState(new Map());

    useEffect(() => {
        const _yearAndMonthsMap = new Map();
        yearAndMonths?.map((key) => {
            const keyValue = key.split("-");
            const _existingMonths = [...(_yearAndMonthsMap.get("20" + keyValue[1]) || []), keyValue[0]];
            _yearAndMonthsMap.set("20" + keyValue[1], _existingMonths);
        });
        setYearAndMonthsMap(_yearAndMonthsMap);

        const _years = Array.from(_yearAndMonthsMap?.keys());
        if (_years) {
            setYears(_years);
            setSelectedYear(_years[0]);
        }
        const _months = _yearAndMonthsMap?.get(_years[0]);
        if (_months) {
            setMonthsHaveInvoice(_months);
            setSelectedMonths(_months);
        }
    }, []);

    useEffect(() => {
        if (selectedYear) {
            const _monthArray = yearAndMonthsMap.get(selectedYear);
            setMonthsHaveInvoice(Array.from(_monthArray));
            setSelectedMonths(Array.from(_monthArray));
        }
    }, [selectedYear]);

    const applyFilter = () => {
        filterPeriod(selectedMonths.map((month) => month + "-" + selectedYear.slice(2, 4)));
        onClose();
    };

    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            setSelectedMonths([...selectedMonths, e.target.value]);
        } else {
            setSelectedMonths(
                selectedMonths.filter((m) => m !== e.target.value)
            );
        }
    };

    return (
        <Dialog
            className={modalClasses.dialog}
            contentClassName={classes.dialogContent}
            visible={isOpen}
            onHide={onClose}
            closeOnEscape
            blockScroll
            draggable={false}
            header={<div className={modalClasses.header}>Filter</div>}
            footer={
                <div className={classes.button_area}>
                    <button className={classes.button_cancel} onClick={onClose} data-testid="invoice-cancel-btn">
                        Cancel
                    </button>
                    <button
                        className={classes.button_apply}
                        onClick={() => applyFilter()}
                        disabled={selectedMonths.length === 0}
                        data-testid="invoice-apply-btn"
                    >
                        Apply
                    </button>
                </div>
            }
        >
            <hr/>
            <div data-testid="modal-container" className={modalClasses.container}>
                <div className={classes.container_item}>Year</div>
                <div className={classes.combo_box_area}>
                    <select
                        name="years"
                        id="years"
                        className={classes.combo_box}
                        value={selectedYear}
                        onChange={e => setSelectedYear(e.target.value)}
                    >
                        {years.map((year) => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                    </select>
                </div>
                <div className={classes.container_item}>Month</div>
                <div className={classes.month_box_area}>
                    <p className={classes.month}>
                        {months.map((month) => {
                            return (
                                <label key={month}>
                                    <input
                                        type="checkbox"
                                        name="months"
                                        value={month}
                                        key={month}
                                        data-testid={month}
                                        checked={selectedMonths?.includes(month)}
                                        onChange={e => handleCheckboxChange(e)}
                                        disabled={!monthsHaveInvoice?.includes(month)}
                                    />
                                    <span
                                        className={`${!monthsHaveInvoice?.includes(month) ? classes.disabled : ""}`}>{month}</span>
                                </label>
                            );
                        })}
                    </p>
                </div>
            </div>
            <hr/>
        </Dialog>
    );
};

export default InvoiceFilterModal;
