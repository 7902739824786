import React, { useContext } from 'react';
import classes from './VehicleStatus.module.scss';
import { PricingDisplayInfoContext } from '../../pages/Dashboard/Dashboard';

const VehicleStatus = () => {
  const pricingDisplayInfo = useContext(PricingDisplayInfoContext);

  const toTitleCase = (str) =>
    str?.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
    });
  return (
    <div className={classes.container}>
      <h4 data-testid={'test-status-text'} className={classes.heading}>
        {toTitleCase(pricingDisplayInfo?.bundle)} Plan
      </h4>
      <div className={classes.status}>
        <span>{pricingDisplayInfo?.mileageLimit < 9999 ? pricingDisplayInfo?.mileageLimit : 'Unlimited'} Miles</span>
      </div>
    </div>
  );
};

export default VehicleStatus;
