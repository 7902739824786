import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createAccount } from '../../../services/noAuthFlowService';
import { validateZipCode } from '../../../services/marketService';
import { atomWithStorage } from 'jotai/utils';
import classes from '../Orchestrator.module.scss';
import { fetchJourney, updateJourney } from '../../../services/journeyService';
import { NavigationURLForPageKey, PageKey } from '../../../models/PageKey';
import { JourneySource } from '../../../models/JourneySource';
import { getLatestReservation, reservationExists } from '../../../services/reservationService';
import { ReservationStatus } from '../../../models/ReservationStatus';
import { AccountType } from '../ContinueJourney/useContinueJourneyHook';
import { getUserAccountType, getUserProfile } from '../../../services/userService';
import { ampli } from '../../../ampli';
import { is25OrOlder } from '../../../utils/dateUtil';
import { EmailType, sendEmail } from '../../../services/emailService';
import { createJourney } from "../../../utils/journeyUtil";

export const marketCode = atomWithStorage('marketCode', '');
const FlowOrchestrator = () => {
  const navigate = useNavigate();

  useEffect(() => {
    checkReservationExistence();
  }, []);

  const checkReservationExistence = async () => {
    const reservationIsExistResponse = await reservationExists();
    if (reservationIsExistResponse.status == 200 && reservationIsExistResponse?.data?.isExist) {
      await checkForActiveReservation();
    } else {
      await saveSignUpData();
    }
  };

  const checkForActiveReservation = async () => {
    try {
      const reservationResponse = await getLatestReservation();
      if (reservationResponse.status === 200 && reservationResponse?.data?.userId) {
        const properties =
          reservationResponse?.data?.market ?
            {
              user_azure_id: reservationResponse.data.userId,
              market: reservationResponse.data.market,
              orchestrator: 'Flow',
            }
          : { user_azure_id: reservationResponse.data.userId, orchestrator: 'Flow' };
        ampli.identify(reservationResponse.data.userId, properties);
      }
      if (
        reservationResponse.status == 200 &&
        (reservationResponse.data.status == ReservationStatus.RETURNED ||
          reservationResponse.data.status == ReservationStatus.REMOVED ||
          reservationResponse.data.status == ReservationStatus.CLOSED)
      ) {
        const journeyResponse=await fetchJourney();
        if(journeyResponse?.status == 200) {
          navigate(NavigationURLForPageKey[journeyResponse?.data?.pageKey]);
        }else {
          await createJourney(reservationResponse.data.market, navigate);
        }
      } else {
        await navigateAsPerLoginAccountType();
      }
    } catch (e) {
      navigate('/error');
      return;
    }
  };

  const navigateAsPerLoginAccountType = async () => {
    const accountTypeResponse = await getUserAccountType();
    const accountType = accountTypeResponse?.data?.accountType;

    try {
      const reservationResponse = await getLatestReservation();
      const userProfileResponse = await getUserProfile();

      // Check to see if user is under 25 in LA
      const [month, day, year] = userProfileResponse.data.dateOfBirth.split('/');

      const data = reservationResponse.data;
      if (
        reservationResponse &&
        data &&
        data.market === 'LA' &&
        data.status !== 'ACTIVE' &&
        !is25OrOlder(year, month, day)
      ) {
        console.log('User is under 25 in LA dashboard');
        await sendEmail({ emailType: EmailType.UNDER_25_LA });
        navigate('/under-25-la');
        return;
      }
    } catch (e) {
      console.error(e);
      if (e.config?.url.includes('v1/email')) {
        navigate('/under-25-la');
        return;
      }
    }

    switch (accountType) {
      case AccountType.OTP_ACCOUNT:
        navigate('/create-password');
        break;
      case AccountType.PASSWORD_ACCOUNT:
        navigate('/dashboard');
        break;
      default:
        navigate('/error');
        break;
    }
  };

  const saveSignUpData = async () => {
    try {
      const createAccountResponse = await createAccount();
      if (createAccountResponse.status == 208) {
        await handleIfUserAlreadySignedUp();
        return;
      }
    } catch (error) {
      navigate('/error');
      return;
    }
    await handleZipCodeValidation(null);
  };

  const handleIfUserAlreadySignedUp = async () => {
    try {
      const journeyResponse = await fetchJourney();
      if (journeyResponse?.status == 200) {
        if (journeyResponse?.data?.userId) {
          const properties =
            journeyResponse?.data?.metadata?.market ?
              {
                user_azure_id: journeyResponse.data.userId,
                market: journeyResponse.data.metadata.market,
                orchestrator: 'Flow',
              }
            : { user_azure_id: journeyResponse.data.userId, orchestrator: 'Flow' };

          ampli.identify(journeyResponse.data.userId, properties);
        }
        if (journeyResponse?.data?.metadata == null) {
          await handleZipCodeValidation(journeyResponse?.data?.journeyId);
        } else {
          navigate(NavigationURLForPageKey[journeyResponse?.data?.pageKey]);
        }
      } else {
        await handleZipCodeValidation(null);
      }
      return;
    } catch (e) {
      console.error(e);
    }
  };

  const handleZipCodeValidation = async (journeyId: string) => {
    try {
      const isValidZipCode = await validateHomeZipCode(journeyId);
      if (!isValidZipCode) {
        navigate('/zip-code-error');
        return;
      }
    } catch (e) {
      console.error(e);
      navigate('/zip-code-error');
    }
  };

  const validateHomeZipCode = async (journeyId: string) => {
    try {
      const zipCodeValidationResponse = await validateZipCode();
      if (!zipCodeValidationResponse?.data || zipCodeValidationResponse.data?.message !== 'available') {
        return false;
      }
      return updateMarketCode(zipCodeValidationResponse.data?.marketCode, journeyId);
    } catch (e) {
      return false;
    }
  };

  const updateMarketCode = async (marketCode: string, journeyId: string) => {
    if (marketCode) {
      if (journeyId) {
        await updateUserJourney(marketCode);
      } else {
        await createJourney(marketCode, navigate);
      }
      return true;
    }
    return false;
  };

  const updateUserJourney = async (marketCode: string) => {
    const journeyRequest = {
      journeySource: JourneySource.NA,
      workflowId: 'UBER',
      pageKey: PageKey.PACKAGE_SELECTION,
      metadata: {
        market: marketCode,
        experianVerified: false,
      },
    };
    try {
      const journeyResponse = await updateJourney(journeyRequest);
      if (journeyResponse?.status == 200) {
        if (journeyResponse?.data?.userId) {
          ampli.identify(journeyResponse.data.userId, {
            user_azure_id: journeyResponse.data.userId,
            market: marketCode,
            orchestrator: 'Flow',
          });
        }
        navigate('/account-package-selection');
      }
    } catch (e) {
      navigate('/error');
    }
  };

  return (
    <div className={classes.spinner_container}>
      <div className={classes.spinner}></div>
    </div>
  );
};

export default FlowOrchestrator;
