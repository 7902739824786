import React, {useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';

import classes from './FormComponents.module.scss';
import Tooltip from '../Tooltip/Tooltip'; // NOTE: This module is to contain the various wrapping components for html native tags and react hook forms logic

// NOTE: This module is to contain the various wrapping components for html native tags and react hook forms logic
// Please use https://www.react-hook-form.com/advanced-usage/#SmartFormComponent &&
// https://www.react-hook-form.com/advanced-usage/#FormProviderPerformance for reference
export const Form: React.FC<any> = ({
  defaultValues,
  mode,
  children,
  onSubmit,
  allFieldsDirty = null,
  setAllFieldsDirty = null,
  startingValues = null,
}) => {
  const methods = useForm({ mode, defaultValues });

  useEffect(() => {
    if (!setAllFieldsDirty) return;
    const allDirty = Object.keys(methods.formState.dirtyFields).length === Object.keys(defaultValues).length;
    if (allFieldsDirty === allDirty) return;
    setAllFieldsDirty(allDirty);
  }, [methods.formState]);

  useEffect(() => {
    if (!startingValues) return;
    Object.keys(methods.getValues()).forEach((key) => {
      methods.setValue(key, startingValues[key], { shouldDirty: true });
    });
  }, [startingValues]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>{children}</form>
    </FormProvider>
  );
};

export const Input: React.FC<any> = ({
  register,
  name,
  title,
  className,
  labelClassName,
  containerClassName,
  rules,
  errors,
  hideErrors,
  lightColor = false,
  ...rest
}: any) => {
  return (
    <div className={`${containerClassName} ${classes.container}`}>
      <label className={`${labelClassName} ${classes.label}`} htmlFor={name}>
        {title}
      </label>
      <input
        className={`${className} ${classes.input} ${errors && errors[name] ? classes.input_error : ''}`}
        id={name}
        data-testid={name}
        {...register(name, { ...rules })}
        {...rest}
      />
      {!hideErrors && errors && errors[name] && (
        <ErrorMessage role="error" errorMessage={errors[name].message} lightColor={lightColor} />
      )}
    </div>
  );
};

export const HiddenInput: React.FC<any> = ({ register, name, value, ...rest }: any) => {
  return (
    <input type="hidden" id={name} disabled={true} data-testid={name} value={value} {...register(name, {})} {...rest} />
  );
};

export const ConsentCheckBoxComponent: React.FC<any> = ({
  register,
  name,
  text,
  className,
  rules,
  errors,
  id,
  onConsentHandler,
  lightColor = false,
  setValue,
  ...rest
}: any) => {
  const [isUnchecked, setIsUnchecked] = useState(true);
  const onConsentSelection = (target) => {
    setIsUnchecked(!target.checked);
    setValue(name, target.checked, { shouldDirty: true });
    onConsentHandler(target.checked ? target.id : '');
  };

  return (
    <>
      <div className={classes.checkbox_container}>
        <input
          type="checkbox"
          data-testid={name}
          className={`${className} ${classes.checkbox}`}
          id={id}
          {...register(name, { ...rules })}
          {...rest}
          onChange={(event) => onConsentSelection(event.target)}
        />
        <div
          className={`${classes.checkbox_label} ${lightColor ? classes.light : ''}`}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
      {errors && errors[name] && isUnchecked && (
        <ErrorMessage role="consent-error" errorMessage={errors[name].message} lightColor={lightColor} />
      )}
    </>
  );
};

export const Button: React.FC<any> = ({ text, className, ...rest }) => {
  return (
    <button className={`${className} ${classes.button} button--primary--next`} data-testid={text} {...rest}>
      {text}
    </button>
  );
};

export const ErrorMessage: React.FC<any> = ({ errorMessage, className, lightColor = false, ...rest }) => {
  const imagePath = lightColor ? '/assets/images/ValidationError.svg' : '/assets/images/icon--warning.svg';
  return (
    <div className={`${className} ${classes.validation_error}`} {...rest}>
      <img src={process.env.PUBLIC_URL + imagePath} alt="Validation Error" className={classes.error_image}></img>
      <p className={`${classes.error_message} ${lightColor ? classes.light : ''}`}>{errorMessage}</p>
    </div>
  );
};

export const InputWithTooltip: React.FC<any> = ({
  register,
  name,
  title,
  className,
  rules,
  errors,
  toolTipTitle,
  toolTipText,
  labelClassName,
  lightColor = false,
  ...rest
}: any) => {
  return (
    <div className={classes.container}>
      <div className={classes.tooltip_container}>
        <label className={`${labelClassName} ${classes.label}`} htmlFor={name}>
          {title}
        </label>
        <Tooltip title={toolTipTitle} text={toolTipText}>
          <img
            className={classes.info_icon}
            alt="Info Icon"
            src={process.env.PUBLIC_URL + '/assets/images/InfoIcon.svg'}
          ></img>
        </Tooltip>
      </div>
      <input
        className={`${className} ${classes.input} ${errors && errors[name] ? classes.input_error : ''}`}
        id={name}
        data-testid={name}
        {...register(name, { ...rules })}
        {...rest}
      />
      {errors && errors[name] && (
        <ErrorMessage role="error" errorMessage={errors[name].message} lightColor={lightColor} />
      )}
    </div>
  );
};
