import React from 'react';
import ChargingDetails from '../ChargingDetails/ChargingDetails';
import ChargingPointOperator from '../ChargingPointOperator/ChargingPointOperator';
import classes from './PublicCharging.module.scss';
import NavWebHeader from '../../../../components/NavWebHeader/NavWebHeader';

const PublicCharging = () => {
  return (
    <>
      <NavWebHeader title="Public Charging" />
      <div className={classes.wrapper}>
        <div className={classes.body_container}>
          <ChargingDetails
            title={'Charging your EV on the road is convenient.'}
            details={
              'Public charging stations are popping up near restaurants, parking spots, parks and other common areas. They’re easy to locate and use.'
            }
          />
          <div className={classes.heading}>
            <p>Two simple ways to find a public charger near you</p>
          </div>
          <div className={classes.image_area}>
            <img
              className={classes.image}
              src={'/assets/images/FindPublicCharger.png'}
              alt="find-public-charger-image"
            />
          </div>
          <div className={classes.heading}>
            <p>Mustang Mach&nbsp;-&nbsp;E Center Screen</p>
          </div>
          <div className={`${classes.details} ${classes.space_below}`}>
            <p>
              You can find public chargers on the go using the SYNC 4A
              <sup className={classes.superscript}>®</sup> center screen of your Mustang Mach-E.
            </p>
          </div>
          <div className={`${classes.image_area} ${classes.space_adjust}`}>
            <img
              className={classes.image}
              src={'/assets/images/ChargePointOperators.png'}
              alt="charge-point-operators-image"
            />
          </div>
          <ChargingPointOperator />
          <ChargingDetails
            title={'Public Charging Support'}
            details={
              'If you need help charging on the go, please reach out to customer support for the charge point operator you’re using. Their number can be found on their app or even on individual charging stations.'
            }
          />
        </div>
      </div>
    </>
  );
};

export default PublicCharging;
