import React, {memo} from 'react';
import {useFormContext} from 'react-hook-form';
import {Input} from '../../FormComponents';
import {FormModel, InputModel} from '../../models';

// NOTE: Please use this component as an example for input implementations
// Please use https://www.react-hook-form.com/advanced-usage/#SmartFormComponent &&
// https://www.react-hook-form.com/advanced-usage/#FormProviderPerformance for reference

// NOTE: This child component is meant to contain the configuration with the Input component, note the use of memo
const Child: React.FC<FormModel> = memo(
    ({register, formState: {errors}, disabled}) => (
        <>
            <Input register={register}
                   name='driversLicenseNumber'
                   title='Driver’s License Number'
                   placeholder='Driver’s License Number'
                   maxLength={20}
                   errors={errors}
                   disabled={disabled}
                   rules={{
                       required: {
                           value: true,
                           message: 'Invalid Number'
                       },
                       minLength: {
                           value: 3,
                           message: 'You need at least 3 characters'
                       },
                       pattern: {
                           value: /^[a-zA-Z0-9]+$/,
                           message: 'Invalid Number'
                       }
                   }}/>
        </>
    ));

// NOTE: This wrapper component for the Input and Child is meant to act as the implementation component to be used
// within the app. You can treat this like any other component and is meant to contain the use of hooks, effects and other
// logic needed for this component. We do this level of abstraction to keep react hook forms logic out of other components
const DriversLicenseFormField: React.FC<InputModel> = ({ disabled }) => {
  const methods = useFormContext();
  return <Child {...methods} disabled={disabled} />;
};
export default DriversLicenseFormField;
