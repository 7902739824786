import React, { useEffect, useState } from 'react';
import classes from './PickupDropoffLocationV2.module.scss';
import { getLocationById } from '../../services/locationService';
import { Location } from '../../models/Location';
import PickupDropoffLocationBody from "../PickupDropoffLocationBody";

const PickupDropoffLocationV2: React.FC<{
  locationId: string;
  isPickup: boolean;
}> = ({ locationId, isPickup }) => {
  const [location, setLocation] = useState<Location>();

  useEffect(() => {
    (async () => {
      try {
        const locationResponse = await getLocationById(locationId);
        setLocation(locationResponse.data);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);
  return (
      <PickupDropoffLocationBody classes={classes} isPickup={isPickup} location={location} version="V2"/>
  );
};

export default PickupDropoffLocationV2;
