import Dropdown from '../Dropdown';
import RedeemVoucherBody from './RedeemVoucherBody';

const RedeemVoucher = ({ expanded }) => {
  return (
    <Dropdown title="Redeem Voucher" expanded={expanded} data-testid="redeem-voucher">
      <RedeemVoucherBody />
    </Dropdown>
  );
};
export default RedeemVoucher;
