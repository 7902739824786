import classes from './SsnError.module.scss';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { runCreditVerification } from '../../services/creditService';
import { PageKey, PageURL } from '../../models/PageKey';
import { updateJourney } from '../../services/journeyService';
import AnimatedButton from '../AnimatedButton/AnimatedButton';
import {
  trackExperianCheck,
  trackSsnContinueButtonClick,
  trackSsnInputPageLoad,
} from '../../services/analyticsService';
import { ErrorType } from '../../pages/ErrorViewer/ErrorViewer';
import { useGetJourney } from '../../pages/Orchestrator/useJourney/useGetJourney';
import VerifyingSpinner from '../VerifyingSpinner/VerifyingSpinner';
import { RedirectTo } from '../../pages/UpdateAddressForm/UpdateAddressForm';
import { createCustomerForBff } from '../../services/stripeService';
import { addWaitListReservation } from '../../services/reservationService';

const SsnError = () => {
  const navigate = useNavigate();
  const [ssn, setSsn] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const submitDisabled = ssn?.length !== 9;
  const [processing, setProcessing] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const { shouldShowContent, journey } = useGetJourney(PageURL.CREDIT_INFO, ['packageId']);

  useEffect(() => {
    trackExperianCheck({ market_code: 'code' });
    trackSsnInputPageLoad();
  }, []);

  const handleChange = (event) => {
    setErrorMessage('');
    if (isNaN(event.target.value)) return;
    setSsn(event.target.value);
    setEnableSubmit(!!event.target.value);
  };

  const handleBlur = () => {
    if (submitDisabled) {
      setErrorMessage('Please enter a valid 9 digit SSN.');
    }
  };

  function handleError(e: any) {
    console.error(e);
    if (e.config?.url.includes('v1/email')) {
      navigate('/under-25-la');
    } else if (e.data?.errorCode) {
      const errorNavigationMap = {
        VR402: () => navigate('/experian-rejection'),
        VR404: () => navigate('/experian-rejection'),
        VR403: () => navigate('/update-address/' + RedirectTo.EXPERIAN),
        VR405: () => navigate('/update-address/' + RedirectTo.EXPERIAN),
        VR406: () => navigate('/experian-rejection'),
        VR407: () => navigate('/experian-rejection'),
        VR408: () => navigate('/experian-rejection'),
        VR409: () => navigate('/experian-rejection'),
      };
      const navigateFunc = errorNavigationMap[e.data.errorCode];
      if (navigateFunc) navigateFunc();
      else navigate('/error/' + ErrorType.EXPERIAN_ERROR);
    } else {
      navigate('/error/' + ErrorType.EXPERIAN_ERROR);
    }
  }

  const submitForm = async () => {
    trackSsnContinueButtonClick();
    setEnableSubmit(false);
    setProcessing(true);
    try {
      if (submitDisabled) {
        setErrorMessage('Please enter a valid 9 digit SSN.');
        setEnableSubmit(true);
        setProcessing(false);
        return;
      }

      const creditVerificationResp = await runCreditVerification(ssn);
      if (creditVerificationResp.status !== 200) {
        console.error('Error running credit verification');
        navigate('/experian-rejection');
        return;
      }

      if (!creditVerificationResp.data.verified) {
        console.error('Experian credit data is unverified');
        navigate('/experian-rejection');
        return;
      }

      const isCreditCardRemovedFlow = JSON.parse(window.__RUNTIME_CONFIG__.REACT_APP_CC_REMOVED);
      let pageKey = PageKey.PAYMENT;
      let navigatePageURL = PageURL.PAYMENT;

      if (isCreditCardRemovedFlow) {
        pageKey = PageKey.SIGNUP_SUCCESS;
        navigatePageURL = PageURL.APPROVED;
      }

      const updatedJourney = await updateJourney({
        pageKey: pageKey,
        metadata: {
          experianVerified: creditVerificationResp.data.verified,
        },
      });

      if (updatedJourney.status !== 200) {
        console.error('Error updating journey');
        return;
      }

      if (isCreditCardRemovedFlow) {
        const { status } = await createCustomerForBff(journey.metadata.market);
        if (status !== 200) {
          console.error('Error creating stripe customer');
          return false;
        }

        const createReservationResponse = await addWaitListReservation(
          journey.metadata.market,
          journey.metadata.packageId
        );
        const updatedJourney = await updateJourney({
          externalId: createReservationResponse.data?.id,
          pageKey: PageKey.SIGNUP_SUCCESS,
        });
        if (createReservationResponse.status !== 200) {
          console.error('Create reservation response was not 200');
          return false;
        }

        if (updatedJourney.status !== 200) {
          console.error('Error updating journey');
          return false;
        }
      }

      navigate(navigatePageURL);
    } catch (e) {
      handleError(e);
    }
  };

  return (
    <>
      {shouldShowContent ?
        <div className="container" role="main">
          <h5 className="container-subtitle">We need more information to move forward</h5>
          <p className={classes.sectiondesc}>
            Please provide your social security number so we can access your credit profile.
            <span className={classes.highlight}> It will not be stored or shared. </span>
          </p>
          <div className={classes.input_container}>
            <p className={classes.inputlabel}>Social Security Number</p>
            <label aria-label="SSN">
              <input
                className={classes.input}
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={ssn}
                data-testid={'ssn-input'}
                disabled={processing}
              />
            </label>
            {errorMessage && (
              <p className={classes.error} data-testid={'ssn-credit-check-error'}>
                {errorMessage}
              </p>
            )}
          </div>
          <div className={classes['button-container']}>
            <AnimatedButton
              enableSubmit={enableSubmit}
              processing={processing}
              text={'Continue'}
              handleSubmit={submitForm}
            />
          </div>
        </div>
      : <VerifyingSpinner />}
    </>
  );
};

export default SsnError;
