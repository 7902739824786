import React from "react";
import {Dialog} from "primereact/dialog";
import modalClasses from "../Modal.module.scss";
import classes from "./InsuranceInfoModal.module.scss";
import {ModalProps} from "../ModalUtils";

const InsuranceInfoModal: React.FC<ModalProps> = ({isOpen, onClose}) => {

    return (
        <Dialog
            className={`${modalClasses.dialog} ${classes.dialog}`}
            contentClassName={classes.dialogContent}
            visible={isOpen}
            onHide={() => onClose()}
            closeOnEscape
            blockScroll
            draggable={false}
            header={<div
                className={`${modalClasses.header} ${classes.header}`}>Ford's Insurance</div>}
        >
            <table data-testid="modal-container" className={`${modalClasses.container} ${classes.container}`}>
                <tbody>
                <tr>
                    <td>Roadside Assistance</td>
                    <td>24/7 Collision Assistance</td>
                </tr>
                <tr>
                    <td>Auto Liability</td>
                    <td>$300,000 combined<br/>single limit (CSL)</td>
                </tr>
                <tr>
                    <td>Uninsured/ Underinsured Motorists</td>
                    <td>$100,000 (CSL)</td>
                </tr>
                <tr>
                    <td>Medical payments</td>
                    <td>up to $1,000</td>
                </tr>
                <tr>
                    <td>Auto Physical Damage</td>
                    <td>up to $55,000</td>
                </tr>
                <tr>
                    <td>Auto Physical<br/>Damage<br/>Deductible</td>
                    <td>$1,000</td>
                </tr>
                </tbody>
            </table>

            <table data-testid="modal-container-22" className={`${modalClasses.container} ${classes.container2}`}>
                <thead>
                <tr>
                    <th colSpan={2} className={`${modalClasses.header} ${classes.header}`}>
                        Period Coverage
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Ford Covers You:</td>
                    <td>
                        <p><label>Period 0:</label>Driving for personal use.</p>
                        <p><label>Period 1:</label>When the app is active, waiting for requests.</p>
                    </td>
                </tr>
                <tr>
                    <td>Ubers Covers You:</td>
                    <td>
                        <p><label>Period 2:</label>En route to pickup.</p>
                        <p><label>Period 3:</label>During trips.</p>
                    </td>
                </tr>

                </tbody>
            </table>
        </Dialog>
    );
};

export default InsuranceInfoModal;