import {useEffect, useState} from 'react';
import {getConsentTexts} from '../../services/consentService';
import {initAuthLogin, savePartialProfileDataToCache} from '../../services/noAuthFlowService';
import {PartialProfile} from '../../models/PartialProfileRequest';
import {
  trackGetStartedButtonClick,
  trackSignInButtonClickFromSignUpPage,
  trackSignUpPageDuration,
  trackSignUpPageLoad,
} from '../../services/analyticsService';
import {usePageDuration} from '../../hooks/usePageDuration';

export default function useSignup() {
  const [, setConsentData] = useState([]);
  const [consent, setConsent] = useState('');
  const [processing, setProcessing] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [allFieldsDirty, setAllFieldsDirty] = useState(false);
  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNo: '',
    homeZipCode: '',
    consents: false,
  };

  useEffect(() => {
    if (allFieldsDirty === enableSubmit) return;
    setEnableSubmit(allFieldsDirty);
  }, [allFieldsDirty]);

  usePageDuration(trackSignUpPageDuration, true);

  useEffect(() => {
    trackSignUpPageLoad();
    (async () => {
      try {
        const consentResponse = await getConsentTexts('Signup');
        if (consentResponse && consentResponse.data) {
          setConsentData(consentResponse.data);
        } else {
          setDefaultValueForConsent();
        }
      } catch (e) {
        setDefaultValueForConsent();
      }
    })();
  }, []);

  const setDefaultValueForConsent = () => {
    setConsentData([
      {
        legalLanguageCode: `${window.__RUNTIME_CONFIG__.REACT_APP_CONSENT_CODE}`,
        textValue: `${window.__RUNTIME_CONFIG__.REACT_APP_CONSENT_TEXT}`,
      },
    ]);
  };

  async function onSubmit(data) {
    trackGetStartedButtonClick();
    setEnableSubmit(false);
    setProcessing(true);
    try {
      const signupData: PartialProfile = {
        firstName: data.firstName,
        middleName: '',
        lastName: data.lastName,
        email: data.email,
        phoneNumber: data.phoneNo?.replace(/\D/g, ''),
        homeZipCode: data.homeZipCode,
        consents: consent,
        referrer: data.referrer,
        //workflowId field is now passed to delegate to differentiate between UBER and RETAIL flow
        workflowId: data.workflowId === 'RETAIL' ? 'RETAIL' : 'UBER',
      };
      const partialProfileData = await savePartialProfileDataToCache(signupData);
      window.location.replace(partialProfileData.data);
    } catch (e) {
      setEnableSubmit(true);
      setProcessing(false);
      console.error(e);
    }
  }

  async function handleSignIn() {
    trackSignInButtonClickFromSignUpPage();
    const authLogin = await initAuthLogin();
    window.location.replace(authLogin.data);
  }

  return {
    defaultValues,
    handleSignIn,
    onSubmit,
    setConsent,
    enableSubmit,
    processing,
    allFieldsDirty,
    setAllFieldsDirty,
  };
}
