import styles from './Button.module.scss';
import { ComponentPropsWithoutRef, ReactNode } from 'react';

interface ButtonProps extends ComponentPropsWithoutRef<'button'> {
  children: ReactNode;
  variant: 'primary' | 'secondary' | 'cta' | 'borderless' | 'ghost';
}

function Button({ children, className, variant = 'primary', ...props }: ButtonProps) {
  return (
    <button className={`${className} ${styles.button} ${styles[variant]}`} {...props}>
      {children}
    </button>
  );
}

export default Button;
