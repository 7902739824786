import React from 'react';
import classes from './SubscriptionCard.module.scss';
import { useNavigate } from 'react-router-dom';

interface CardProps {
  children?: React.JSX.Element;
  subscriptionText?: string;
  linkUrl?: string;
  styles?: Record<string, unknown>;
  displayArrow?: boolean;
}

const SubscriptionCard: React.FC<CardProps> = ({
  children,
  subscriptionText,
  linkUrl,
  styles,
  displayArrow = true,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={classes.container}
      data-testid="sub-card"
      style={styles}
      onClick={() => displayArrow && navigate(linkUrl)}
    >
      {subscriptionText && (
        <div className={classes.subscript} data-testid={'active-body-details-invoice-due-date'}>
          <span>{subscriptionText}</span>
        </div>
      )}
      <div className={classes.body}>
        {children}
        {displayArrow && (
          <div className={classes.chevron} data-testid="sub-card-nav">
            <i className="pi pi-chevron-right" />
          </div>
        )}
      </div>
    </div>
  );
};

export default SubscriptionCard;
