import React from 'react';
import PickupInfoV2 from '../PickupInfoV2/PickupInfoV2';
import { Reservation } from '../../../../../models/Reservation';
import { ReservationStatus } from '../../../../../models/ReservationStatus';
import RememberToBring from '../../../../../components/RememberToBring/RememberToBring';
import RedeemVoucher from '../../../../../components/RedeemVoucher/RedeemVoucher';
import ScheduleYourPickup from '../../../../../components/ScheduleYourPickup';
import Spacer from '../../../../../components/Spacer';
import NoShowPolicy from '../../../../../components/NoShowPolicy';

const PickupPendingBodyV2: React.FC<{ reservation?: Reservation }> = ({ reservation }) => {
  return (
    <div className="l-center">
      <PickupInfoV2 reservation={reservation} />
      {reservation.status === ReservationStatus.WAITLISTED && <ScheduleYourPickup expanded={false} />}
      {reservation.status === ReservationStatus.PENDING && (
        <>
          <Spacer height={24} />
          <RememberToBring expanded={false} /> <br /> <RedeemVoucher expanded={false} /> <br />
          <NoShowPolicy expanded={false} />
        </>
      )}
      <Spacer height={32} />
    </div>
  );
};

export default PickupPendingBodyV2;
