import { postRequestForBff } from '../axios/axiosVerbs';

interface StripeResponse {
  clientSecret: string;
}

export const createCustomerForBff = (market) => postRequestForBff<string>('v1/customer', { market });

export const createPaymentIntent = () =>
  postRequestForBff<StripeResponse>(`v1/customer/payment`, { withCredentials: true }, true);

export const createPaymentIntentForBff = () =>
  postRequestForBff<StripeResponse>(`v1/customer/payment`, { withCredentials: true }, true);
