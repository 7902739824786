import React from 'react';
import { useParams } from 'react-router-dom';
import SuccessByType from '../components/SuccessByType/SuccessByType';
import MobileHeader from '../components/MobileHeader/MobileHeader';
import Footer from '../components/Footer/Footer';

export enum SuccessType {
  ADD = 'add',
  UPDATE = 'update',
  DELETE = 'delete',
}

const Success = () => {
  const { type } = useParams();

  const render = () => {
    switch (type) {
      case SuccessType.ADD:
      case SuccessType.UPDATE:
      case SuccessType.DELETE:
        return <SuccessByType type={type} />;
      default:
        break;
    }
  };

  return (
    <>
      <MobileHeader />
      {render()}
      <Footer />
    </>
  );
};

export default Success;
