import React, { useEffect, useState } from "react";
import classes from "./PickupDropoffSchedule.module.scss";
import { getLatestReservation } from "../../services/reservationService";
import { Reservation } from "../../models/Reservation";
import { convertStringToTimeValue, formatPickupOrReturnDate } from "../../utils/dateUtil";
import { ReservationStatus } from "../../models/ReservationStatus";

const PickupDropoffSchedule = () => {
  const [reservation, setReservation] = useState<Reservation>();
  const [pickupDate, setPickupDate] = useState<string>();
  const [returnDate, setReturnDate] = useState<string>();
  const [pickupTime, setPickupTime] = useState<string>();
  const [dropOffTime, setDropOffTime] = useState<string>();
  useEffect(() => {
    (async () => {
      try {
        const reservationResponse = await getLatestReservation();
        setReservation(reservationResponse.data);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);
  useEffect(() => {
    if (reservation) {
      setPickupDate(formatPickupOrReturnDate(reservation.selectedPickUpDate));
      setReturnDate(formatPickupOrReturnDate(reservation.selectedReturnDate));
      setPickupTime(convertStringToTimeValue(reservation.selectedPickupDateTime));
      setDropOffTime(convertStringToTimeValue(reservation.selectedDropoffDateTime));
    }
  }, [reservation]);

  if(!pickupTime && !dropOffTime) {
    return <></>;
  }

  return (
    <div className={classes['time-wrapper']}>
      <img className={classes.image} src={'/assets/images/calendar.svg'} alt="calendar icon" />
      {reservation && reservation.status === ReservationStatus.PENDING && (
        <div className={classes.time}>
          <time dateTime={reservation.selectedPickUpDate}>{pickupDate}</time>
          {pickupTime && pickupTime.length !== 0 ? (
            pickupTime
          ) : (
            ""
          )}
        </div>
      )}
      {reservation && reservation.status === ReservationStatus.PENDING_RETURN && (
        <div>
          <time dateTime={reservation.selectedReturnDate}>{returnDate}</time>
          <br />
          {dropOffTime && dropOffTime.length !== 0 ? (
            dropOffTime
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};

export default PickupDropoffSchedule;
