import React from 'react';
import './CreditCardConfirmation.scss';
import classes from './PaymentWrapper.module.scss';
import { CardElement } from '@stripe/react-stripe-js';
import useCreditCardConfirmationWrapper from './useCreditCardConfirmationWrapper';
import { Journey } from '../../models/Journey';
import AnimatedButton from '../../components/AnimatedButton/AnimatedButton';

interface Props {
  clientSecret: string;
  journey: Journey;
}

const CreditCardConfirmationWrapper = ({ clientSecret, journey }: Props) => {
  const { errorMessage, handleSubmit, elements, selectedPackage, enableSubmit, processing, onChangeCard, onCardFocus } =
    useCreditCardConfirmationWrapper(clientSecret, journey);

  return (
    <div>
      <h5 className="container-subtitle">Reserve your spot now</h5>
      <div className="card-container section-container">
        <p className="sub-section-label">Payment</p>
        <p className="section-desc">
          Please enter a <span className="price-desc-highlight">payment card.</span>{' '}
          <span className="card-desc-highlight">You will not be charged</span> until you pick up a vehicle.
        </p>
        <div className={classes['payment-container']}>
          <div className={`payment-wrapper-container ${classes['payment-wrapper']}`} id={'payment-element'}>
            {elements && <CardElement onFocus={onCardFocus} onChange={onChangeCard} />}
          </div>
        </div>
      </div>
      <div className="pricing-container section-container">
        <p className="sub-section-label">Pricing Package</p>
        <p className="section-desc">
          Switch packages as many times as needed. Our program requires a{' '}
          <span className="price-desc-highlight">1 month minimum commitment.</span>
        </p>
        <div
          className="card--mileage-package mileage-container mileage-no-gap"
          key={selectedPackage?.id}
          style={{ marginBottom: '32px' }}
        >
          <p className="font-sm mileage-package__header" data-testid="tier-name">
            {selectedPackage?.bundle?.toUpperCase()}
          </p>
          <div className="mileage-package__amount">
            <p className="weekly-rate weekly-rate-item">
              <sup className="superscript">$</sup>
              {selectedPackage?.weeklyRate}.00<span className="superscript">/wk</span>
            </p>
          </div>
          <p className="font-sm mileage-package__desc" data-testid="description">
            {selectedPackage?.mileageLimit} miles included, then <span>$</span>
            {selectedPackage?.meteredRate?.toFixed(2)}/mile
          </p>
        </div>
        {errorMessage && <p className={classes.error}>{errorMessage}</p>}
        <div className="section-container">
          <div className={'button-container'}>
            <AnimatedButton
              data-testid="submit-button"
              enableSubmit={enableSubmit}
              processing={processing}
              text={'Next'}
              handleSubmit={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditCardConfirmationWrapper;
