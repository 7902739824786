import React, { useEffect, useState } from 'react';
import classes from './Faq.module.scss';
import toast, { Toaster } from 'react-hot-toast';
import { searchFaqs, submitQuestion } from '../../services/faqService';
import Footer from '../../components/Footer/Footer';
import Card from '../../components/cards/Card/Card';
import NavWebHeader from "../../components/NavWebHeader/NavWebHeader";
import {useNavigate} from "react-router-dom";
const Faq = () => {
  const [faqResponse, setFaqResponse] = useState({results: []});
  const [searchString, setSearchString] = useState('');
  const [query, setQuery] = useState('');
  const [submittedQuestion, setSubmittedQuestion] = useState('');
  const [processingSubmit, setProcessingSubmit] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const faqResponse = await searchFaqs(query);
        setFaqResponse(faqResponse.data);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [query]);

  const handleSearch = (event) => {
    event.preventDefault();
    setQuery(searchString);
  };

  const handleSearchChange = (event) => {
    setSearchString(event.target.value);
  };

  const handleSubmitQuestionChange = (event) => {
    setSubmittedQuestion(event.target.value);
  };

  const handleSubmitQuestion = () => {
    (async () => {
      try {
        setProcessingSubmit(true);
        await submitQuestion(submittedQuestion);
        setSubmittedQuestion('');

        toast(
            (t) => (
                <div
                    style={{
                      animation: t.visible ? 'custom-enter 0.3s ease-in!important' : 'custom-exit 1s ease-out!important',
                    }}
                    className={`${classes.toast}`}
                >
                  <p>Your question has been submitted.</p>
                </div>
            ),
            {
              duration: 3700,
              style: {
                display: 'block',
                background: 'none',
                backgroundColor: 'white',
                boxShadow: 'unset',
                lineHeight: 'unset',
                maxWidth: 'unset',
                padding: '0',
              },
            }
        );
      } catch (e) {
        toast(
            (t) => (
                <div
                    style={{
                      animation: t.visible ? 'custom-enter 0.3s ease-in!important' : 'custom-exit 1s ease-out!important',
                    }}
                    className={`${classes.toast}`}
                >
                  <img className={classes.warning} src={'/assets/images/warning.svg'} alt=""/>
                  <p>
                    We’re unable to process your
                    <br/>
                    request at this time. Please try later.
                  </p>
                </div>
            ),
            {
              duration: 3700,
              style: {
                display: 'block',
                background: 'none',
                backgroundColor: 'white',
                boxShadow: 'unset',
                lineHeight: 'unset',
                maxWidth: 'unset',
                padding: '0',
              },
            }
        );
      } finally {
        setProcessingSubmit(false);
      }
    })();
  };

  return (
      <>
        <div className="l-height-bg">
          <Toaster/>
          <NavWebHeader title={'FAQ'} shouldNav={true} handleBackNavigation={() => navigate(-1)}/>
          <div className="l-border-radius-bg">
            <div className="l-center">
              <section className={classes.content} role="main">
                <div>
                  <h4 className={classes.faq_header}>Have a Question?</h4>
                </div>
                <div className={classes.input_container}>
                  <form onSubmit={handleSearch}>
                    <label>
                      <input
                          className={`${classes.input} ${classes.search_field}`}
                          type="text"
                          placeholder="Enter topic or question to search"
                          onChange={handleSearchChange}
                          data-testid="search_questions_text"
                      />
                    </label>
                    <button
                        type="submit"
                        className={classes.search_button}
                        onClick={handleSearch}
                        data-testid="search_questions_button"
                    >
                      <img src="/assets/images/search.svg" alt="search"/>
                    </button>
                  </form>
                </div>
                {faqResponse?.results.map((faq) => <Card key={faq.id} faq={faq}/>)}
                <div>
                  <h4 className={classes.faq_header}>Have another question?</h4>
                  <span className={classes.faq_subheader}>Type and submit your question below.</span>
                </div>
                <div className={classes.input_container}>
                  <label>
                    <input
                        className={`${classes.input} ${classes.search_field}`}
                        type="text"
                        placeholder="What is your question?"
                        value={submittedQuestion}
                        onChange={handleSubmitQuestionChange}
                        data-testid="another_question_text"
                    />
                  </label>
                </div>
                <div className="l-flex-center">
                  <button
                      className={classes.submit_question_button}
                      onClick={handleSubmitQuestion}
                      data-testid="submit_another_question"
                  >
                    {!processingSubmit && <span>Submit Question</span>}
                    {processingSubmit && (
                        <span className={`${classes['faq_spinner']} spinner`} data-testid="faq-loader">
              <img src={'/assets/images/spinner-white.svg'} alt="processing"/>
            </span>
                    )}
                  </button>
                </div>
              </section>
            </div>
          </div>
          <Footer></Footer>
        </div>
      </>
  );
};

export default Faq;
