import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Input } from '../../FormComponents';
import { FormModel, InputModel } from '../../models';

import classes from './AdressFormFields.module.scss';

const Child: React.FC<FormModel> = memo(({ register, formState: { errors }, disabled }) => (
  <>
    <Input
      register={register}
      name="zipCode"
      title="Zip Code"
      maxLength={5}
      placeholder="00000"
      className={classes.input_width_medium}
      errors={errors}
      disabled={disabled}
      rules={{
        required: {
          value: true,
          message: 'Invalid Zip Code',
        },
        minLength: {
          value: 5,
          message: 'You need 5 digits',
        },
        pattern: {
          value: /^\d{5}$/,
          message: 'Invalid Zip Code',
        },
      }}
    />
  </>
));

const ZipCodeFormField: React.FC<InputModel> = ({ disabled = false }) => {
  const methods = useFormContext();
  return <Child {...methods} disabled={disabled} />;
};

export default ZipCodeFormField;
