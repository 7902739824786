import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkPreconditions, checkResponseStatus } from './utils';
import { Journey } from '../../../models/Journey';
import { fetchJourney } from '../../../services/journeyService';
import { PageURL } from '../../../models/PageKey';

export function useGetJourney(previousUrl: string, preconditionKeys: string[] = []) {
  const navigate = useNavigate();
  const [shouldShowContent, setShouldShowContent] = useState(false);
  const [journey, setJourney] = useState<Journey>(null);

  useEffect(() => {
    let isMounted = true; // Add this line

    (async () => {
      try {
        const { data, status } = await fetchJourney();

        if (!checkResponseStatus(status)) {
          console.error(`Journey response received status: ${status}`);
          navigate(PageURL.SIGN_UP);
          return;
        }

        if (!checkPreconditions(data, preconditionKeys)) {
          console.error(`User's precondition keys were: ${JSON.stringify(data.metadata)}`);
          navigate(previousUrl);
          return;
        }

        if (isMounted) {
          // Check if the component is still mounted
          setJourney(data);
          setShouldShowContent(true);
        }
      } catch (e) {
        console.error(e);
        navigate(PageURL.SIGN_UP);
      }
    })();

    return () => {
      isMounted = false; // Set isMounted to false when the component unmounts
    };
  }, []);
  return { shouldShowContent, journey };
}
