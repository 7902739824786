import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import { createPaymentIntent } from '../../services/stripeService';
import { useNavigate } from 'react-router-dom';
import { loadStripe, PaymentMethod, StripeElementsOptions } from '@stripe/stripe-js';
import { APPEARANCE } from './StripePaymentForm.const';
import { ErrorType } from '../../pages/ErrorViewer/ErrorViewer';
import { SuccessType } from '../../pages/Success';

export const stripePromise = loadStripe(`${window.__RUNTIME_CONFIG__.REACT_APP_STRIPE_PK}`);

interface StripeElementProps {
  clientSecret?: string;
  paymentMethod?: (id: string | PaymentMethod) => void;
  isNameEmpty?: boolean;
}

const StripePaymentForm = ({ paymentMethod, isNameEmpty }: StripeElementProps) => {
  const [options, setOptions] = useState<StripeElementsOptions>(null);
  const navigate = useNavigate();

  useEffect(() => {
    createPaymentIntent()
      .then((resp) => {
        setOptions({
          clientSecret: resp.data.clientSecret,
          appearance: APPEARANCE,
        });
      })
      .catch(() => navigate(`/error/${ErrorType.WAITLIST}`));
  }, []);

  return (
    <Elements stripe={stripePromise} options={null}>
      {options && (
        <StripeElement clientSecret={options.clientSecret} paymentMethod={paymentMethod} isNameEmpty={isNameEmpty} />
      )}
    </Elements>
  );
};

const StripeElement = ({ clientSecret, paymentMethod, isNameEmpty }: StripeElementProps) => {
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState('');

  const elements = useElements();
  const stripe = useStripe();
  const navigate = useNavigate();

  const getCardTypeError = (cardType: string) => {
    const errorMsg: string =
      ['prepaid', 'unknown'].includes(cardType) ?
        'Invalid card number. Please enter a valid payment card number. Prepaid cards are not accepted.'
      : '';
    return errorMsg;
  };

  const handleSubmit = async () => {
    setProcessing(true);
    setError('');

    const card = elements.getElement('card');
    const createdToken = await stripe.createToken(card);

    // set error messages for create token call
    const tokenError = createdToken?.error?.message;
    const cardTypeError = getCardTypeError(createdToken?.token?.card?.funding);
    const errorOccurred = tokenError || cardTypeError;
    setError(errorOccurred);

    // confirm card setup
    if (!errorOccurred) {
      const setupIntentResponse = await stripe.confirmCardSetup(clientSecret, { payment_method: { card } });

      if (setupIntentResponse.error) {
        setError(setupIntentResponse.error.message);
      } else {
        paymentMethod(setupIntentResponse.setupIntent.payment_method);
        card.unmount();
        navigate(`/success/${SuccessType.ADD}`);
      }
    }

    setProcessing(false);
  };

  return (
    <div className="l-abs-center">
      {elements && (
        <div className="container" style={{ maxWidth: '327px' }}>
          <CardElement />
          {error && <p className="error">Card Error: {error}</p>}
        </div>
      )}
      <button className="button--primary l-margin-top l-abs-center" onClick={handleSubmit} disabled={isNameEmpty}>
        {processing ?
          <img className="spinner button-spinner" src={'/assets/images/spinner-white.svg'} alt="processing" />
        : 'Add Payment Method'}
      </button>
    </div>
  );
};

export default StripePaymentForm;
