import React from 'react';
import VehicleStatus from '../../../../../components/VehicleStatus/VehicleStatus';
import PendingReturnDetails from '../PendingReturnDetails/PendingReturnDetails';
import {Reservation} from '../../../../../models/Reservation';
import VehicleDetails from '../../../../../components/VehicleDetails/VechicleDetails';

const PendingReturnBody: React.FC<{ reservation: Reservation }> = ({ reservation }) => {
  return (
    <div className="l-center l-margin-bottom-32">
      <VehicleStatus />
      <PendingReturnDetails reservation={reservation} />
      <VehicleDetails isExploreOption={false} isTitleDiplayed={true} />
    </div>
  );
};

export default PendingReturnBody;
