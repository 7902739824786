import React from "react";
import classes from "../TermsAndConditions.module.scss";

const IndemnityAndHoldHarmless = () => {
  return (
    <>
      <div className={classes.tc_heading}>
        <p>Indemnity and Hold Harmless</p>
      </div>
      <div className={classes.tc_area}>
        <div className={classes.tc_conditions}>
          <div className={classes.tc_condition}>
            By using this Site you agree that you will defend, indemnify and
            hold harmless Ford and its subsidiaries and affiliates, their
            respective distributors, dealers, dealer associations and
            advertising and promotions agencies, together with their respective
            employees, agents, directors, officers and shareholders, from and
            against all the liabilities, claims, damages and expenses (including
            reasonable attorneys' fees and costs) arising out of your use of
            this Site, your breach or alleged breach of these Terms and
            Conditions, and/or your breach or alleged violation of the patent,
            copyright, trademark, proprietary or other rights of third parties.
            Ford reserves the right to assume the exclusive defense and control
            of any matter otherwise subject to indemnification by you, and, in
            such case, you shall agree to cooperate with Ford's defense of such
            claim.
          </div>
        </div>
      </div>
    </>
  );
};

export default IndemnityAndHoldHarmless;