import React from 'react';
import classes from './HomeCharging.module.scss';
import { useNavigate } from 'react-router-dom';
import Accordion from '../../../../components/Accordion/Accordion';
import { FullCharge, MilesPerHour, MyOptions, Need, Specs, TotalCost, Voltage } from './AccordionBody';
import DownloadApp from '../DownloadApp/DownloadApp';
import ChargingCard from '../ChargingCard/ChargingCard';
import NavWebHeader from '../../../../components/NavWebHeader/NavWebHeader';

const HomeCharging = () => {
  const navigate = useNavigate();
  const data = [
    { title: 'Voltage', body: <Voltage /> },
    { title: 'What are my options?', body: <MyOptions /> },
    { title: 'What will I need?', body: <Need /> },
    { title: 'Specs', body: <Specs /> },
    { title: 'Length of full charge', body: <FullCharge /> },
    { title: 'Average miles per hour of charging', body: <MilesPerHour /> },
    { title: 'Total Cost', body: <TotalCost /> },
  ];
  return (
    <>
      <NavWebHeader title="Home Charging" />
      <div className={classes.wrapper}>
        <div className={classes.body_container}>
          <p className={classes.support_heading}>Find the right home charging option for you</p>

          <ChargingCard
            cardImage="/assets/images/ford-mobile-charger.png"
            cardImageAlt="ford mobile charger"
            cardTitle="Ford Mobile Charger"
          >
            <p className={classes.content_heading}>
              Mustang Mach-E comes with the Ford Mobile Charger, which is compatible with a 120V or 240V outlet for
              faster charging. Learn all about it{' '}
              <strong
                className={classes.link}
                data-testid="ford-mobile-charger-link"
                onClick={() =>
                  window.open(
                    'https://www.ford.com/cmslibs/content/dam/brand_ford/en_us/brand/suvs-crossovers/mac[…]df/Mach-E%20MPC%20Home_Install_Spec_Sheet_0831_FINAL(51)_2.pdf'
                  )
                }
              >
                here
              </strong>
              .
            </p>
          </ChargingCard>

          <ChargingCard
            cardImage="/assets/images/HomeCharging.png"
            cardImageAlt="ford connected charging station"
            cardTitle="Ford Connected Charging Station"
          >
            <p className={classes.content_heading}>
              Maximize your charge at home with the available Bluetooth<sup className={classes.sup}>&reg;</sup> and
              Wi-Fi-enabled 240V Ford Connected Charge Station. Learn all about it{' '}
              <strong
                data-testid="ford-connected-charging-station-link"
                className={classes.link}
                onClick={() => window.open('https://chargers.ford.com/products/ford-connected-charge-station')}
              >
                here
              </strong>
              .
            </p>
          </ChargingCard>

          <p className={classes.support_heading}>Which Home Charging Option Is Right For You?</p>

          <Accordion data={data} />

          <p className={classes.accordion_info}>
            <sup>**</sup> When equipped with the Mustang Mach-E with extended-range battery and RWD. Range and charge
            time based on manufacturer computer engineering simulations and EPA-estimated range calculation methodology.
            The charging rate decreases as the battery reaches full capacity. Your results may vary based on model year,
            drive line configuration, battery charge, peak charging times and battery state of charge. The actual range
            varies with conditions such as external environment, vehicle use, vehicle maintenance, lithium-ion battery
            age and state of health.{' '}
          </p>

          <ChargingCard
            cardImage="/assets/images/HomeCharging.png"
            cardImageAlt="home charging"
            cardTitle="Installing a Ford Connected Charging Station Easily With Qmerit"
          >
            <p className={classes.content_heading}>
              We’ve partnered with Qmerit to offer reliable home installation from qualified experts.
            </p>
            <div className={classes.button_container}>
              <button
                data-testid="qmerit-button"
                className={`${classes.charging_button} button--primary`}
                onClick={() => window.open('https://qmerit.com/ev/ford-drive/')}
              >
                Get started now with Qmerit’s Home Charging Assessment Tool
              </button>
            </div>
          </ChargingCard>

          <ChargingCard
            cardImage="/assets/images/home-charging-and-fordpass.png"
            cardImageAlt="home charging and fordpass"
            cardTitle="Home Charging and FordPass ™"
          >
            <p className={classes.content_heading}>
              Using the FordPass™ App when charging at home allows you to remotely check the status of a charge,
              estimated time of completion, and any other charging related notifications.
            </p>
            <br />
            <p className={classes.content_heading}>
              FordPass™ App can also be used to remote lock, unlock, and start your Mustang Mach-E, plus much more!{' '}
            </p>
          </ChargingCard>

          <DownloadApp
            imageToDisplay="FordPass-logo.png"
            playStoreLink="https://play.google.com/store/search?q=fordpass&c=apps"
            appStoreLink="https://apps.apple.com/us/app/fordpass/id1095418609"
          />

          <p className={classes.support_heading}>Home Charging Support</p>
          <p className={classes.content_heading}>
            If you need help charging at home, please reach out to our Customer Care Team via chat or by calling during
            office hours.
          </p>
          <br />
          <div className={classes.button_container}>
            <button
              data-testid="contact-button"
              className={`${classes.charging_button} ${classes.button_outline}`}
              onClick={() => navigate('/support/:todo')}
            >
              Contact Our Help Center
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeCharging;
