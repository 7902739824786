import React from 'react';
import classes from './NoShowPolicy.module.scss';
import Spacer from '../Spacer';

const NoShowPolicyBody = () => {
  return (
    <div className={classes.wrapper}>
      <p className={classes.text}>If you need to reschedule your</p>
      <p className={classes.text}>appointment, you may do so by calling</p>
      <p className={classes.text}>our Customer Care team. Please note,</p>
      <p className={classes.text}>there is a $25 fee if you are 45 mins late</p>
      <p className={classes.text}>or do not show up to your scheduled</p>
      <p className={classes.text}>appointment.</p>
      <Spacer height={16} />
    </div>
  );
};

export default NoShowPolicyBody;
