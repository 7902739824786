import React, {useEffect, useState} from 'react';
import classes from './UpdateAddressForm.module.scss';
import {Form} from '../../components/form/FormComponents';
import AddressFormFields from '../../components/form/components/AddressFormFields/AddressFormFields';
import Footer from '../../components/Footer/Footer';
import NavWebHeader from '../../components/NavWebHeader/NavWebHeader';
import {UserAddress} from '../../models/UserAddress';
import VerifyingSpinner from '../../components/VerifyingSpinner/VerifyingSpinner';
import {useParams} from 'react-router-dom';
import useUpdateAddressForm from './useUpdateAddressForm';
import {getUserAddress} from './utils';
import AnimatedButton from '../../components/AnimatedButton/AnimatedButton';
import {usePageDuration} from '../../hooks/usePageDuration';
import {trackUpdateAddressErrorPageDuration, trackUpdateAddressErrorPageLoad} from '../../services/analyticsService';

export enum RedirectTo {
  EXPERIAN = 'experian',
  PROFILE = 'profile',
}

const UpdateAddressForm: React.FC = () => {
  const [dataPresent, setDataPresent] = useState(false);
  const [address, setAddress] = useState<UserAddress>();
  const {type} = useParams();
  const {onSubmit, enableSubmit, processing, allFieldsDirty, setAllFieldsDirty, defaultValues, navigateBack} =
    useUpdateAddressForm(type);

  useEffect(() => {
    if (dataPresent) trackUpdateAddressErrorPageLoad();
    (async () => {
      setAddress(await getUserAddress());
      setDataPresent(true);
    })();
  }, [dataPresent]);

  usePageDuration(trackUpdateAddressErrorPageDuration, dataPresent);

  return (
    <>
      <div className="l-height-bg">
        <NavWebHeader
          title={type === RedirectTo.EXPERIAN ? 'Verification' : 'Update Profile'}
          shouldNav={true}
          handleBackNavigation={navigateBack}
        />
        {dataPresent ? (
          <div className="l-border-radius-bg">
            <div className="l-center">
              <div className={classes.updateAddressNotes}>
                <p className={classes.header} data-testid="updateAddress">
                  Update Address
                </p>
                {type === RedirectTo.EXPERIAN ? (
                  <p className={classes.notes} data-testid="updateAddressNotes">
                    The address provided does not match the information on file with Experian. Kindly update your
                    address accordingly.
                  </p>
                ) : (
                  ''
                )}
              </div>
              <Form
                onSubmit={onSubmit}
                defaultValues={defaultValues}
                allFieldsDirty={allFieldsDirty}
                setAllFieldsDirty={setAllFieldsDirty}
                startingValues={address}
              >
                <div className="l-margin-right-24 l-margin-left-24">
                  <AddressFormFields disabled={processing}/>
                </div>

                <div className="l-flex-center">
                  <div className={classes.button_container}>
                    <AnimatedButton enableSubmit={enableSubmit} processing={processing} text={'Submit'}/>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        ) : (
          <VerifyingSpinner/>
        )}
        <Footer/>
      </div>
    </>
  );
};

export default UpdateAddressForm;
