import React from 'react';
import classes from './DashboardHeader.module.scss';
import { trackLogout } from '../../services/analyticsService';
import { logoutUser } from '../../services/userService';
import { useNavigate } from 'react-router-dom';

const DashboardHeader = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    trackLogout();
    logoutUser()
      .then(() => {
        navigate('/login-email');
      })
      .catch(() => {
        navigate('/error');
      });
  };
  return (
    <div className={classes.header} data-testid="dashboard-header-id" role="banner">
      <div>
        <img src={'/assets/images/logo.webp'} className={classes.image} alt="Ford Drive Logo" />
      </div>
      <div className={classes.logout_button} onClick={handleLogout} data-testid={'logout-button'}>
        Logout
      </div>
    </div>
  );
};

export default DashboardHeader;
