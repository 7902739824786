import {getRequestForBff, patchRequestForBff, postRequestForBff, putRequestForBff} from '../axios/axiosVerbs';
import {LoggedInUser} from '../models/LoggedInUser';

export const initAuthLogin = () => getRequestForBff<string>('v1/auth/auth-login', true);

export const savePartialProfileDataToCache = (partialProfile) =>
  postRequestForBff<string>('v1/auth/cache-profile-data', partialProfile, true);

export const saveSecondaryProfileData = (secondaryProfile) =>
  patchRequestForBff<string>('v1/profiles/add-secondary-profile-details', secondaryProfile, true);

export const updateProfileAddressData = (updateAddress) =>
  putRequestForBff<string>('v1/profiles/update-address', updateAddress, true);

export const createAccount = () => postRequestForBff<string>('v1/create-account', true);

export const validateToken = (policy: string, code: string) =>
  getRequestForBff<string>('v1/auth/validate-token?policy=' + policy + '&code=' + code, true);

export const getLoggedInCustomer = () => getRequestForBff<LoggedInUser>('v1/logged-in-customer', true);
