import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { getLatestReservation } from '../../../services/reservationService';
import { HTTP_STATUS } from '../../../models/HttpStatus';
import { Reservation } from '../../../models/Reservation';
import { getPricingDisplayInfoById, getUpcomingPlan } from '../../../services/pricingService';
import { PricingDisplayInfo } from '../../../models/PricingDisplayInfo';
import { selectedPricingDisplayInfoAtom } from '../../../pages/Dashboard/Dashboard';

export function useLeasePackage() {
  const [pricingDisplayInfo, setPricingDisplayInfo] = useState<PricingDisplayInfo>(null);
  const [upcomingPlan, setUpcomingPlan] = useState<PricingDisplayInfo>(null);
  const [, setSelectedPricingDisplayInfo] = useAtom(selectedPricingDisplayInfoAtom);

  useEffect(() => {
    (async () => {
      // fetch latest reservation
      const latestResp = await getLatestReservation();
      if (latestResp.status !== HTTP_STATUS.OK) {
        console.error('Could not retrieve latest reservation');
        return null;
      }

      // fetch pricing display info
      const latest: Reservation = latestResp.data;
      const selectedPricingListResp = await getPricingDisplayInfoById(latest.billingPlanId);
      if (selectedPricingListResp.status !== HTTP_STATUS.OK) {
        console.error('Could not retrieve pricing list');
        return null;
      }

      const upcomingPlanResp = await getUpcomingPlan();
      if (upcomingPlanResp.status !== HTTP_STATUS.OK) {
        console.error('Could not retrieve upcoming plan');
        return null;
      }

      // find and set the selected pricing display info
      const selectedPricingDisplayInfo: PricingDisplayInfo = selectedPricingListResp.data;
      setPricingDisplayInfo(selectedPricingDisplayInfo);
      setSelectedPricingDisplayInfo(selectedPricingDisplayInfo);
      setUpcomingPlan(upcomingPlanResp.data);
    })();
  }, []);

  return { pricingDisplayInfo, upcomingPlan };
}
