import React from 'react';
import {
  CardCvcElement,
  CardCvcElementComponent,
  CardExpiryElement,
  CardExpiryElementComponent,
  CardNumberElement,
  CardNumberElementComponent,
} from '@stripe/react-stripe-js';
import styles from './CardElementWrapper.module.scss';
import {
  StripeCardCvcElementOptions,
  StripeCardExpiryElementOptions,
  StripeCardNumberElementOptions,
} from '@stripe/stripe-js';

const CARD_ELEMENT_OPTIONS:
  | StripeCardNumberElementOptions
  | StripeCardExpiryElementOptions
  | StripeCardCvcElementOptions = {
  style: {
    base: {
      color: '#000000',
      fontSize: '12px',
      fontWeight: '400',
      '::placeholder': {
        color: '#adadad',
        fontFamily: 'sans-serif',
        fontSize: '12px',
        fontWeight: '400',
      },
    },
  },
  showIcon: true,
  iconStyle: "solid",
};

type CardElementProps = {
  className: string;
  element: 'number' | 'expiry' | 'cvc';
  label: string;
};

function CardElementWrapper({ className, element, label }: CardElementProps) {
  let ElementComponent: CardNumberElementComponent | CardExpiryElementComponent | CardCvcElementComponent;
  switch (element) {
    case 'number':
      ElementComponent = CardNumberElement;
      break;
    case 'expiry':
      ElementComponent = CardExpiryElement;
      break;
    case 'cvc':
      ElementComponent = CardCvcElement;
      break;
    default:
      return null;
  }

  return (
    <label className={`${className} ${styles.label}`} data-testid="card-element" htmlFor={label.toLowerCase()}>
      {label}
      <ElementComponent className={styles.cardInputWrapper} options={CARD_ELEMENT_OPTIONS} />
    </label>
  );
}

export default CardElementWrapper;
