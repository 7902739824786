import React from "react";
import classes from "./ChargingDetails.module.scss";

interface ChargingDetailsProps {
  title: string;
  details: string;
}

const ChargingDetails: React.FC<ChargingDetailsProps> = ({
  title,
  details,
}) => {
  return (
    <>
      <div className={classes.heading}>
        <p>{title}</p>
      </div>
      <div className={classes.details}>
        <p>{details}</p>
      </div>
    </>
  );
};

export default ChargingDetails;
