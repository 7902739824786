import c from './SuccessScreen.module.scss';
import { useEffect, useState } from 'react';
import { setPassword as passwordRequest } from '../../services/userService';
import Footer from '../../components/Footer/Footer';
import AnimatedButton from '../../components/AnimatedButton/AnimatedButton';
import { useNavigate } from 'react-router-dom';
import VerifyingSpinner from '../../components/VerifyingSpinner/VerifyingSpinner';
import {
  trackQualifiedAndPasswordCreationPageCreateAccountButtonClick,
  trackQualifiedAndPasswordCreationPageCreateAccountError,
  trackQualifiedAndPasswordCreationPageLoad,
} from '../../services/analyticsService';
import { getLoggedInCustomer } from '../../services/noAuthFlowService';
import { reservationExists } from '../../services/reservationService';

const SuccessScreen = () => {
  const navigate = useNavigate();
  const [showContent, setShowContent] = useState(false);
  const [userId, setUserId] = useState('');
  const [email, setEmail] = useState('');
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [oneLowercase, setOneLowercase] = useState(false);
  const [oneUppercase, setOneUppercase] = useState(false);
  const [oneNumber, setOneNumber] = useState(false);
  const [oneSpecialCharacter, setOneSpecialCharacter] = useState(false);
  const [min8Characters, setMin8Characters] = useState(false);
  const [noWhitespace, setNoWhitespace] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const validations = [
    oneLowercase,
    oneUppercase,
    oneNumber,
    oneSpecialCharacter,
    min8Characters,
    noWhitespace,
    passwordsMatch,
  ];
  const fetchErrorMessage = 'There was an error processing your request. Please try again.';

  useEffect(() => {
    if (showContent) trackQualifiedAndPasswordCreationPageLoad();
  }, [showContent]);

  useEffect(() => {
    (async () => {
      try {
        const loggedInUserResponse = await getLoggedInCustomer();
        if (loggedInUserResponse?.status !== 200) throw new Error('logged in user response was not 200');
        setUserId(loggedInUserResponse.data.userId);
        setEmail(loggedInUserResponse.data.email);

        const reservationExistsResponse = await reservationExists();
        if (!reservationExistsResponse?.data?.isExist) throw new Error('reservation does not exist for user');
        setShowContent(true);
      } catch (e) {
        console.error(e);
        navigate('/sign-up');
      }
    })();
  }, []);

  const validate = (v) => {
    setPassword(v);
    setOneNumber(/\d/.test(v));
    setOneLowercase(/[a-z]/.test(v));
    setOneUppercase(/[A-Z]/.test(v));
    setOneSpecialCharacter(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(v));
    setMin8Characters(v.length >= 8);
    setNoWhitespace(!/\s/.test(v));
    setPasswordsMatch(v === confirmation);
  };

  const validateConfirmation = (v) => {
    setConfirmation(v);
    setPasswordsMatch(password === v);
  };

  useEffect(() => {
    setEnableSubmit(validations.every((v) => v));
  }, validations);

  useEffect(() => {
    if (!password) {
      setNoWhitespace(false);
      setPasswordsMatch(false);
    }
  }, [password, confirmation]);

  const handleSubmit = async () => {
    setFetchError(false);
    setProcessing(true);
    setEnableSubmit(false);
    try {
      trackQualifiedAndPasswordCreationPageCreateAccountButtonClick();
      const passwordRes = await passwordRequest({ id: userId, password: password });
      if (passwordRes.status !== 200) throw new Error(`password response was ${passwordRes.status} not 200`);

      setProcessing(false);
      setSuccess(true);
    } catch (e) {
      trackQualifiedAndPasswordCreationPageCreateAccountError({ error_msg: fetchErrorMessage });
      console.error(e);
      setFetchError(true);
      setEnableSubmit(true);
      setProcessing(false);
    }
  };

  const validation = (validationPassed, text, testId) => {
    return validationPassed ?
        <div className={c.validation}>
          <img
            src={process.env.PUBLIC_URL + '/assets/images/check.png'}
            alt={'check'}
            className={`${c.left} ${c.check}`}
            data-testid={`check-${testId}`}
          />
          <div className={`${c.right} ${c.check}`}>{text}</div>
        </div>
      : <div className={c.validation}>
          <img
            src={process.env.PUBLIC_URL + '/assets/images/bullet.png'}
            alt={'bullet'}
            className={`${c.left} ${c.bullet}`}
            data-testid={`bullet-${testId}`}
          />
          <div className={`${c.right} ${c.bullet}`}>{text}</div>
        </div>;
  };

  const successAnimationEnd = () => {
    navigate('/dashboard');
  };

  return (
    <div className={c.SuccessScreen} data-testid={'success-screen'}>
      <div className={c.header}>
        <a href="https://www.forddrive.com" target="_blank">
          <img
            src={process.env.PUBLIC_URL + '/assets/images/ford-drive-success-logo.svg'}
            alt={'Ford Drive Logo'}
            className={c.logo}
          />
        </a>
      </div>
      {showContent ?
        <div className={c.containerCard}>
          <div className={c.container}>
            <div className={c.title}>Congrats! You're signed up.</div>
            <div className={c.nextSteps}>Next steps:</div>
            <div className={`${c.stepContainer} ${c.first}`}>
              <div className={c.left}>01</div>
              <div className={c.right}>Expect an email confirmation shortly.</div>
            </div>
            <div className={`${c.stepContainer} ${c.third}`}>
              <div className={c.left}>02</div>
              <div className={c.right}>
                To check your status and access other helpful resources, please create an account.
              </div>
            </div>
            <div className={c.accountTitle}>
              Account <span className={c.smallText}>(optional)</span>
            </div>
            <div className={c.email}>{email}</div>
            <input
              className={c.password}
              type="password"
              placeholder="Password*"
              onChange={(e) => validate(e.target.value)}
              disabled={processing || success}
              maxLength={50}
              data-testid={'password'}
            />
            <div className={c.validationsContainer}>
              <div className={`${c.validationsColumn} ${c.col1}`}>
                {validation(oneLowercase, 'One lowercase character', 'lowercase')}
                {validation(oneUppercase, 'One uppercase character', 'uppercase')}
                {validation(oneNumber, 'One number', 'number')}
              </div>
              <div className={`${c.validationsColumn} ${c.col2}`}>
                {validation(oneSpecialCharacter, 'One special character', 'special')}
                {validation(min8Characters, '8 character minimum', '8minimum')}
                {validation(noWhitespace, 'No spaces', 'whiteSpace')}
              </div>
            </div>
            <input
              className={`${c.password} ${c.confirmation}`}
              type="password"
              placeholder="Confirm Password*"
              onChange={(e) => validateConfirmation(e.target.value)}
              disabled={processing || success}
              maxLength={50}
              data-testid={'confirmation'}
            />
            <div className={c.validationsContainer}>
              <div className={`${c.validationsColumn} ${c.col1}`}>
                {validation(passwordsMatch, 'Passwords must match', 'passwordsMatch')}
              </div>
            </div>
            <div className={c.buttonTopSpacer} />
            <AnimatedButton
              enableSubmit={enableSubmit}
              processing={processing}
              success={success}
              text={'Create Account'}
              handleSubmit={handleSubmit}
              onAnimationEnd={successAnimationEnd}
            />
            {fetchError && (
              <div className={c.fetchError} data-testid={'fetch-error'}>
                There was an error processing your request.
                <br />
                Please try again.
              </div>
            )}

            {/*<div className={c.notificationTitle}>How shall you get notified?</div>*/}
            {/*<div className={c.radioContainerOuter}>*/}
            {/*  <div className={c.radioContainerInner}>*/}
            {/*    <div className={c.radioTitle}>SMS</div>*/}
            {/*    <img*/}
            {/*      src={process.env.PUBLIC_URL + '/assets/images/toggleOff.png'}*/}
            {/*      alt={'toggle off'}*/}
            {/*      className={c.radio}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*  <div className={c.radioContainerInner}>*/}
            {/*    <div className={c.radioTitle}>Email</div>*/}
            {/*    <img*/}
            {/*      src={process.env.PUBLIC_URL + '/assets/images/toggleOn.png'}*/}
            {/*      alt={'toggle on'}*/}
            {/*      className={c.radio}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className={c.buttonBotSpacer} />
          </div>
        </div>
      : <VerifyingSpinner height={'1041px'} />}
      <Footer />
    </div>
  );
};

export default SuccessScreen;
