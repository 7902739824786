import React, { ComponentPropsWithoutRef, useContext, useEffect, useState } from 'react';
import styles from './PasswordForm.module.scss';
import AnimatedButton from '../AnimatedButton/AnimatedButton';
import { setPassword as passwordRequest } from '../../services/userService';
import { useNavigate } from 'react-router-dom';
import {
  trackPasswordAfterLoginCreateAccountButtonClick,
  trackPasswordAfterLoginCreateAccountError,
  trackPasswordCreatedSuccessfullyDuringSignup,
} from '../../services/analyticsService';
import { LoggedInUser } from '../../models/LoggedInUser';
import { getLoggedInCustomer } from '../../services/noAuthFlowService';
import { ExpandContext } from '../Dropdown';

interface PasswordFormProps extends ComponentPropsWithoutRef<'div'> {
  expanded?: boolean;
}

function PasswordForm({ expanded }: PasswordFormProps) {
  const navigate = useNavigate();

  const fetchErrorMessage = 'There was an error processing your request. Please try again.';

  const [status, setStatus] = useState('idle');
  const [userInfo, setUserInfo] = React.useState<LoggedInUser>();
  const [processing, setProcessing] = useState(status === '');
  const [success, setSuccess] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [oneLowercase, setOneLowercase] = useState(false);
  const [oneUppercase, setOneUppercase] = useState(false);
  const [oneNumber, setOneNumber] = useState(false);
  const [oneSpecialCharacter, setOneSpecialCharacter] = useState(false);
  const [min8Characters, setMin8Characters] = useState(false);
  const [noWhitespace, setNoWhitespace] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const setIsOpen = useContext(ExpandContext);
  const validations = [
    oneLowercase,
    oneUppercase,
    oneNumber,
    oneSpecialCharacter,
    min8Characters,
    noWhitespace,
    passwordsMatch,
  ];

  useEffect(() => {
    userInfo && status === 'success' && setIsOpen(expanded);
  }, [userInfo, status]);

  useEffect(() => {
    (async () => {
      try {
        const loggedInUserResponse = await getLoggedInCustomer();
        if (loggedInUserResponse?.status !== 200) throw new Error('logged in user response was not 200');
        setUserInfo(loggedInUserResponse.data);
        setStatus('success');
      } catch (e) {
        console.error(e);
        console.error(e.message);
        setStatus('error');
      }
    })();
  }, []);

  const handleSubmit = async () => {
    setEnableSubmit(false);
    try {
      trackPasswordAfterLoginCreateAccountButtonClick();
      const passwordRes = await passwordRequest({ id: 'self', password: password });
      if (passwordRes.status !== 200) throw new Error(`password response was ${passwordRes.status} not 200`);
      setProcessing(false);
      setSuccess(true);
      trackPasswordCreatedSuccessfullyDuringSignup();
    } catch (e) {
      trackPasswordAfterLoginCreateAccountError({ error_msg: fetchErrorMessage });
      console.error(e);
      setEnableSubmit(false);
    }
  };

  useEffect(() => {
    setEnableSubmit(validations.every((v) => v));
  }, validations);

  const validate = (v) => {
    setPassword(v);
    setOneNumber(/\d/.test(v));
    setOneLowercase(/[a-z]/.test(v));
    setOneUppercase(/[A-Z]/.test(v));
    setOneSpecialCharacter(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(v));
    setMin8Characters(v.length >= 8);
    setNoWhitespace(!/\s/.test(v));
    setPasswordsMatch(v === confirmation);
  };

  const validation = (validationPassed, text, testId) => {
    const imageType = validationPassed ? 'check' : 'bullet';

    return (
      <li className={styles.validation}>
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/${imageType}.png`}
          alt={imageType}
          className={`${styles.left} ${imageType === 'check' ? styles.check : styles.bullet}`}
          data-testid={`${imageType}-${testId}`}
        />
        <div className={`${styles.right} ${imageType === 'check' ? styles.check : styles.bullet}`}>{text}</div>
      </li>
    );
  };

  const validateConfirmation = (v) => {
    setConfirmation(v);
    setPasswordsMatch(password === v);
  };

  const navToDashboard = () => {
    navigate('/dashboard');
  };

  return (
    <div data-testid="password-form" className={styles.formContainer}>
      <p className={styles.formInfo}>To log back in, streamline pickup, and access our mobile app.</p>

      <div className={styles.accountTitle}>
        Account <span className={styles.smallText}>(optional)</span>
      </div>
      <div className={styles.email}>{userInfo?.email}</div>

      <input
        className={styles.password}
        type="password"
        placeholder="Password"
        onChange={(e) => validate(e.target.value)}
        disabled={processing || success}
        maxLength={50}
        data-testid={'password'}
      />
      <ul className={styles.validationsContainer}>
        {validation(oneLowercase, 'One lowercase character', 'lowercase')}
        {validation(oneSpecialCharacter, 'One special character', 'special')}
        {validation(oneUppercase, 'One uppercase character', 'uppercase')}
        {validation(min8Characters, '8 character minimum', '8minimum')}
        {validation(oneNumber, 'One number', 'number')}
      </ul>

      <p style={{ marginTop: '24px' }}>Confirm Password</p>

      <input
        className={`${styles.password} ${styles.confirmation}`}
        type="password"
        placeholder="Confirm Password"
        onChange={(e) => validateConfirmation(e.target.value)}
        disabled={processing || success}
        maxLength={50}
        data-testid={'confirmation'}
      />
      <ul className={styles.validationsContainer}>
        {validation(passwordsMatch, 'Passwords must match', 'passwordsMatch')}
      </ul>

      <AnimatedButton
        enableSubmit={enableSubmit}
        processing={processing}
        success={success}
        text={'Create Password'}
        handleSubmit={handleSubmit}
        onAnimationEnd={navToDashboard}
      />
    </div>
  );
}

export default PasswordForm;
