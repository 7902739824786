import React from "react";
import classes from './NotesCard.module.scss';

const NotesCard = ({children, shouldShowFooter = false}) => {
    const style = {
        borderRadius: '8px 8px 0 0'
    };
    return (
        <div className={classes.wrapper}>
            <div className={classes.container} style={shouldShowFooter ? style : {}}>
                <img className={classes.document} src={"/assets/images/document.svg"} alt="document icon" height="85"/>
                {children}
            </div>
            {shouldShowFooter && <div className={classes.footer}>*Mandatory for vehicle pickup.</div>}
        </div>
    );
};

export default NotesCard;
