import {ampliExperiment} from "../amplitude";
import {PricingDisplayInfoWithSelection} from "../models/PricingDisplayInfo";

export const vehicleImage = () => {
    const variant = ampliExperiment.variant('vehicle-package-screen');

    switch (variant?.value) {
        case 'control-variant':
            return '/assets/images/PackageSelectionMacheWhite.webp';
        case 'vehicle-variant-1':
            return '/assets/images/PackageSelectionMacheRed.webp';
        default:
            return '/assets/images/PackageSelectionMacheWhite.webp';
    }
};

export const sortPricingList = (pricingDisplayInfoWithSelectedList: PricingDisplayInfoWithSelection[]) => {
    const variant = ampliExperiment.variant('pricing-list');

    if(variant?.value === 'reverse') {
        return pricingDisplayInfoWithSelectedList.reverse();
    } else {
        return pricingDisplayInfoWithSelectedList;
    }
};
