import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ChargingGuide from './components/ChargingGuide/ChargingGuide';
import HomeCharging from './components/HomeCharging/HomeCharging';
import PublicCharging from './components/PublicCharging/PublicCharging';
import Footer from '../../components/Footer/Footer';
import { trackSupportGuideLinkClick } from '../../services/analyticsService';

export enum ChargingSupportType {
  CHARGING_GUIDE = 'charging-guide',
  HOME_CHARGING = 'home-charging',
  PUBLIC_CHARGING = 'public-charging',
}

const ChargingSupport = () => {
  const { type } = useParams();

  useEffect(() => {
    trackSupportGuideLinkClick();
  }, [type]);

  const renderBody = () => {
    switch (type) {
      case ChargingSupportType.CHARGING_GUIDE:
        return <ChargingGuide />;
      case ChargingSupportType.HOME_CHARGING:
        return <HomeCharging />;
      case ChargingSupportType.PUBLIC_CHARGING:
        return <PublicCharging />;
      default:
        return null;
    }
  };

  return (
    <>
      {renderBody()}
      <Footer />
    </>
  );
};

export default ChargingSupport;
