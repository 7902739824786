import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import classes from '../Orchestrator.module.scss';
import { validateToken } from '../../../services/noAuthFlowService';
import useContinueJourneyHook from '../ContinueJourney/useContinueJourneyHook';

const LoginOrchestrator = () => {
  const { continueJourney } = useContinueJourneyHook('Login');
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.hash);

  useEffect(() => {
    redirectAfterLoginSuccess();
  }, []);

  const redirectAfterLoginSuccess = async () => {
    const code = searchParams.get('#code');
    if (!code) {
      navigate('/error');
      return;
    }
    try {
      const validationResponse = await validateToken('LOGIN', code);
      if (validationResponse.status !== 200) {
        navigate('/error');
        return;
      }
      await continueJourney();
    } catch (e) {
      navigate('/error');
      return;
    }
  };

  return (
    <div className={classes.spinner_container}>
      <div className={classes.spinner}></div>
    </div>
  );
};

export default LoginOrchestrator;
