import React from "react";
import {useNavigate} from "react-router-dom";
import "./WaitlistError.scss";

const WaitlistError = () => {
    const navigate = useNavigate();
    const handleRetryWaitlist = () => {
        navigate('/experian');
    };
    return (
        <div className="waitlist-error-page" role="main">
            <div>
                <img
                    src={"/assets/images/genericerror.svg"}
                    alt="Waitlist Error Logo"
                />
            </div>
            <div className="waitlist-error-content">
                <div className="waitlist-error-header-container">
                    <h2 className="error-header-text">Sorry, our end of things went haywire!</h2>
                </div>
                <div className="error-sub-header-div">
                    <h3 className="error-sub-header-text">Your card details were added.</h3>
                    <h3 className="error-sub-header-text">
                        Something went wrong from our end.
                    </h3>
                    <h3 className="error-sub-header-text">
                        Please try joining waitlist now.
                    </h3>
                </div>
                <button className="retry-join-waitlist-button" onClick={handleRetryWaitlist}>
                    Retry Joining Waitlist
                </button>
            </div>
        </div>
    );
};

export default WaitlistError;
