import React, { forwardRef, InputHTMLAttributes } from "react";
import classes from "./ConsentCheckBox.module.scss";

interface MyInputProps extends InputHTMLAttributes<HTMLInputElement> {
  htmlContent: string;
  checkbox_label: string;
}

const ConsentCheckBox = forwardRef<HTMLInputElement, MyInputProps>(
  ({ htmlContent,checkbox_label, ...rest }, ref) => {
    return (
      <div className={classes.checkbox_div}>
        <input
          {...rest}
          className={classes.checkbox_input}
          type="checkbox"
          ref={ref}
          data-testid={"consent-checkbox"}
        />
        <div
          className={checkbox_label}
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        />
      </div>
    );
  }
);
export default ConsentCheckBox;
