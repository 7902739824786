import { useNavigate } from 'react-router-dom';
import { PageURL } from '../models/PageKey';
import { useEffect } from 'react';

export function useHandleBackButton(route: PageURL) {
  const navigate = useNavigate();

  useEffect(() => {
    function handlePopState(event: PopStateEvent) {
      event.preventDefault();
      navigate(route);
    }

    window.addEventListener('popstate', handlePopState);

    return () => window.removeEventListener('popstate', handlePopState);
  }, [navigate, route]);
}
