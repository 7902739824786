import React from "react";
import VehicleStatus from "../../../../../components/VehicleStatus/VehicleStatus";
import NotesCard from "../../../../../components/cards/NotesCard/NotesCard";
import PickupPendingDetails from "../PickupPendingDetails/PickupPendingDetails";
import PickupInfo from "../PickupInfo/PickupInfo";
import CreditCardDetails from "../../../../../components/CreditCardDetails/CreditCardDetails";

const PickupPendingBody: React.FC<{ locationId: string }> = ({ locationId }) => {
  return (
    <div className="l-center">
      <VehicleStatus />
      <NotesCard shouldShowFooter={true}>
        <PickupPendingDetails />
      </NotesCard>
      <CreditCardDetails />
      <PickupInfo locationId={locationId} />
    </div>
  );
};

export default PickupPendingBody;
