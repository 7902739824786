import React from 'react';
import ClosedDetails from '../ClosedDetails/ClosedDetails';
import NotesCard from '../../../../../components/cards/NotesCard/NotesCard';
import ClosedRestartMembership from '../ClosedRestartMembership/ClosedRestartMembership';
import classes from './ClosedBody.module.scss';

const ClosedBody = ({marketCode, userProfile}) => {
  return (
    <div test-dataid="closed-body-component" className="l-center">
      <div className={classes.container}>
        <h4 data-testid={'test-status-text'} className={classes.heading}>
          Sorry to see you go
        </h4>
        <div className={classes.status}>
          <span>Closed</span>
        </div>
      </div>
      <NotesCard>
        <ClosedDetails />
      </NotesCard>
      <ClosedRestartMembership marketCode={marketCode} userProfile={userProfile} />
    </div>
  );
};

export default ClosedBody;
