import { HomeAddress } from '../../models/HomeAddress';
import { runCreditVerification } from '../../services/creditService';
import { PageKey, PageURL } from '../../models/PageKey';
import { ErrorType } from '../ErrorViewer/ErrorViewer';
import { updateJourney } from '../../services/journeyService';
import { useNavigate } from 'react-router-dom';
import { updateProfileAddressData } from '../../services/noAuthFlowService';
import { RedirectTo } from './UpdateAddressForm';
import { useEffect, useState } from 'react';
import { trackUpdateAddressErrorSubmitButtonClick } from '../../services/analyticsService';

export default function useUpdateAddressForm(redirectTo: string) {
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(true);
  const [allFieldsDirty, setAllFieldsDirty] = useState(false);
  const defaultValues = {
    homeAddress: '',
    city: '',
    state: '',
    zipCode: '',
  };

  useEffect(() => {
    if (allFieldsDirty === enableSubmit) return;
    setEnableSubmit(allFieldsDirty);
  }, [allFieldsDirty]);

  async function navigateBack() {
    try {
      if (redirectTo === RedirectTo.PROFILE) {
        navigate(PageURL.PROFILE_SETTINGS);
      }

      if (redirectTo === RedirectTo.EXPERIAN) {
        const journeyResponse = await updateJourney({
          pageKey: PageKey.CREDIT_INFO,
        });

        if (journeyResponse?.status !== 200) {
          console.error('Error updating journey: ', journeyResponse?.status);
          navigate('/error');
          return;
        }
        navigate(PageURL.CREDIT_INFO);
      }
    } catch (e) {
      navigate('/error');
    }
  }

  async function runExperianCheck() {
    try {
      const creditVerificationResp = await runCreditVerification();
      if (creditVerificationResp.status !== 200) {
        console.error('Error running credit verification');
        return;
      }

      if (!creditVerificationResp.data.verified) {
        console.error('Experian credit data is unverified');
        navigate('/experian-rejection');
        return;
      }

      const updatedJourney = await updateJourney({
        pageKey: PageKey.PAYMENT,
        metadata: {
          experianVerified: creditVerificationResp.data.verified,
        },
      });

      if (updatedJourney.status !== 200) {
        console.error('Error updating journey');
        return;
      }

      navigate(PageURL.APPROVED);
    } catch (e) {
      switch (e.data.errorCode) {
        case 'VR402':
        case 'VR404':
          navigate('/error/' + ErrorType.EXPERIAN_SSN_ERROR);
          break;
        case 'VR403':
        case 'VR405':
          navigate('/update-address');
          break;
        case 'VR406':
        case 'VR407':
        case 'VR408':
        case 'VR409':
          navigate('/experian-rejection');
          break;
        default:
          navigate('/error/' + ErrorType.EXPERIAN_ERROR);
          break;
      }
    }
  }

  async function onSubmit(data) {
    trackUpdateAddressErrorSubmitButtonClick();
    setProcessing(true);
    setEnableSubmit(false);
    try {
      const homeAddress: HomeAddress = {
        streetAddress: data.homeAddress,
        apartmentNumber: '',
        city: data.city,
        state: data.state,
        zipcode: data.zipCode,
        country: '',
      };
      await updateProfileAddressData(homeAddress);

      if (redirectTo === RedirectTo.EXPERIAN) {
        await runExperianCheck();
      }

      if (redirectTo === RedirectTo.PROFILE) {
        navigate('/settings');
      }
    } catch (e) {
      setProcessing(false);
      setEnableSubmit(true);
      console.log(e);
      navigate('/error/' + ErrorType.SYSTEM);
    }
  }

  return { onSubmit, processing, enableSubmit, allFieldsDirty, setAllFieldsDirty, defaultValues, navigateBack };
}
