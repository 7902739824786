import {ampli, Environment, ApiKey} from './ampli';
import { Experiment } from '@amplitude/experiment-js-client';

const environment: Environment = window.__RUNTIME_CONFIG__.REACT_APP_AMPLITUDE as Environment;
ampli.load({ environment });

export const ampliExperiment = Experiment.initializeWithAmplitudeAnalytics(ApiKey[environment]);
await ampliExperiment.start().catch(err => {
    console.error(err);
});
