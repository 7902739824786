import React from 'react';
import FirstNameFormField from './FirstNameFormField';
import LastNameFormField from './LastNameFormField';
import EmailFormField from './EmailFormField';
import MobileNumberFormField from './MobileNumberFormField';
import HomeZipCodeFormField from './HomeZipCodeFormField';
import {InputModel} from '../../models';

const SignUpFormFields: React.FC<InputModel> = ({disabled = false}) => {
  return (
    <div>
      <FirstNameFormField disabled={disabled}/>
      <LastNameFormField disabled={disabled}/>
      <EmailFormField disabled={disabled}/>
      <MobileNumberFormField disabled={disabled}/>
      <HomeZipCodeFormField disabled={disabled}/>
    </div>
  );
};
export default SignUpFormFields;
