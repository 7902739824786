import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from '../Orchestrator.module.scss';
import useContinueJourneyHook from '../ContinueJourney/useContinueJourneyHook';

const EmailOrchestrator = () => {
  const { continueJourney } = useContinueJourneyHook('Email');
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        await continueJourney();
      } catch (e) {
        navigate('/error');
      }
    })();
  }, []);

  return (
    <div className={classes.spinner_container}>
      <div className={classes.spinner}></div>
    </div>
  );
};

export default EmailOrchestrator;
