import React, { useState } from 'react';
import Footer from '../../components/Footer/Footer';
import MobileHeader from '../../components/MobileHeader/MobileHeader';

export default function Maintenance() {
  const [show, setShow] = useState(false);

  const handleCopyClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    navigator.clipboard.writeText('Support@rideshare.forddrive.com');
    setShow(!show);
  };

  const handleEmailUsClick = () => {
    window.location.href = 'mailto: Support@rideshare.forddrive.com';
  };

  return (
    <>
      <MobileHeader />
      <main className="content l-section l-abs-center-items">
        <img src={`${process.env.PUBLIC_URL}/assets/images/Maintenance.svg`} alt="maintenance image" />
        <h4 style={{ marginBottom: '8px' }}>Website under maintenance</h4>
        <p style={{ textAlign: 'center', width: '279px' }}>
          We apologize for any inconvenience as you may be unable to sign up or complete account-related tasks on
          <b> August 24 starting at 6pm PT.</b>
        </p>
        <p>We will soon be up and running!</p>
        <br />
        <p>Contact us with any questions.</p>
        <div>
          <button
            className="button--primary"
            style={{ marginTop: '24px', width: '216px', position: 'relative' }}
            onClick={handleEmailUsClick}
          >
            Email Us!
            <p
              style={{
                position: 'absolute',
                right: '-70px',
                top: '-12.5px',
                color: '#00095b',
                fontWeight: '400',
                opacity: show ? 1 : 0,
                transition: 'all 0.3s ease-in-out',
              }}
              onTransitionEnd={() => {
                setTimeout(() => setShow(false), 1500);
              }}
            >
              Copied!
            </p>
            <img
              onClick={handleCopyClick}
              data-testid="copy-image"
              src={`${process.env.PUBLIC_URL}/assets/images/Copy.svg`}
              alt="copy image"
              style={{ position: 'absolute', right: '-54px', top: '12.5px' }}
            />
          </button>
        </div>
      </main>
      <Footer />
    </>
  );
}
