
import React from "react";
import classes from "../TermsAndConditions.module.scss";

const ThirdPartyInfo = () => {
  return (
    <>
      <div className={classes.tc_heading}>
        <p>Third Party Information such as Comparator Values</p>
      </div>
      <div className={classes.tc_area}>
        <div className={classes.tc_conditions}>
          <div className={classes.tc_condition}>
            This Site may contain links to third-party websites that are not
            owned or controlled by Ford, including but not limited to links to
            websites for Ford dealers (Third Party Websites). Ford has no
            control over, and assumes no responsibility for, the content,
            privacy policies, or practices of any Third Party Websites. In
            addition, Ford will not and cannot censor or edit the content of any
            Third Party Website. By clicking on links, you expressly relieve
            Ford from any and all liability arising from your use of any Third
            Party Website, or from the content of any Third Party Website. When
            using a Third Party Website, you should be aware when you leave
            Ford's Site and read the terms and conditions and privacy policy of
            each Third Party Website that you visit.
          </div>
        </div>
      </div>
    </>
  );
};

export default ThirdPartyInfo;