import React from 'react';
import classes from './VehicleDetails.module.scss';

interface VehicleDetailsProps {
  isTitleDiplayed: boolean;
  isExploreOption: boolean;
  isReferral?: boolean;
}

const VehicleDetails: React.FC<VehicleDetailsProps> = ({ isTitleDiplayed, isExploreOption, isReferral }) => {
  const handleExploreMache = () => {
    window.location.href = 'https://www.ford.com/suvs/mach-e/';
  };
  const extoleLink = () => {
    return window.__RUNTIME_CONFIG__.REACT_APP_ENVIRONMENT === 'prod' ? 'https://refer.forddrive.com/portal' : 'https://dev.refer.forddrive.com/portal';
  };
  return (
    <>
      <div className={classes.vehicle_image_container}>
        <img src={'/assets/images/White-Mach_E-car.png'} alt="ford-mache-white" />
      </div>
      {isReferral && (
        <a href={extoleLink()} target="_blank">
          <div className={classes.referral_container}>
              <img
                  src={"/assets/images/Referral-banner.png"}
                  className={classes.referral_image}
                  alt="referral-banner"
              />
            <a className={classes.referral_link}>Refer a friend and you’ll both earn a $250 Visa Gift Card!</a>
            <p>Terms apply</p>
          </div>
          </a>
      )}
      <div className={ isReferral ? classes.display_none : classes.vehicle_info_container }  >
        {isTitleDiplayed && (
          <div className={classes.vehicle_title}>
            Ford Mustang Mach-E
          </div>
        )}
        <div className={classes.vehicle_info}>
          <div>
            <ul className={classes.first_list}>
              <li>All Electric</li>
              <li>Automatic Transmission</li>
            </ul>
          </div>
          <div>
            <ul className={classes.second_list}>
              <li>Ford Co-Pilot360™</li>
              <li>4 Doors / 5 Seats</li>
            </ul>
          </div>
        </div>
        {isExploreOption && (
          <div>
            <button
              data-testid="explore-mache-button"
              className={classes.explore_mache_button}
              onClick={handleExploreMache}
            >
              Explore Mustang Mach-E
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default VehicleDetails;
