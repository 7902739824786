import React from 'react';
import { Dialog } from 'primereact/dialog';
import { ModalProps } from '../ModalUtils';
import modelClasses from '../Modal.module.scss';
import classes from './InsuranceSupportModal.module.scss';
import useInsuranceSupportHook from './useInsuranceSupportHook';

const InsuranceSupportModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const { collisionContactNumber } = useInsuranceSupportHook();
  return (
    <Dialog
      className={`${modelClasses.dialog} ${classes.dialog}`}
      visible={isOpen}
      onHide={onClose}
      closeOnEscape
      blockScroll
      draggable={false}
    >
      <div data-testid="modal-container" className={`${modelClasses.container} ${classes.container}`}>
        <p className={`${modelClasses.header} ${classes.header}`}>Have you been in a collision?</p>
        <p>
          If you are in a collision and <br />
          require a tow:
        </p>
        <a href={`tel:${collisionContactNumber}`} data-testid="collision-help-no">
          {collisionContactNumber}
        </a>
        <p data-testid="file-claim-text">
          If you have Ford Drive bundled
          <br />
          insurance, then to report a collision
          <br />
          and file a claim:
        </p>
        <a href="tel:800-327-3636">800-327-3636</a>
        <p className={`${modelClasses.header} ${classes.header}`} style={{ marginTop: '16px' }}>
          Do you need assistance?
        </p>
        <p data-testid="dead-battery-text">
          Get help with a dead battery, <br />
          flat tire, and more:
        </p>
        <a className={modelClasses.link_item} href="tel:800-367-3221" data-testid="dead-battery-help-no">
          800-367-3221
        </a>
      </div>
    </Dialog>
  );
};

export default InsuranceSupportModal;
