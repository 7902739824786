
import React from "react";
import classes from "../TermsAndConditions.module.scss";

const Disclaimer = () => {
  return (
    <>
      <div className={classes.tc_heading}>
        <p>Disclaimer</p>
      </div>
      <div className={classes.tc_area}>
        <div className={classes.tc_conditions}>
          <div className={classes.tc_condition}>
            Ford may at any time revise these Terms and Conditions by updating
            this posting. You are bound by such revisions and should therefore
            visit this page to review the current Terms and Conditions from time
            to time. NOTE THAT THESE TERMS AND CONDITIONS APPLY ONLY TO THIS
            SITE, OTHER WEBSITES PROVIDED BY FORD OR ITS AFFILIATES MAY HAVE
            DIFFERENT TERMS AND CONDITIONS THAT APPLY TO THE USE OF THOSE SITES.
            YOU ARE RESPONSIBLE FOR READING AND UNDERSTANDING THE TERMS AND
            CONDITIONS OF THOSE SITES.
          </div>
          <div className={classes.tc_condition}>
            This Site and all the information it contains is provided for
            information purposes only on an "as is" basis and could include
            technical, typographical or other errors. In certain areas, the
            information provided may represent an opinion or judgment. Ford,
            information providers and their agents make no warranties,
            representations, or guarantees of any kind, express or implied,
            including but not limited to, accuracy, currency, or completeness,
            the operation of the Site, the information, materials, content,
            availability, and products. To the extent permitted by applicable
            law, Ford disclaims all warranties, express or implied, including
            but not limited to, implied warranties of merchantability and
            fitness for a particular purpose. Ford is not responsible for the
            content of any Site linked to this Site and is not directly or
            indirectly implying any approval, endorsement or affiliation with
            any linked Site.
          </div>
          <div className={classes.tc_condition}>
            YOU AGREE THAT YOUR USE OF THIS SITE SHALL BE AT YOUR SOLE RISK. TO
            THE FULLEST EXTENT PERMITTED BY LAW, FORD'S OFFICERS, DIRECTORS,
            EMPLOYEES, PARENTS, AFFILIATES, SHAREHOLDERS, REPRESENTATIVES AND
            AGENTS DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION
            WITH THIS SITE AND YOUR USE THEREOF. FORD, ITS INFORMATION PROVIDERS
            AND THEIR AGENTS, MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
            CONTENT OF ANY SITES LINKED TO THIS SITE AND ASSUMES NO LIABILITY OR
            RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF
            CONTENT, (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE
            WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF OUR WEB SITE,
            (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR
            ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED
            THEREIN, (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR
            FROM OUR WEB SITE, (IV) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE
            LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH OUR WEB SITE BY ANY
            THIRD-PARTY, AND/OR (V) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR
            FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE
            OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE
            AVAILABLE VIA THE WEB SITE. FORD DOES NOT WARRANT, ENDORSE,
            GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE
            ADVERTISED OR OFFERED BY A THIRD-PARTY THROUGH THE WEB SITE OR ANY
            HYPERLINKED WEB SITE OR FEATURED IN ANY BANNER OR OTHER ADVERTISING,
            AND FORD WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR
            MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF
            PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE
            THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST
            JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE. NEITHER FORD,
            INFORMATION PROVIDERS OR THEIR AGENTS ARE PROVIDING ANY LEGAL, TAX,
            MEDICAL, COUNSELING, ACCOUNTING, INVESTMENT, FINANCIAL OR ANY OTHER
            PROFESSIONAL SERVICES OR ADVICE.
          </div>
        </div>
      </div>
    </>
  );
};

export default Disclaimer;