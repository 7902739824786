import React from "react";
import ChargingDetails from "../ChargingDetails/ChargingDetails";
import DownloadApp from "../DownloadApp/DownloadApp";
import classes from "./ChargingPointOperator.module.scss";

const ChargingPointOperator = () => {
  return (
    <>
      <ChargingDetails
        title={"Public Charge Point Operators"}
        details={
          "To find, access and pay for public charging stations you’ll need to download individual charging provider apps. These apps will provide step-by-step instructions on using the public chargers."
        }
      />
      <div className={`${classes.details} ${classes.space_above}`}>
        <p>
          Some popular public charging providers in your area are Electrify
          America, ChargePoint and EVgo. All can be downloaded below.
        </p>
      </div>
      <DownloadApp
        dataTestId="evgo-app"
        imageToDisplay="EVgo.svg"
        playStoreLink="https://play.google.com/store/search?q=evgo&c=apps"
        appStoreLink="https://apps.apple.com/us/app/evgo-ev-chargers/id1281660968"
      />
      <DownloadApp
        dataTestId="electrify-america-app"
        imageToDisplay="ElectrifyAmerica.svg"
        playStoreLink="https://play.google.com/store/search?q=electrify%20america&c=apps"
        appStoreLink="https://apps.apple.com/us/app/electrify-america/id1458030456"
      />
      <DownloadApp
        dataTestId="charge-point-app"
        imageToDisplay="ChargePoint.svg"
        playStoreLink="https://play.google.com/store/search?q=chargepoint&c=apps"
        appStoreLink="https://apps.apple.com/us/app/chargepoint/id356866743"
      />
    </>
  );
};

export default ChargingPointOperator;
