import React, {ChangeEvent} from 'react';


interface Props {
    id: string;
    label: string;
    value?: string;
    handleOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Input = ({id, label, value, handleOnChange}: Props) => {
    return (
        <label className="l-flex-col" htmlFor={id} data-testid={id}>
            <span className="font-sm l-margin-bottom-4" data-testid='span_id' style={{color: '#4D4D4D'}}>{label}</span>
            <input className="c-input"
                   type="text"
                   id={id}
                   onChange={handleOnChange}
                   value={value}/>
        </label>
    );
};

export default Input;
