import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import WaitlistError from '../../components/Error/Waitlist/WaitlistError';
import ExperianError from '../../components/ExperianError/ExperianError';
import Generic from '../../components/Error/Generic/Generic';
import NavWebHeader from '../../components/NavWebHeader/NavWebHeader';
import classes from './ErrorViewer.module.scss';
import MobileHeader from '../../components/MobileHeader/MobileHeader';

export enum ErrorType {
  EXPERIAN_ERROR = 'experian',
  EXPERIAN_SSN_ERROR = 'experian-ssn',
  EXPERIAN_ADDRESS_ERROR = 'experian-address',
  WAITLIST = 'waitlist',
  SYSTEM = 'system',
  UNAUTHENTICATED = 'unauthenticated',
}

const ErrorViewer = () => {
  const navigate = useNavigate();
  const { type } = useParams();

  const renderBody = () => {
    switch (type) {
      case ErrorType.WAITLIST:
        return <WaitlistError />;
      case ErrorType.EXPERIAN_ERROR:
      case ErrorType.EXPERIAN_SSN_ERROR:
      case ErrorType.EXPERIAN_ADDRESS_ERROR:
        return <ExperianError type={type} />;
      case ErrorType.SYSTEM:
        return <Generic route={'/experian'} />;
      case ErrorType.UNAUTHENTICATED:
        return <Generic route={'/login-email'} />;
      default:
        return <Generic route={'/dashboard'} />;
    }
  };

  return (
    <>
      <div className="l-height-bg">
        {type && type.includes('experian') ?
          <NavWebHeader title={'Verification'} shouldNav={true} handleBackNavigation={() => navigate(-1)} />
        : <MobileHeader />}
        <div className="l-border-radius-bg">
          <div className={classes.container}>{renderBody()}</div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ErrorViewer;
