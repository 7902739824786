import React from 'react';
import CityFormField from './CityFormField';
import StateFormField from './StateFormField';
import ZipCodeFormField from './ZipCodeFormField';
import HomeAddressFormField from './HomeAddressFormField';

import classes from './AdressFormFields.module.scss';
import { InputModel } from '../../models';

const AddressFormFields: React.FC<InputModel> = ({ disabled = false }) => {
  return (
    <>
      <HomeAddressFormField disabled={disabled} />
      <CityFormField disabled={disabled} />
      <div className={classes.grid_container}>
        <div className={classes.input_container}>
          <StateFormField disabled={disabled} />
        </div>
        <div className={classes.input_container}>
          <ZipCodeFormField disabled={disabled} />
        </div>
      </div>
    </>
  );
};

export default AddressFormFields;
