import React, {memo, useState} from 'react';
import { useFormContext } from 'react-hook-form';
import { Input } from '../../FormComponents';
import classes from './SignUpFormFields.module.scss';
import { InputModel } from '../../models';

const Child: React.FC<any> = memo(({ register, formState: { errors }, disabled }) => {
    const [phoneNumber, setPhoneNumber] = useState('');

    const formatPhoneNumber = (value: string) => {
        const cleaned = ('' + value).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);

        if (match) {
            const part1 = match[1] ? `(${match[1]}` : '';
            const part2 = match[2] ? `) ${match[2]}` : '';
            const part3 = match[3] ? `-${match[3]}` : '';
            return `${part1}${part2}${part3}`.trimEnd();
        }
        return value;
    };

    const handleChange = (event: { target: { value: any; }; }) => {
        const { value } = event.target;
        const formattedInputValue = formatPhoneNumber(value);
        setPhoneNumber(formattedInputValue);
    };
    return   (
        <>
            <Input
                register={register}
                name="phoneNo"
                title="Phone Number"
                placeholder="(555) 555-5555"
                data-testid="phone-number-input"
                labelClassName={classes.input_label}
                errors={errors}
                lightColor={true}
                disabled={disabled}
                rules={{
                    required: {
                        value: true,
                        message: 'Phone number is required',
                    },
                    pattern: {
                        value: /^\(\d{3}\) \d{3}-\d{4}$/i,
                        message: 'Please enter a phone number in the correct format',
                    },
                    onChange: handleChange
                }}
                value={phoneNumber}
                maxLength={14}
            />
        </>
    );});

const MobileNumberFormField: React.FC<InputModel> = ({ disabled = false }) => {
    const methods = useFormContext();
    return <Child {...methods} disabled={disabled} />;
};
export default MobileNumberFormField;

