import { postRequestForBff } from '../axios/axiosVerbs';

export enum EmailType {
  UNDER_25_LA = 'UNDER_25_LA',
}

export interface EmailRequest {
  emailType: EmailType;
}

export const sendEmail = (emailRequest: EmailRequest) => postRequestForBff<string>(`v1/email`, emailRequest, true);
