import React, {useState} from "react";
import classes from './AccordionItem.module.scss';

const AccordionItem = ({item}) => {

    const [clicked, setClicked] = useState(false);

    const {title, body} = item;

    const handleToggle = () => {
        setClicked((prev) => !prev);
    };

    const getImageUrl = () => {
        if (clicked) {
            return "/assets/images/CaretUp.svg";
        }
        return "/assets/images/CaretDown.svg";
    };

    return (
        <li className={`${classes.accordion_item} ${clicked ? classes.active : ""}`}>
            <button className={classes.header} onClick={handleToggle}>
                <div>{title}</div>
                <div className={classes.image}>
                    <img src={getImageUrl()}
                         data-testid='accordion-item-caret-image'
                         height={10}
                         alt="caret image"/>
                </div>
            </button>

            <div className={`${classes.body} ${clicked ? classes.active : ""}`}>
                <div className={classes.answer}>
                    {body}
                </div>
            </div>
        </li>
    );
};

export default AccordionItem;