import React from "react";
import classes from "./WaitlistDetails.module.scss";

const WaitlistDetails = () => {
    return (
        <div className={classes.container}>
            <div className={classes.body}>
                <div className={classes.description}>
                    <h2 className={classes.title}>
                        Vehicle Availability and Next Steps
                    </h2>
                    <div className={classes.words}>
                        <p className={classes.sub}>
                            We’ll notify you when your vehicle is ready for pickup.
                        </p>
                        <br/>
                        <p className={classes.last}>You will not be charged until vehicle pickup is complete.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WaitlistDetails;
