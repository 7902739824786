import React from 'react';
import classes from './DownloadApp.module.scss';

interface DownloadAppProps {
  imageToDisplay: string;
  playStoreLink: string;
  appStoreLink: string;
  dataTestId?: string;
}

const DownloadApp: React.FC<DownloadAppProps> = ({ imageToDisplay, playStoreLink, appStoreLink, dataTestId }) => {
  return (
    <div className={classes.app_info} data-testid={dataTestId}>
      <div>
        <img className={classes.image} src={'/assets/images/' + imageToDisplay} alt="download-image" />
      </div>
      <div className={classes.download_text}>
        <p>Download:</p>
      </div>
      <div className={classes.download_info}>
        <div className={classes.store_image}>
          <img
            data-testid="app-store-image"
            className={classes.image}
            src={'/assets/images/AppStore.svg'}
            alt="app-store-image"
            onClick={() => window.open(appStoreLink)}
          />
        </div>
        <div className={classes.store_image}>
          <img
            data-testid="play-store-image"
            className={classes.image}
            src={'/assets/images/PlayStore.svg'}
            alt="play-store-image"
            onClick={() => window.open(playStoreLink)}
          />
        </div>
      </div>
    </div>
  );
};

export default DownloadApp;
