import React, { memo } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { Input } from '../../FormComponents';
import { FormModel, InputModel } from '../../models';
import classes from './AdressFormFields.module.scss';

const Child: React.FC<
  FormModel & {
    errorMessage: any;
  }
> = memo(({ register, formState: { errors }, errorMessage, disabled }) => (
  <>
    <Input
      register={register}
      name="homeAddress"
      title="Home Address"
      placeholder="Home Address"
      maxLength={128}
      errors={errors}
      disabled={disabled}
      rules={{
        required: {
          value: true,
          message: errorMessage,
        },
        pattern: {
          value: /^[ A-Za-z0-9]*$/,
          message: errorMessage,
        },
      }}
    />
    {!DisplayAlert() && (
      <label className={classes.address_message} htmlFor="message">
        {errorMessage}
      </label>
    )}
  </>
));

const DisplayAlert = () => {
  const formState = useFormState();
  return formState.errors?.homeAddress;
};

const HomeAddressFormField: React.FC<InputModel> = ({ disabled = false }) => {
  const methods = useFormContext();
  return <Child {...methods} errorMessage="Please do not use special characters ( ), . # /" disabled={disabled} />;
};
export default HomeAddressFormField;
