import React, { FC } from 'react';
import VehicleStatus from '../../../../../components/VehicleStatus/VehicleStatus';
import ActiveDetails from '../ActiveDetails/ActiveDetails';
import { Reservation } from '../../../../../models/Reservation';
import VehicleDetails from '../../../../../components/VehicleDetails/VechicleDetails';

const ActiveBody: FC<{ reservation: Reservation }> = ({ reservation }) => {
  const showExtoleBanner =
    window.__RUNTIME_CONFIG__.REACT_APP_SHOW_EXTOLE_BANNER === true ||
    window.__RUNTIME_CONFIG__.REACT_APP_SHOW_EXTOLE_BANNER === 'true' ||
    window.__RUNTIME_CONFIG__.REACT_APP_SHOW_EXTOLE_BANNER === 'TRUE';
  return (
    <>
      <div className="l-center l-margin-bottom-32">
        <VehicleStatus />
        <ActiveDetails reservation={reservation} />
        <VehicleDetails isExploreOption={false} isTitleDiplayed={!showExtoleBanner} isReferral={showExtoleBanner} />
      </div>
    </>
  );
};

export default ActiveBody;
