import {downloadAllInvoices} from "./customerService";
import {AxiosResponse} from "axios";

export const downloadInvoices = (filterBy: string[]) => {
    downloadAllInvoices(filterBy)
        .then((response:AxiosResponse) => {
            const href = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');

            link.href = href;
            link.setAttribute('download', "invoices.zip");
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        })
        .catch( e => console.log(e));
};

export const isInvoiceDownloadable = (invoice) => {
   return (invoice && invoice.invoicePdf) && !invoice.invoicePdf.includes("pdf-non-downloadable");
};