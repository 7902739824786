import React from 'react';
import classes from './ClosedRestartMembership.module.scss';
import {useNavigate} from 'react-router-dom';
import {createJourney} from "../../../../../utils/journeyUtil";
import {PartialProfile} from "../../../../../models/PartialProfileRequest";
import {savePartialProfileDataToCache} from "../../../../../services/noAuthFlowService";
import {fetchJourney} from "../../../../../services/journeyService";
import {NavigationURLForPageKey} from "../../../../../models/PageKey";

const ClosedRestartMembership = ({marketCode, userProfile}) => {
  const navigate = useNavigate();

  const handleRestartMembershipClick = async () => {
      const signupData: PartialProfile = {
          firstName: userProfile?.name?.firstName,
          middleName: '',
          lastName: userProfile?.name?.lastName,
          email: userProfile?.email,
          phoneNumber: userProfile.phoneNumber,
          homeZipCode: '00000',
          consents: '',
          referrer: "https://bonjour.uber.com",
          workflowId: "UBER",
      };
      const journeyResponse = await fetchJourney();
      if (journeyResponse?.status == 200) {
          navigate(NavigationURLForPageKey[journeyResponse?.data?.pageKey]);
      }
      else {
          await savePartialProfileDataToCache(signupData);
          await createJourney(marketCode, navigate);
      }
  };

  return (
    <div className={classes.container}>
      <h4 className={classes.support}>Change your mind?</h4>
      <button className={classes.button} onClick={() => handleRestartMembershipClick()}>
        Restart Membership
      </button>
    </div>
  );
};

export default ClosedRestartMembership;
