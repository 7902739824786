import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Input } from '../../FormComponents';
import classes from './SignUpFormFields.module.scss';
import { InputModel } from '../../models';

const Child: React.FC<any> = memo(({ register, formState: { errors }, disabled }) => (
  <>
    <Input
      register={register}
      name="email"
      title="Email"
      placeholder="Email"
      maxLength={128}
      data-testid="email-input"
      labelClassName={classes.input_label}
      errors={errors}
      lightColor={true}
      disabled={disabled}
      rules={{
        required: {
          value: true,
          message: 'Email is required',
        },
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: 'Please enter an email address in the correct format',
        },
      }}
    />
  </>
));

const EmailFormField: React.FC<InputModel> = ({ disabled = false }) => {
  const methods = useFormContext();
  return <Child {...methods} disabled={disabled} />;
};
export default EmailFormField;
