import React, { useEffect } from 'react';
import classes from './UberLogin.module.scss';
import { trackLoginPageView } from '../../services/analyticsService';

const UberLogin = () => {
  const handleLoginToUber = () => {
    const uberLoginRedirectUrl = `https://urldefense.com/v3/__https:/earn.sng.link/A3ir4p/llnh?_dl=uberdriver:**Aweb*bonjour*marketplace*marketplaces*vehicles_us*weekly_rentals*product*68976598-0796-561a-b6c6-4022d239f354*offer*4b1ce0c0-f6a7-55f2-91a0-aa3006b1d2ea*vs_ref_id=vs_us_f_mache_ext_e&_smtype=3&_fallback_redirect=https:**Abonjour.uber.com*marketplace*marketplaces*vehicles_us*weekly_rentals*product*68976598-0796-561a-b6c6-4022d239f354*offer*4b1ce0c0-f6a7-55f2-91a0-aa3006b1d2ea*vs_ref_id=vs_us_f_mache_ext_e&_ios_redirect=https:**Abonjour.uber.com*marketplace*marketplaces*vehicles_us*weekly_rentals*product*68976598-0796-561a-b6c6-4022d239f354*offer*4b1ce0c0-f6a7-55f2-91a0-aa3006b1d2ea*vs_ref_id=vs_us_f_mache_ext_e&_android_redirect=https:**Abonjour.uber.com*marketplace*marketplaces*vehicles_us*weekly_rentals*product*68976598-0796-561a-b6c6-4022d239f354*offer*4b1ce0c0-f6a7-55f2-91a0-aa3006b1d2ea*vs_ref_id=vs_us_f_mache_ext_e__;Ly8vLy8vLy8vLy8_Ly8vLy8vLy8vLz8vLy8vLy8vLy8vPy8vLy8vLy8vLy8_!!N_LtwI-RPugbI9wg0dJn!FWrUjQ4hSii6NNP_9QztAb1ejMyeLXqjSwLhH90S7GaT8qShhwxXQIfVqlYIKZ8LK_AUK8n20Rhuj5lcqvw$`;
    window.location.replace(uberLoginRedirectUrl);
  };

  useEffect(() => {
    trackLoginPageView();
  }, []);

  return (
    <>
      <div className={classes.container}>
        <section className={classes.content}>
          <img className={classes.logo} src={'/assets/images/FordDriveLogo.svg'} alt="forddrivelogo" />
          <h5 className={classes.subtext}>
            Rideshare drivers please log in
            <br />
            to view your account.
          </h5>
          <button className={classes.loginUberButton} onClick={handleLoginToUber}>
            Login with Uber
          </button>
        </section>
        <footer className={classes.privacyAndTerms}>
          <p className={classes.copyright}>©Ford Drive. All Rights Reserved</p>
          <div className={classes.links}>
            <a href="https://www.ford.com/help/privacy" target="_blank" data-testid="footer-pp">Privacy Policy</a>
            <a href={'/terms-and-conditions'} target="_top" data-testid="footer-tac">Terms & Conditions</a>
          </div>
        </footer>
      </div>

      <img className={classes.wave} src={'/assets/images/Wave-bg-md.svg'} alt="wave" />
    </>
  );
};

export default UberLogin;
